import React from "react";

function Layout(props) {
  return (
    <React.Fragment>
      <main
        style={{
          padding: "2rem 10px",
          width: "857px",
          marginLeft: "76px",
          height: "100vh",
        }}
      >
        {props.children}
      </main>
    </React.Fragment>
  );
}

export default Layout;
