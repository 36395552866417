import React, { useState, useContext, useCallback } from "react";
import {
  TextField,
  Typography,
  Box,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import MainButton from "../../components/UIElements/Button";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../components/context/auth";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
  root: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(rgba(50, 50, 50, 0.8), rgba(50, 50, 50, 0.8)), ",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justify: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: "80px",
  },
  container: {
    background: "transparent",
  },
  table: {
    backgroundColor: "white",
  },
  head: {
    backgroundColor: " #e0e0e0",
    color: theme.palette.primary.light,
  },
  border: {
    borderColor: "#ccc",
    borderRadius: "5px" /* (height/2) + border-width */,
    borderStyle: "solid",
    borderWidth: " 2px",
  },
  buttonRoot: {
    width: 190,
    fontSize: "16px",
    textAlign: "left",
    float: "left",
    marginTop: "20px",
    position: "relative",
  },
  button: {
    width: 10,
  },
  customTable: {
    "& .MuiTableCell-root": {
      padding: "0px", // <-- arbitrary value
    },
  },
  box: {
    background: "#fff",
    borderRadius: "12px 12px 12px 12px",
    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.11)",
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    justifyItems: "center",
    padding: "40px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "center",
    alignItems: "center",
    justify: "center",
  },
}));

export default function Doctor({ DoctorNote }) {
  const classes = useStyles();
  const [newDoctorNote, setNewDoctorNote] = useState();
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);
  const { form } = useParams();
  //Doctor Note
  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setNewDoctorNote(value);
  };

  const sendDoctorNote = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://app.mahimo.co/user/doctor-note/`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ form_id: form, note: newDoctorNote }),
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error("ERORRR");
      } else {
        window.location.reload();
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [newDoctorNote]);

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <div id="idSvgLoading">
          <img src={mahimoSvg} alt="" />
        </div>
        {/* <CircularProgress color='inherit' /> */}
      </Backdrop>
      <GridContainer
        style={{
          marginTop: "5rem",
          width: "80%",

          display: "flex",
          flexDirection: "center",
          alignItems: "center",
          justify: "center",
          justifyContent: "center",
          backgroundColor: "#e0e0e0",
          borderColor: "#E0E0E0",
          borderRadius: "5px 5px 0px 0px",
          borderStyle: "solid",
          borderWidth: "2px",
        }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <Typography className="result-doctor-note">Doctor Note</Typography>
        </GridItem>
      </GridContainer>

      <GridContainer
        style={{
          marginTop: "25px",
          width: "80%",

          display: "flex",
          flexDirection: "center",
          alignItems: "center",
          justify: "center",
          justifyContent: "center",

          borderColor: "#E0E0E0",
          borderRadius: "0px 0px 0px 0px" /* (height/2) + border-width */,
          borderStyle: "solid",
          borderWidth: "2px",
        }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <Box
            alignItems="left"
            display="flex"
            justifyContent="left"
            flexDirection="column"
            style={{
              paddingTop: "20px",
              paddingLeft: "20px",
              paddingBottom: "20px",
            }}
          >
            <Typography className="text-special-title">
              Special title Treatment
            </Typography>
            <Typography className="test-doctor-note">{DoctorNote}</Typography>
            <TextField
              variant="outlined"
              label="doctorNote"
              type="text"
              name="doctorNote"
              onChange={inputChangeHandler}
            />

            <MainButton
              background
              type="submit"
              variant="contained"
              className={classes.buttonRoot}
              onClick={() => {
                sendDoctorNote();
              }}
            >
              Save
            </MainButton>
          </Box>
        </GridItem>
      </GridContainer>
    </>
  );
}
