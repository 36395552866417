import React, { useState } from "react";
import { Button, TextField, Snackbar, Backdrop } from "@material-ui/core";
import { useForm } from "react-hook-form";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));

function UserInfo({ data, parentCallback }) {
  const { register, handleSubmit } = useForm();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [passwordFailed, setPasswordFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState();
  const [faildMessage, setFaildMessage] = useState("Please try again!");
  const handleUpdateClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateFailed(false);
  };

  const handlePasswordClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setPasswordFailed(false);
  };

  const handleUpdateSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateSuccess(false);
  };

  const formSubmitHandler = async (data) => {
    // if (data.password !== data.password_2) {
    //   setPasswordFailed(true);
    //   return;
    // }

    try {
      setLoading(true);
      const response = await fetch(
        "https://app.mahimo.co/user/forget-password/",
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: userInfo.email }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        // throw new Error('Error');
        setFaildMessage(responseData.errors[0].message);
        setPasswordFailed(true);
        setLoading(false);
      } else {
        setUpdateSuccess(true);
        setLoading(false);
        setTimeout(() => {
          parentCallback(true);
          //history.push('/login');
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserInfo((preventData) => ({ ...preventData, [name]: value }));
  };

  return (
    <React.Fragment>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <div id="idSvgLoading">
          <img src={mahimoSvg} alt="" />
        </div>
        {/* <CircularProgress color='inherit' /> */}
      </Backdrop>
      <Snackbar
        open={updateFailed}
        autoHideDuration={6000}
        onClose={handleUpdateClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleUpdateClose} severity="error">
          Update failed! Please try again.
        </Alert>
      </Snackbar>
      <Snackbar
        open={passwordFailed}
        autoHideDuration={6000}
        onClose={handlePasswordClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handlePasswordClose} severity="error">
          {faildMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={updateSuccess}
        autoHideDuration={6000}
        onClose={handleUpdateSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleUpdateSuccessClose} severity="success">
          Recover successful! Please check your email.
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <TextField
          id="standard-read-only-input"
          label="Email"
          name="email"
          variant="outlined"
          fullWidth
          defaultValue={data && data.username}
          margin="normal"
          inputRef={register({ required: true })}
          style={{ display: "block" }}
          onChange={inputChangeHandler}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          style={{
            margin: "15px 0",
            borderRadius: 10,
            height: 50,
          }}
        >
          Recovery request
        </Button>
      </form>
    </React.Fragment>
  );
}

export default UserInfo;
