import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  TableContainer,
  makeStyles,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Backdrop,
  TableFooter,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { AuthContext } from "../../components/context/auth";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
  },
  content: {
    borderTop: `${theme.palette.divider} 1px solid`,
    borderBottom: `${theme.palette.divider} 1px solid`,
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));

const ProductList = ({ openProduct, handleClose, list, formId }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  const [product_list, setProduct_list] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);
  //const confirm = useConfirm();
  const [data, setData] = React.useState(list);
  const [form, setForm] = React.useState(formId);
  //table paggenation
  const [tableCount, SetTablecCount] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  //----------------------------------------------------------------
  const openDialog = useCallback(
    (row) => {
      console.log(row);
      console.log(form);
      setProduct_list((prevState) => [...prevState, row.id]);
    },

    [form]
  );

  useEffect(() => {
    console.log(formId);
    setData(list);
    setForm(formId);
    if (list !== null) SetTablecCount(list.length);
  }, [formId, list]);

  //pagenation
  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);
  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }, []);

  //load quiz list
  const saveProduct = async () => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(
        "https://app.mahimo.co/quiz/admin/doctor_suggest_product/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            form_id: formId,
            products: product_list,
          }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error("ERORRR");
      } else {
        setLoading(false);
        handleClose();
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      open={openProduct}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        Product List Added {product_list.length}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.content,
        }}
      >
        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell align="center">Image</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Add</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && (
                <>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          {" "}
                          <img
                            src={`https://app.mahimo.co${row.images[0].image}`}
                            style={{ width: "125px", height: "80px" }}
                            alt="profile"
                          />
                        </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          {row.description.substring(0, 100)}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              openDialog(row);
                            }}
                          >
                            <AddBoxIcon />
                          </Button>
                          <span
                            style={{
                              width: 5,
                              display: "inline-block",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableFooter
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "start",
            position: "relative",
          }}
        >
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={tableCount}
              rowsPerPage={rowsPerPage}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              variant="outlined"
              shape="rounded"
              style={{ color: "secondary" }}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="inherit"
          style={{ marginLeft: "5px" }}
        >
          Cancel
        </Button>
        <Button
          style={{
            color: "#76288a",
          }}
          onClick={() => {
            saveProduct();
          }}
          autoFocus
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <div id="idSvgLoading">
          <img src={mahimoSvg} alt="" />
        </div>
      </Backdrop>
    </Dialog>
  );
};

export default ProductList;
