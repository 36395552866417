import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [wpToken, setWpToken] = useState(null);
  const [access, setAccess] = useState(null);
  const [expirationDateToken, setExpirationDateToken] = useState();

  const accessPermission = useCallback((access, expirationDate) => {
    setAccess(access);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 10);
    setExpirationDateToken(tokenExpirationDate);
    localStorage.setItem(
      'access',
      JSON.stringify({
        access: access,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const login = useCallback((token, expirationDate) => {
    setToken(token);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 10);
    setExpirationDateToken(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const wplogin = useCallback((wp, expirationDate) => {
  
    setWpToken(wp);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 10);
    setExpirationDateToken(tokenExpirationDate);
    localStorage.setItem(
      'wpData',
      JSON.stringify({
        wpToken : wp,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);
  const jwtlogin = useCallback((jwt, expirationDate) => {
 
    setJwtToken(jwt);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 10);
    setExpirationDateToken(tokenExpirationDate);
    localStorage.setItem(
      'jwtData',
      JSON.stringify({
        jwtToken: jwt,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setJwtToken(null);
    setWpToken(null);
    setExpirationDateToken(null);
    localStorage.removeItem('userData');
    localStorage.removeItem('wpData');
    localStorage.removeItem('jwtData');
    localStorage.removeItem('access');
  }, []);

  useEffect(() => {
    if (token && expirationDateToken) {
      const remainingTime =
        expirationDateToken.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, expirationDateToken, logout]);

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem('userData'));
    const wpData = JSON.parse(localStorage.getItem('wpData'));
    const jwtData = JSON.parse(localStorage.getItem('jwtData'));
    const accessData = JSON.parse(localStorage.getItem('access'));
 

    if (
      storeData &&
      storeData.token &&
      new Date(storeData.expiration) > new Date()
    ) {
      login(storeData.token, new Date(storeData.expiration));

      accessPermission(accessData, new Date(storeData.expiration));
      if (wpData && wpData.wpToken && new Date(wpData.expiration) > new Date()) {
        wplogin(wpData.wpToken, new Date(wpData.expiration));
      }
      if (jwtData && jwtData.jwtToken &&new Date(wpData.expiration) > new Date()) {
        jwtlogin(jwtData.jwtToken, new Date(wpData.expiration));
      }
    }
  }, [login, wplogin]);

  return { token, access, accessPermission, login, logout,jwtlogin, wplogin, wpToken, jwtToken};
};
