import React from "react";
import { Grid, Typography } from "@material-ui/core";
import GridContainer from "../Grid/GridContainer.js";
export default function Index({ userProfileQuestionsAnswers }) {
  return (
    <GridContainer
      style={{
        marginTop: "3rem",
      }}
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        style={{
          justifyContent: "flex-Start",
          marginTop: "3rem",
          display: "flex",
        }}
        direction="column"
      >
        {userProfileQuestionsAnswers &&
          (userProfileQuestionsAnswers[0].user_obj.username ? (
            <Typography className="text-home-desktop-take-quiz">
              Hi {userProfileQuestionsAnswers[0].user_obj.username},
            </Typography>
          ) : (
            <Typography className="text-home-desktop-take-quiz">
              Hi {userProfileQuestionsAnswers[0].user_obj.email},
            </Typography>
          ))}
        <Typography className="text-result-custome-treatment">
          Meet your custom treatment
        </Typography>
      </Grid>
    </GridContainer>
  );
}
