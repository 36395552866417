import React, { useEffect, useState, useContext } from "react";
import {
  makeStyles,
  Grid,
  Backdrop,
  Snackbar,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../components/context/auth";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import "react-circular-progressbar/dist/styles.css";
import { useMediaQuery } from "react-responsive";
// import { MAP } from './map';
import mainProduct from "../assets/img/main_product.svg";
import Ellipse7 from "../assets/img/NEllipse.svg";
import star from "../assets/img/star_home.svg";
import mahimoSvg from "../assets/img/mahimoSvg.svg";
import axios from "axios";
import "../styles.css";
import CameraLayout from "../components/shared/CameraLayout";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import arrowRight from "../assets/img/arrow-right.svg";
import SideLayout from "../components/quiz/SideLayout.js";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "../pages/Quiz/RadialSeparators";

import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { set } from "nprogress";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
  bottomGradient: {
    position: "fixed",
    width: "33.625rem",
    height: "33.625rem",
    bottom: "0",
    left: "9rem",
    background: "rgba(255, 187, 88, 0.12)",
    filter: "blur(209px)",
    borderRadius: "50%",
    marginBottom: "-20rem",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },
  topGradientMobile: {
    position: "fixed",
    width: "43.625rem",
    height: "43.625rem",
    top: "-22rem",
    right: "-22rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },

  bottomGradientMobile: {
    position: "fixed",
    width: "33.625rem",
    height: "33.625rem",
    bottom: "0",
    left: "-5rem",
    background: "rgba(255, 187, 88, 0.12)",
    filter: "blur(209px)",
    borderRadius: "50%",
    marginBottom: "-20rem",
    zIndex: -1,
  },
  topGradient: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "-2rem",
    marginLeft: "33rem",
    background: "rgba(225, 187, 88, 0.12)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  Ellipse: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "17rem",
    marginLeft: "43rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  mahimoSvg: {
    position: "absolute",
    top: "34rem",
    marginLeft: "39rem",
    zIndex: 2,
  },
  buttonBox: {
    marginTop: "2rem",
    display: "flex",
  },
}));

function UserQuestions() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { token } = useContext(AuthContext);
  const history = useHistory();
  const [location, setLocationPlace] = useState();
  const [latLng, setLatLng] = useState(null);
  const [sectionFailed, setSectionFailed] = useState(false);
  const [faildMassage, setFaildMassage] = useState("Please Enter the Location");
  const { state } = useLocation();
  const handleSectionFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSectionFailed(false);
  };

  useEffect(() => {
    console.log(state);
  });
  const handleSend = async () => {
    if (latLng && location) {
      setLoading(true);
      try {
        const response = await fetch(
          `https://app.mahimo.co/user/update_user_location/`,
          {
            method: "Post",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
              lat: latLng.lat,
              lon: latLng.lng,
              city: location.address_components[0].long_name,
              country: location.address_components[3].long_name,
            }),
          }
        );

        if (!response.ok) {
          setLoading(false);
          setSectionFailed(true);
          throw new Error("ERORRR");
        } else {
          setLoading(false);

          if (state && state.state.form) {
            history.push(`/quiz/result/${state.state.form}`);
          } else {
            history.push("/profile");
          }
        }
      } catch (err) {
        console.log(err);
      }
      return;
    } else {
      setSectionFailed(true);
      return;
    }
  };

  const handleNext = async (place) => {
    console.log(place);
    geocodeByAddress(place.formatted_address)
      .then((results) => getLatLng(results[0]))
      .then((location) => setLatLng(location))
      .catch((error) => console.error("Error", error));
    setLocationPlace(place);
  };

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <CameraLayout>
      {/* check the screen size */}

      <Desktop>
        <div className={classes.topGradient}></div>
        <div className={classes.Ellipse}></div>
        <div className={classes.mahimoSvg}>
          <img src={mahimoSvg} alt="defualt" zIndex="2" style={{ zIndex: 1 }} />
        </div>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
        <Snackbar
          open={sectionFailed}
          autoHideDuration={3000}
          onClose={handleSectionFailed}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleSectionFailed} severity="error">
            {faildMassage}
          </Alert>
        </Snackbar>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{
              justifyContent: "flex-end",
              padding: "10px",
              marginTop: "-20px",
              marginRight: "40px",
              display: "flex",
            }}
          >
            <img src={star} alt="" style={{ marginBottom: "-32px" }} />
          </GridItem>
        </GridContainer>
        <GridContainer></GridContainer>
        <GridContainer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "start",
            justify: "start",

            justifyContent: "center",
          }}
        >
          {/* first section  percent*/}

          <Grid xs={12} sm={12} md={12} lg={7} style={{ marginTop: "1rem" }}>
            <GridContainer
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                flexDirection: "center",
                alignItems: "center",
                justify: "center",
                width: "80%",
                zIndex: 2,
              }}
            >
              <Typography className="text-location-desktop">
                Where would you be staying for the next 2 months?
              </Typography>

              <Autocomplete
                apiKey={"AIzaSyA--TYG6yi_zl4Hp8BNChh-lYOaob0wa2c"}
                required
                style={{
                  fontFamily: "Playfair Display",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "26px",
                  lineHeight: "20px",
                  backgroundImage: "none",
                  lable: "Toronto",
                  content: "none",
                  width: "60%",
                  marginTop: "20px",
                  alignSelf: "left",
                  textAlign: "left",
                  marginLeft: "20px",
                  color: "#686D7A",
                  border: "none",
                  padding: "10px 15px",
                  outline: "none",
                  borderBottom: "2px solid #522953",
                }}
                onPlaceSelected={(place, autocomplete) => {
                  handleNext(place);
                  // setAddress(place);
                }}
                defaultValue={location ? location.formatted_address : ""}
              />
            </GridContainer>
            <div className={classes.buttonBox}>
              <div
                style={{
                  width: 55,
                  height: 55,
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 20,
                }}
              >
                <CircularProgressbarWithChildren
                  value={1}
                  strokeWidth={6}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#732F88",
                  })}
                >
                  <RadialSeparators
                    key={"678"}
                    count={1}
                    style={{
                      background: "#fff",
                      width: "2px",
                      // This needs to be equal to props.strokeWidth
                      height: `${10}%`,
                    }}
                  />
                  <Box
                    style={{
                      height: 40,
                      width: 40,

                      borderRadius: 360,
                      background: "#732F88",
                      justifyContent: "center",
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <IconButton variant="text" onClick={handleSend}>
                      <img
                        src={arrowRight}
                        style={{
                          position: "relative",
                          alignSelf: "center",
                        }}
                        alt=""
                      />
                    </IconButton>
                  </Box>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Grid>

          {/* second section */}
          <SideLayout />
        </GridContainer>

        <Box className={classes.bottomGradient}></Box>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
      </Desktop>
      {/* Tablet or Mobile */}
      <Tablet>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
        <Snackbar
          open={sectionFailed}
          autoHideDuration={3000}
          onClose={handleSectionFailed}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleSectionFailed} severity="error">
            {faildMassage}
          </Alert>
        </Snackbar>
        <div className={classes.topGradientMobile}></div>
        <GridContainer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justify: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
          }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            style={{
              justifyContent: "center",

              display: "flex",
            }}
            direction="column"
          >
            {" "}
            <Typography className="text-location-mobile">
              Where would you be staying for the next 2 months?
            </Typography>
            <Grid
              xs={12}
              sm={12}
              md={12}
              direction="column"
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Autocomplete
                apiKey={"AIzaSyA--TYG6yi_zl4Hp8BNChh-lYOaob0wa2c"}
                style={{
                  fontFamily: "Playfair Display",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "28px",
                  backgroundImage: "none",
                  lable: "Toronto",
                  content: "none",
                  width: "60%",
                  marginTop: "20px",
                  alignSelf: "center",
                  textAlign: "left",
                  marginBottom: "10px",
                  color: "#686D7A",
                  border: "none",
                  padding: "10px 15px",
                  outline: "none",
                  borderBottom: "2px solid #522953",
                }}
                onPlaceSelected={(place, autocomplete) => {
                  handleNext(place);
                  // setAddress(place);
                }}
                defaultValue={location ? location.formatted_address : ""}
              />
              <div className={classes.buttonBox}>
                <div
                  style={{
                    width: 55,
                    height: 55,
                    display: "flex",
                    justifyContent: "center",
                    marginRight: 20,
                  }}
                >
                  <CircularProgressbarWithChildren
                    value={1}
                    strokeWidth={6}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      pathColor: "#732F88",
                    })}
                  >
                    <RadialSeparators
                      key={"678"}
                      count={1}
                      style={{
                        background: "#fff",
                        width: "2px",
                        // This needs to be equal to props.strokeWidth
                        height: `${10}%`,
                      }}
                    />
                    <Box
                      style={{
                        height: 40,
                        width: 40,

                        borderRadius: 360,
                        background: "#732F88",
                        justifyContent: "center",
                        alignSelf: "center",
                        display: "flex",
                      }}
                    >
                      <IconButton variant="text" onClick={handleSend}>
                        <img
                          src={arrowRight}
                          style={{
                            position: "relative",
                            alignSelf: "center",
                          }}
                          alt=""
                        />
                      </IconButton>
                    </Box>
                  </CircularProgressbarWithChildren>
                </div>
              </div>{" "}
            </Grid>
          </Grid>
        </GridContainer>
      </Tablet>
    </CameraLayout>
  );
}

export default UserQuestions;
