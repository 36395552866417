import React, { useState } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import productImg from "../../assets/img/product.svg";
import minusIcon from "../../assets/img/minus.svg";
import plusIcon from "../../assets/img/plus.svg";
import trashIcon from "../../assets/img/trash.svg";

const useStyles = makeStyles((theme) => ({
  wrap: {
    "&:last-child .makeStyles-productInfo-3": {
      border: "none",
    },
  },
  productInfo: {
    marginLeft: "1.5rem",
    borderBottom: "1px solid #EBEBEB",
  },
  imgBg: {
    background: "#F6F6F6",
    width: 83,
    height: 83,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: 63,
    height: 72,
  },
  infoBox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    color: "#040415",
    fontSize: "14px",
    paddingBottom: 10,
  },
  productCount: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: "10px",
  },
}));

function ProductCart({ product, setProduct, plusProduct, minusProduct }) {
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(true);
  const classes = useStyles();

  return (
    <React.Fragment>
      {product && (
        <div className={classes.wrap}>
          <Grid container wrap="nowrap" style={{ paddingBottom: "1rem" }}>
            <Grid item>
              <div className={classes.imgBg}>
                <img src={productImg} alt="product" className={classes.img} />
              </div>
            </Grid>
            <Grid
              item
              container
              justify={"space-between"}
              className={classes.productInfo}
            >
              <Grid item className={classes.infoBox}>
                <div>${product.price * count}</div>
                <div>{product.name}</div>
                <div>Qty: {count}</div>
              </Grid>
              <Grid item className={classes.productCount}>
                <div>
                  {count <= 0 ? (
                    <Button
                      onClick={() => {
                        setProduct();
                      }}
                    >
                      <img src={trashIcon} alt="" />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setCount(count - 1);
                        minusProduct(parseFloat(product.price));
                      }}
                    >
                      <img src={minusIcon} alt="" />
                    </Button>
                  )}

                  <span>{count}</span>
                  <Button
                    onClick={() => {
                      setCount(count + 1);
                      plusProduct(parseFloat(product.price));
                    }}
                  >
                    <img src={plusIcon} alt="" />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}

export default ProductCart;
