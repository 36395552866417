import React, { useState, useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import Chart from "../../components/shared/Chart";
import sun from "../../assets/img/sun.svg";

import { useMediaQuery } from "react-responsive";
import moment from "moment";
import "./profile.css";
import Tips from "../../components/profile/Tips.js";
import Boxes from "../../components/profile/Boxes.js";
import BoxesMobile from "../../components/profile/BoxesMobile";
function Profile({ data }) {
  const [mainResult, setMainResult] = useState(null);
  const [chartData, setChartData] = useState(null);

  //check the pathname
  useEffect(() => {
    // var item = data.elasticity_tips[Math.floor(Math.random() * data.elasticity_tips.length)];
    var result = [];
    for (var i in data.forms) {
      if (data.forms[i].disorders.length > 0) {
        for (var j in data.forms[i].disorders) {
          result.push({
            name: data.forms[i].disorders[j].name,
            percentage: data.forms[i].disorders[j].percentage,
            date: moment(data.forms[i].created_at).format("MMMM"),
          });
        }
      } else {
        if (data.forms[i].disorders[0] !== undefined)
          result.push({
            name: data.forms[i].disorders[0].name,
            percentage: data.forms[i].disorders[0].percentage,
            date: moment(data.forms[i].created_at).format("MMMM"),
          });
      }
    }
    let pp = result.filter(
      (ele, ind) => ind === result.findIndex((elem) => elem.name === ele.name)
    );
    setChartData(result);
    setMainResult(pp);
  }, []);

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1440 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1439 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <div className="dashboard-div">
          <Typography className="text-24-linear-normal align-left">
            Welcome back {data.user_info.first_name}!
          </Typography>

          <div style={{ width: "250px" }}>
            <div className="dashboard-search-div">
              <input
                type="text"
                className="dashboard-input"
                placeholder="Search"
              ></input>
            </div>
          </div>
        </div>
        <Box className="dashboard-box">
          {/* <img
            src={`https://app.mahimo.co/${data.weather.icon_image}`}
            alt='weather'
          /> */}
          <img src={sun} alt="sun" />
          <Typography className="dashboard-weather-text">
            {/* {data.weather.name} */}
            It’s a Sunny Day today!
          </Typography>
          <Typography className="dashboard-weather-text-details">
            {/* {data.weather.weather_skin_suggestion} */}
            Don’t forget to take your water bottle with you.
          </Typography>
        </Box>

        {/* second box */}
        <Boxes mainResult={mainResult} />

        {/* chart */}
        <div className="dashboard-chart">
          <Chart data={chartData} />
        </div>
      </Desktop>
      {/* Tablet mode */}
      <Tablet>
        <div className="dashboard-div">
          <Typography className="dashboard-mobile-welcome">
            Welcome back {data.user_info.first_name}!
          </Typography>
        </div>
        <Box className="dashboard-mobile-box-1">
          <div className="dashboard-mobile-box-1-div">
            {/* <img
              src={`https://app.mahimo.co/${data.weather.icon_image}`}
              alt='weather'
            /> */}
            <img src={sun} alt="sun" />
          </div>
          <div className="dashboard-mobile-box-1-div-2">
            <Typography className="dashboard-mobile-weather-title">
              {/* {data.weather.name} */}
              It’s a Sunny Day today!
            </Typography>
            <Typography className="dashboard-mobile-weather-description">
              {/* {data.weather.weather_skin_suggestion} */}
              Don’t forget to take your water bottle with you.
            </Typography>
          </div>
        </Box>

        {/* second box */}
        {/* second box */}
        <BoxesMobile mainResult={mainResult} />

        {/* chart */}
        <div className="dashboard-mobile-chart">
          <Chart data={chartData} />
        </div>
        {/* last box */}
      </Tablet>
      {/* inner box  */}
      <Tips data={data} />
    </>
  );
}

export default Profile;
