import React from 'react';
import Header from './NHeader';

function Layout(props) {
  return (
    <React.Fragment>
      <Header />
      <main style={{ padding: '1rem 0' }}>{props.children}</main>
    </React.Fragment>
  );
}

export default Layout;
