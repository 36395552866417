import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  makeStyles,
  TextField,
  SwipeableDrawer,
  Hidden,
  Backdrop,
  Modal,
  Box,
  Grid,
} from "@material-ui/core";
import { AuthContext } from "../../components/context/auth";
import closeIcon from "../../assets/img/x.svg";
import closeDrawerIcon from "../../assets/img/drawer-close.svg";
import UserInfo from "../../components/panel/UserInfo";
import UserInfoReadOnly from "../../components/panel/UserInfoReadOnly";
import UserAddressReadOnly from "../../components/panel/UserAddressReadOnly";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import { useMediaQuery } from "react-responsive";
import "./profile.css";
const useStyles = makeStyles((theme) => ({
  active: {
    color: "#742B88",
    borderRadius: 0,
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  profileBox: {
    padding: "15px 0",
  },
  profileHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  boxTitle: {
    color: "#000618",
    fontSize: 20,
    fontWeight: 600,
  },
  drawer: {
    padding: 10,
  },
  drawerHead: {
    padding: "10px 0",
    borderBottom: "1px solid #eee",
    fontSize: 20,
    color: "#000618",
    marginBottom: 30,
  },
  modalWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  modalBox: {
    background: "#fff",
    width: 480,
    padding: 25,
  },
  modalHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    color: "#000618",
    marginBottom: 30,
  },
  drawerClose: {
    textAlign: "center",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));

function Profile() {
  const [infoDrawer, setInfoDrawer] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [userAddress, setUserAddress] = useState();
  const { token, wpToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setLoading(true);
        const response = await fetch("https://app.mahimo.co/user/profile/", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (!response.ok) {
          throw new Error("ERORRR");
        }

        const responseData = await response.json();

        setUserInfo(responseData);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    //load order list
    const loadData = async () => {
      try {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ wc_token: wpToken });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch("https://app.mahimo.co/user/get_user_orders/", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setUserAddress(result.shipping_address);
            setLoading(false);
          })
          .catch((error) => console.log("error", error));
      } catch (err) {
        console.log(err);
      }
    };
    if (!userInfo) sendRequest();
    if (!userAddress) {
      loadData();
    }
    console.log(wpToken);
  }, [token, wpToken]);
  //loading profile

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1440 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1439 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "4rem" }}>
          <span className="heading-name">
            <p className={classes.active}>Setting</p>

            {/* {tab.chat && <span>Provider Messages</span>} */}
          </span>
          <div className="heading-bg"></div>
          {/* <h2 className='heading-title'>Profile</h2> */}
        </div>
        <div>
          {/* <Button
          className={tab.chat && classes.active}
          onClick={() =>
            setTab({ treatments: false, profile: false, chat: true })
          }
        >
          Chat
        </Button> */}
        </div>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Box className="profile-main-box">
              <div className={classes.profileBox}>
                <div className={classes.profileHead}>
                  <span className={classes.boxTitle}>Account information</span>
                  <Button
                    color="primary"
                    style={{ fontSize: 15, fontWeight: 600 }}
                    onClick={() => {
                      setInfoDrawer(true);
                      setInfoModal(true);
                    }}
                  >
                    Edit
                  </Button>
                </div>
                <>
                  <Hidden xsDown>
                    <Modal open={infoModal} onClose={() => setInfoModal(false)}>
                      <div className={classes.modalWrapper}>
                        <div className={classes.modalBox}>
                          <div className={classes.modalHead}>
                            <span>Edit info</span>
                            <Button onClick={() => setInfoModal(false)}>
                              <img src={closeIcon} alt="" />
                            </Button>
                          </div>
                          {<UserInfo data={userInfo} />}
                        </div>
                      </div>
                    </Modal>
                  </Hidden>
                  <Hidden smUp>
                    <SwipeableDrawer
                      anchor="bottom"
                      open={infoDrawer}
                      onClose={() => setInfoDrawer(false)}
                      onOpen={() => setInfoDrawer(true)}
                    >
                      <div className={classes.drawer}>
                        <div className={classes.drawerClose}>
                          <Button onClick={() => setInfoDrawer(false)}>
                            <img src={closeDrawerIcon} alt="" />
                          </Button>
                        </div>
                        <div className={classes.drawerHead}>
                          <span>Edit info</span>
                        </div>
                        {<UserInfo data={userInfo} />}
                      </div>
                    </SwipeableDrawer>
                  </Hidden>
                </>

                <UserInfoReadOnly userInfo={userInfo} />
              </div>
            </Box>
          </Grid>
          {/* address information */}
          <Grid item xs={6}>
            {userAddress && (
              <Box className="profile-main-box">
                <div className={classes.profileBox}>
                  <div className={classes.profileHead}>
                    <span className={classes.boxTitle}>
                      Address information
                    </span>
                  </div>

                  <UserAddressReadOnly userAddress={userAddress} />
                </div>
              </Box>
            )}
          </Grid>
        </Grid>
      </Desktop>
      <Tablet>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "2rem" }}>
          <div className="heading-bg">
            {" "}
            <p className={classes.active}>Setting</p>
          </div>
          {/* <h2 className='heading-title'>Profile</h2> */}
        </div>
        <div></div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box className="profile-main-box">
              <div className={classes.profileBox}>
                <div className={classes.profileHead}>
                  <span className={classes.boxTitle}>Account information</span>
                  <Button
                    color="primary"
                    style={{ fontSize: 15, fontWeight: 600 }}
                    onClick={() => {
                      setInfoDrawer(true);
                      setInfoModal(true);
                    }}
                  >
                    edit
                  </Button>
                </div>
                <>
                  <Hidden xsDown>
                    <Modal open={infoModal} onClose={() => setInfoModal(false)}>
                      <div className={classes.modalWrapper}>
                        <div className={classes.modalBox}>
                          <div className={classes.modalHead}>
                            <span>Edit info</span>
                            <Button onClick={() => setInfoModal(false)}>
                              <img src={closeIcon} alt="" />
                            </Button>
                          </div>
                          {<UserInfo data={userInfo} />}
                        </div>
                      </div>
                    </Modal>
                  </Hidden>
                  <Hidden smUp>
                    <SwipeableDrawer
                      anchor="bottom"
                      open={infoDrawer}
                      onClose={() => setInfoDrawer(false)}
                      onOpen={() => setInfoDrawer(true)}
                    >
                      <div className={classes.drawer}>
                        <div className={classes.drawerClose}>
                          <Button onClick={() => setInfoDrawer(false)}>
                            <img src={closeDrawerIcon} alt="" />
                          </Button>
                        </div>
                        <div className={classes.drawerHead}>
                          <span>Edit info</span>
                        </div>
                        {<UserInfo data={userInfo} />}
                      </div>
                    </SwipeableDrawer>
                  </Hidden>
                </>

                <UserInfoReadOnly userInfo={userInfo} />
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {userAddress && (
              <Box className="profile-main-box">
                <div className={classes.profileBox}>
                  <div className={classes.profileHead}>
                    <span className={classes.boxTitle}>
                      Address information
                    </span>
                  </div>
                  <UserAddressReadOnly userAddress={userAddress} />
                </div>
              </Box>
            )}
          </Grid>
        </Grid>
      </Tablet>
    </>
  );
}

export default Profile;
