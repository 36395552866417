import React, { useContext } from "react";
import { Grid, Button, Typography, Paper } from "@material-ui/core";
import { AuthContext } from "../context/auth";
import GridItem from "../Grid/GridItem.js";

export default function Index({ products, defaultProduct }) {
  const { jwtToken } = useContext(AuthContext);
  return (
    <GridItem
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{
        justifyContent: "center",
        marginTop: "8rem",
        marginBottom: "8rem",
      }}
    >
      {products &&
        products.map((product, idx) => (
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            spacing={12}
            container
            style={{
              justifyContent: "flex-start",
              display: "flex",
              marginTop: "8rem",
            }}
          >
            {idx % 2 ? (
              <>
                <GridItem xs={12} sm={12} md={12} lg={9}>
                  <Typography className="product-text-name">
                    {product.name}
                  </Typography>
                  <Typography className="  product-text-detail">
                    {product.description}
                  </Typography>
                  <Typography className="product-text-Ingredients">
                    Featyred Ingredients
                  </Typography>

                  <div
                    style={{
                      width: "80%",
                      overflow: "auto",
                      display: "flex",
                    }}
                  >
                    {product && (
                      <>
                        {product.ingredients.map((p) => (
                          <div
                            style={{
                              margin: "8px",
                            }}
                          >
                            <Paper
                              elevation={0}
                              style={{
                                height: "100%",
                                width: "109px",
                                background: "transparent",
                              }}
                            >
                              <>
                                {p.ingredient && (
                                  <>
                                    <img
                                      src={`https://app.mahimo.co/${p.ingredient.image}`}
                                      // src={`https://app.mahimo.co/media/Mahimo_Products_Front_UYlT1aA.jpg`}
                                      alt=""
                                      style={{
                                        alignItems: "center",
                                        width: "109px",
                                        height: "90px",
                                      }}
                                    />
                                    <Typography className="product-text-Ingredients-name">
                                      {p.ingredient.name}
                                    </Typography>
                                  </>
                                )}
                              </>
                            </Paper>
                          </div>
                        ))}
                      </>
                    )}
                  </div>

                  <Grid item md={5}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="product-routine"
                    >
                      {/* Start your routine now! Only {product.price}$ */}
                      {defaultProduct && (
                        <a
                          href={
                            jwtToken
                              ? defaultProduct[0].related_link +
                                "?mo_jwt_token=" +
                                jwtToken
                              : defaultProduct[0].related_link
                          }
                        >
                          <span>
                            Start your routine now! Only $
                            {defaultProduct[0].price}
                          </span>
                        </a>
                      )}
                    </Button>
                  </Grid>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  style={{
                    justifyContent: "center",
                  }}
                  display="flex"
                >
                  {product.images[0] && (
                    <div className="product-images-div">
                      {/* //180 270 _ 170 174 */}
                      <img
                        src={`https://app.mahimo.co${product.images[0].image}`}
                        alt="product"
                        width="190"
                        height="280"
                      />
                    </div>
                  )}
                </GridItem>
              </>
            ) : (
              <>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  style={{
                    justifyContent: "center",
                  }}
                  display="flex"
                >
                  {product.images[0] && (
                    <div className="product-images-div">
                      {/* //180 270 _ 170 174 */}
                      <img
                        src={`https://app.mahimo.co${product.images[0].image}`}
                        alt="product"
                        width="190"
                        height="280"
                      />
                    </div>
                  )}
                </Grid>
                <GridItem xs={12} sm={12} md={12} lg={9}>
                  <Typography className="product-text-name">
                    {product.name}
                  </Typography>
                  <Typography className="  product-text-detail">
                    {product.description}
                  </Typography>

                  <Typography className="product-text-Ingredients">
                    Featyred Ingredients
                  </Typography>

                  <div
                    style={{
                      width: "80%",
                      overflow: "auto",
                      display: "flex",
                    }}
                  >
                    {product && (
                      <>
                        {product.ingredients.map((p) => (
                          <div
                            style={{
                              margin: "8px",
                            }}
                          >
                            <Paper
                              elevation={0}
                              style={{
                                height: "100%",
                                width: "109px",
                                background: "transparent",
                              }}
                            >
                              <>
                                {p.ingredient && (
                                  <>
                                    <img
                                      src={`https://app.mahimo.co/${p.ingredient.image}`}
                                      // src={`https://app.mahimo.co/media/Mahimo_Products_Front_UYlT1aA.jpg`}
                                      alt=""
                                      style={{
                                        alignItems: "center",
                                        width: "109px",
                                        height: "90px",
                                      }}
                                    />
                                    <Typography className="product-text-Ingredients-name">
                                      {p.ingredient.name}
                                    </Typography>
                                  </>
                                )}
                              </>
                            </Paper>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <Grid item md={5}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="product-routine"
                    >
                      {defaultProduct && (
                        <a
                          href={
                            jwtToken
                              ? defaultProduct[0].related_link +
                                "?mo_jwt_token=" +
                                jwtToken
                              : defaultProduct[0].related_link
                          }
                        >
                          <span>
                            Start your routine now! Only $
                            {defaultProduct[0].price}
                          </span>
                        </a>
                      )}
                    </Button>
                  </Grid>
                </GridItem>
              </>
            )}
          </Grid>
        ))}
    </GridItem>
  );
}
