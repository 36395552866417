import React, { useState, useEffect } from "react";

import { Typography, Grid } from "@material-ui/core";
import GridItem from "../Grid/GridItem.js";
import Frame from "../../assets/img/frame.svg";
function Index({ imgSrc }) {
  return (
    <GridItem
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{
        justifyContent: "center",
        display: "flex",
        marginTop: "-10px",
      }}
      direction="column"
    >
      <Typography className="text-home-mobile-take-quiz">
        Take picture or go to the next Section
      </Typography>

      <Typography
        className="desktop-text"
        style={{
          visibility: imgSrc ? "hidden" : "visible",
        }}
      >
        Before taking a photo, please note these four items:
      </Typography>

      <Grid
        container
        spacing={1}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          marginTop: "30px",
          justifyContent: "center",
          display: "flex",
          marginBottom: "-173px",
        }}
      >
        <img
          src={Frame}
          alt=""
          style={{
            zIndex: "10",
            visibility: imgSrc ? "hidden" : "visible",
          }}
        />
      </Grid>
    </GridItem>
  );
}

export default Index;
