import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { AuthContext } from '../context/auth';

const UserInfoReadOnly = ({ userInfo }) => {
  const { tokenGoogle } = useContext(AuthContext);
  const [socialProfile, setSocialProfile] = useState();

  useEffect(() => {
    if (tokenGoogle) {
      const sendRequest = async () => {
        const response = await fetch(
          `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${tokenGoogle}`
        );

        const responseData = await response.json();

        setSocialProfile(responseData);
      };
      sendRequest();
    }
  }, [tokenGoogle]);

  return (
    <>
      {(socialProfile || userInfo) && (
        <div style={{ width: '100%' }}>
          <TextField
            id='standard-read-only-input'
            label='Name'
            defaultValue={
              tokenGoogle ? socialProfile.given_name : userInfo.first_name
            }
            InputProps={{
              readOnly: true,
            }}
            margin='normal'
            style={{ display: 'block' }}
            fullWidth
            variant='outlined'
          />
          <TextField
            id='standard-read-only-input'
            label='Last Name'
            defaultValue={
              tokenGoogle ? socialProfile.family_name : userInfo.last_name
            }
            InputProps={{
              readOnly: true,
            }}
            margin='normal'
            style={{ display: 'block' }}
            fullWidth
            variant='outlined'
          />
          <TextField
            id='standard-read-only-input'
            label='Email'
            defaultValue={tokenGoogle ? socialProfile.email : userInfo.email}
            InputProps={{
              readOnly: true,
            }}
            margin='normal'
            style={{ display: 'block' }}
            fullWidth
            variant='outlined'
          />
        </div>
      )}
    </>
  );
};

export default UserInfoReadOnly;
