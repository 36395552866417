import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LOGO from "../../assets/img/Logo.svg";
import profile from "../../assets/img/profile.svg";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth";
import { Typography } from "@material-ui/core";

import SideBar from "../NavBar/SideBar";
const useStyles = makeStyles((theme) => ({
  header: {
    padding: " 1rem 0",
  },
  header_Links: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuLink: {
    padding: "0 10px",
    display: "flex",
    zIndex: "2",
  },
}));

function Header() {
  const classes = useStyles();
  const { token, tokenGoogle } = useContext(AuthContext);

  return (
    <>
      {(token || tokenGoogle) && (
        <>
          {" "}
          <SideBar />
          <div style={{ height: "100px", width: "100%" }}></div>
        </>
      )}
      {!token && (
        <header className={classes.header}>
          <div className={classes.header_Links}>
            <Link to="/">
              <img src={LOGO} alt="" />
            </Link>

            <React.Fragment>
              <div>
                <Link to="/login" className={classes.menuLink}>
                  <img src={profile} alt="profile" />
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: "rgba(51, 51, 51, 1)",
                    }}
                  >
                    {" "}
                    Login
                  </Typography>
                </Link>
              </div>
            </React.Fragment>
          </div>
        </header>
      )}
    </>
  );
}

export default Header;
