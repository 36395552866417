import React, { useState } from "react";
import { makeStyles, Typography, Paper, Box } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";

import "../../pages/profile/profile.css";
const useStyles = makeStyles((theme) => ({
  grid2: {
    height: "100%",
    width: "100%",
    background: "transparent",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    padding: "0px 20px 10px 0px",
    marginBottom: "10px",
    ["@media only screen and (max-width: 600px)"]: {
      /* For mobile phones: */
      gridTemplateColumns: "minmax(min-content, 1fr)",
    },
    rowGap: "12px",
    columnGap: "12px",
  },
}));

function Tips({ data }) {
  const classes = useStyles();
  const [tip1, setTip1] = useState(
    data.elasticity_tips[
      Math.floor(Math.random() * data.elasticity_tips.length)
    ]
  );
  const [tip2, setTip2] = useState(
    data.elasticity_tips[
      Math.floor(Math.random() * data.elasticity_tips.length)
    ]
  );
  const [tip3, setTip3] = useState(
    data.elasticity_tips[
      Math.floor(Math.random() * data.elasticity_tips.length)
    ]
  );

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1440 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1439 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        {/* last box */}
        <Box className="dashboard-last-box">
          <Box className="dashboard-last-box-2">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography className="dashboard-tips-text-title">
                Elasticity tips
              </Typography>
              <Typography className="dashboard-tips-text-description">
                Losing skin elasticity leads to early signs of aging
              </Typography>
            </div>
          </Box>
          {/* inner box  */}
          <Box className="dashboard-box-inner">
            <Box className="dashboard-box-inner-2">
              <div className="dashboard-box-inner-2-div">
                <img
                  src={`https://app.mahimo.co/${tip1.image}`}
                  alt="weather"
                />
              </div>
              <Typography className="dashboard-box-inner-tip-title">
                {tip1.name}
              </Typography>
              <Typography className="dashboard-box-inner-tip-description">
                {`${tip1.description.substring(0, 90)}... `}
              </Typography>
            </Box>
            <Box className="dashboard-box-inner-2">
              <div className="dashboard-box-inner-2-div">
                <img
                  src={`https://app.mahimo.co/${tip2.image}`}
                  alt="weather"
                />
              </div>
              <Typography className="dashboard-box-inner-tip-title">
                {tip2.name}
              </Typography>
              <Typography className="dashboard-box-inner-tip-description">
                {`${tip2.description.substring(0, 90)}... `}
              </Typography>
            </Box>
            <Box className="dashboard-box-inner-2">
              <div className="dashboard-box-inner-2-div">
                <img
                  src={`https://app.mahimo.co/${tip3.image}`}
                  alt="weather"
                />
              </div>
              <Typography className="dashboard-box-inner-tip-title">
                {tip3.name}
              </Typography>
              <Typography className="dashboard-box-inner-tip-description">
                {`${tip3.description.substring(0, 90)}... `}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Desktop>
      {/* Tablet mode */}
      <Tablet>
        {/* last box */}
        <Box className="dashboard-mobile-last-box">
          <Box className="dashboard-mobile-last-box-box">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography className="dashboard-mobile-tips-title ">
                Elasticity tips
              </Typography>
              <Typography className="dashboard-mobile-tips-description ">
                Losing skin elasticity leads to early signs of aging
              </Typography>
            </div>
          </Box>
          {/* inner box  */}
          <Box className="dashboard-mobile-last-box-inner-box">
            <Paper elevation={0} className={classes.grid2}>
              <Box className="dashboard-mobile-last-box-inner-box-2">
                <div>
                  <img
                    src={`https://app.mahimo.co/${tip1.image}`}
                    alt="weather"
                  />
                </div>
                <Typography className="dashboard-mobile-tips-item-title">
                  {tip1.name}
                </Typography>
                <Typography className="dashboard-mobile-tips-item-description">
                  {`${tip1.description.substring(0, 100)}... `}
                </Typography>
              </Box>
              <Box className="dashboard-mobile-last-box-inner-box-3">
                <div>
                  <img
                    src={`https://app.mahimo.co/${tip2.image}`}
                    alt="weather"
                  />
                </div>
                <Typography className="dashboard-mobile-tips-item-title">
                  {tip2.name}
                </Typography>
                <Typography className="dashboard-mobile-tips-item-description">
                  {`${tip2.description.substring(0, 100)}... `}
                </Typography>
              </Box>
              <Box className="dashboard-mobile-last-box-inner-box-4">
                <div>
                  <img
                    src={`https://app.mahimo.co/${tip3.image}`}
                    alt="weather"
                  />
                </div>
                <Typography className="dashboard-mobile-tips-item-title">
                  {tip3.name}
                </Typography>
                <Typography className="dashboard-mobile-tips-item-description">
                  {`${tip3.description.substring(0, 100)}... `}
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Tablet>
    </>
  );
}

export default Tips;
