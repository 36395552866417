import React, { useEffect, useState, useContext } from 'react';

import MobileQuiz from './MobileQuiz';
import DesktopQuiz from './DesktopQuiz';
import CameraLayout from '../../components/shared/CameraLayout';
import { useMediaQuery } from 'react-responsive';

const QuizSingle = () => {
  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <CameraLayout>
      {/* check the screen size */}
      <Desktop>
        <DesktopQuiz />
      </Desktop>
      {/* Tablet or Mobile */}
      <Tablet>
        <MobileQuiz />
      </Tablet>
    </CameraLayout>
  );
};

export default QuizSingle;
