import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  makeStyles,
  Hidden,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Backdrop,
  TablePagination,
  TableFooter,
  Paper,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { AuthContext } from "../../components/context/auth";
import VisibilityIcon from "@material-ui/icons/Visibility";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import moment from "moment";
import "./profile.css";
import { useMediaQuery } from "react-responsive";
const useStyles = makeStyles((theme) => ({
  active: {
    color: "#742B88",
    borderRadius: 0,
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },

  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));

function Profile() {
  const [tab, setTab] = useState({
    treatments: true,
    profile: false,
    chat: false,
  });
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState();
  const history = useHistory();
  const [tableCount, SetTablecCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();

  //load quiz list
  const loadData = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://app.mahimo.co/user/my-forms/", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error("ERORRR");
      }

      const responseData = await response.json();
      SetTablecCount(responseData.length);
      setdata(responseData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!data) loadData();
  }, [data]);

  //pagenation
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1440 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1439 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "4rem" }}>
          <span className="heading-name">
            <p className={classes.active}>Treatments</p>
          </span>
        </div>
        <Box className="profile-main-box">
          <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.head}>
                <TableRow style={{ borderBottom: "2px solid #451C52" }}>
                  <TableCell
                    align="center"
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "rgba(69, 28, 82, 1)",
                    }}
                  >
                    Quiz title
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "rgba(69, 28, 82, 1)",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "rgba(69, 28, 82, 1)",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && (
                  <>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "20px",
                              lineHeight: "30px",
                              color: "rgba(69, 28, 82, 1)",
                            }}
                          >
                            {row.quiz_obj.title}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "20px",
                              lineHeight: "30px",
                              color: "rgba(130, 130, 130, 1)",
                            }}
                          >
                            {moment(row.created_at).format("MMM Do YY")}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                //   openDialog(row);
                                history.push(`/quiz/result/${row.id}`);
                              }}
                              style={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "20px",
                                lineHeight: "30px",
                                color: "rgba(130, 130, 130, 1)",
                              }}
                            >
                              <Hidden xsDown>View</Hidden>
                              <span
                                style={{
                                  width: 5,
                                  display: "inline-block",
                                }}
                              />
                              <VisibilityIcon className={classes.icon} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableFooter
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "start",
              position: "relative",
            }}
          >
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "all", value: -1 }]}
                colSpan={3}
                count={tableCount}
                rowsPerPage={rowsPerPage}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                variant="outlined"
                shape="rounded"
                style={{ color: "secondary" }}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Box>
      </Desktop>
      <Tablet>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "2rem" }}>
          <span className="heading-name">
            <p className={classes.active}>Treatments</p>

            {/* {tab.chat && <span>Provider Messages</span>} */}
          </span>
        </div>
        <div></div>
        <Box className="profile-main-box">
          <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.head}>
                <TableRow style={{ borderBottom: "2px solid #451C52" }}>
                  <TableCell
                    align="center"
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "rgba(69, 28, 82, 1)",
                    }}
                  >
                    Quiz title
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "rgba(69, 28, 82, 1)",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "rgba(69, 28, 82, 1)",
                    }}
                  >
                    Show
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && (
                  <>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "20px",
                              lineHeight: "30px",
                              color: "rgba(69, 28, 82, 1)",
                            }}
                          >
                            {row.quiz_obj.title}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "20px",
                              lineHeight: "30px",
                              color: "rgba(130, 130, 130, 1)",
                            }}
                          >
                            {moment(row.created_at).format("MMM Do YY")}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                //   openDialog(row);
                                history.push(`/quiz/result/${row.id}`);
                              }}
                              style={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "20px",
                                lineHeight: "30px",
                                color: "rgba(130, 130, 130, 1)",
                              }}
                            >
                              <Hidden xsDown>Show</Hidden>
                              <span
                                style={{
                                  width: 5,
                                  display: "inline-block",
                                }}
                              />
                              <VisibilityIcon className={classes.icon} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
            {/* <UserTable
              openUser={userOpen}
              list={userList}
              handleUserClose={() => {
                setUserOpen(false);
              }}
            /> */}
          </TableContainer>
          <TableFooter
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "center",
              width: "100%",
            }}
          >
            <TableRow
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "center",
                width: "100%",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "all", value: -1 }]}
                colSpan={10}
                count={tableCount}
                rowsPerPage={rowsPerPage}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                variant="outlined"
                shape="rounded"
                style={{ color: "secondary" }}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Box>
      </Tablet>
    </>
  );
}

export default Profile;
