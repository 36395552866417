import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  SwipeableDrawer,
  Hidden,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Backdrop,
  CircularProgress,
  TablePagination,
  TableFooter,
  Modal,
  Paper,
  Box,
  Icon,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../components/context/auth";
import Layout from "../../components/shared/Layout";
import NLayout from "../../components/shared/NLayout";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import SideBar from "../../components/NavBar/SideBar";
import DashboardBar from "../../components/NavBar/Dashboardbar";
import { useMediaQuery } from "react-responsive";
import DashboardPage from "./dashboard";
import QuizePage from "./Quiz";
import PersonalPage from "./Personal";
import SettingsPage from "./setting";
import Order from "./Order";
import ViewOrder from "./ViewOrder";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },

  topGradient: {
    position: "absolute",
    width: "43.625rem",
    height: "43.625rem",
    top: "-22rem",
    right: "-22rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  grid: {
    height: "100%",
    width: "100%",
    background: "transparent",
    display: "grid",
    gridTemplateColumns: "minmax(min-content, 1fr) minmax(min-content,1fr)",
    ["@media only screen and (max-width: 400px)"]: {
      /* For mobile phones: */
      gridTemplateColumns: "minmax(min-content, 1fr)",
    },
    rowGap: "12px",
    columnGap: "12px",
  },
}));

function Profile() {
  const { token, logout } = useContext(AuthContext);
  const classes = useStyles();
  const [lat, setLat] = useState(null);
  const [data, setData] = useState([]);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://app.mahimo.co/user/dashboard_info/",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        if (!response.ok) {
          throw new Error("ERORRR");
        }

        const responseData = await response.json();

        setData(responseData);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    sendRequest();
  }, [token]);

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1440 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1439 });
    return isTablet ? children : null;
  };

  const ReturnView = () => {
    switch (location.pathname) {
      case "/profile":
        return <DashboardPage data={data} />;
      case "/profile/quiz":
        return <QuizePage />;
      case "/profile/personal":
        return <PersonalPage />;
      case "/profile/settings":
        return <SettingsPage />;
      case "/profile/orders":
        return <Order />;
      case "/profile/view/order":
        return <ViewOrder />;
      default:
        return <DashboardPage data={data} />;
    }
  };

  return (
    <>
      <Desktop>
        {loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <div id="idSvgLoading">
              <img src={mahimoSvg} alt="" />
            </div>
          </Backdrop>
        ) : (
          <>
            {" "}
            <DashboardBar data={data} />
            <NLayout>{ReturnView()}</NLayout>
          </>
        )}
      </Desktop>
      <Tablet>
        {loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <div id="idSvgLoading">
              <img src={mahimoSvg} alt="" />
            </div>
          </Backdrop>
        ) : (
          <>
            <SideBar />
            <Layout>{ReturnView()}</Layout>
          </>
        )}
      </Tablet>
    </>
  );
}

export default Profile;
