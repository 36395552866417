import React from "react";
import { Grid, Typography } from "@material-ui/core";
import GridContainer from "../Grid/GridContainer.js";
export default function Index({ userProfileQuestionsAnswers }) {
  return (
    <GridContainer
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        alignItems: "center",
        justify: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        justifyContent: "center",
        alignContent: "center",
        justifyItems: "center",
      }}
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        style={{
          justifyContent: "center",
          marginTop: "3rem",
          display: "flex",
        }}
        direction="column"
      >
        {userProfileQuestionsAnswers &&
          (userProfileQuestionsAnswers[0].user_obj.username ? (
            <Typography className="text-result-welcome">
              Hi {userProfileQuestionsAnswers[0].user_obj.username},
            </Typography>
          ) : (
            <Typography className="text-result-welcome-2">
              Hi{" "}
              {userProfileQuestionsAnswers[0].user_obj.email
                .substring(
                  0,
                  userProfileQuestionsAnswers[0].user_obj.email.lastIndexOf("@")
                )
                .slice(0, -1)}
              ,
            </Typography>
          ))}
        <Typography className="text-result-custome-treatment-mobile">
          Meet your custom treatment
        </Typography>
      </Grid>
    </GridContainer>
  );
}
