import React, { useEffect, useState, useContext } from "react";
import { makeStyles, Grid, Backdrop, Box, Typography } from "@material-ui/core";

import { AuthContext } from "../components/context/auth";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import "react-circular-progressbar/dist/styles.css";
import { useMediaQuery } from "react-responsive";
// import { MAP } from './map';
import mainProduct from "../assets/img/main_product.svg";
import Ellipse7 from "../assets/img/NEllipse.svg";
import star from "../assets/img/star_home.svg";
import mahimoSvg from "../assets/img/mahimoSvg.svg";
import axios from "axios";

import "../styles.css";
import CameraLayout from "../components/shared/CameraLayout";

import QuestionsWithAnswers from "../components/UserQuestions/QuestionsWithAnswers";
import QuestionsWithoutAnswers from "../components/UserQuestions/QuestionsWithoutAnswers";

const useStyles = makeStyles((theme) => ({
  pageHead: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    padding: "2rem 0 3rem 0",
    "& > div": {
      width: "60%",
      "& > span": {
        fontSize: "19px",
        color: "#0057ff",
      },
      "& > h2": {
        fontSize: "38px",
      },
      "& > p": {
        fontSize: "18px",
      },
    },
  },
  box: {
    background: "#fff",
    borderRadius: "12px 12px 12px 12px",
    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.11)",
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    justifyitems: "center",
  },
  boxPink: {
    background: "linear-gradient(180deg, #d9d6f9 0%,#ffffff 100%)",
    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.11)",
    padding: "2rem",
  },
  infoBox: {
    lineHeight: "30px",
    padding: "1rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#000618",
    borderBottom: "1px solid #ccc",
  },
  answer: {
    fontWeight: "bold",
  },
  productBox: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    background: "red",
  },
  userInfoBox: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    height: "100%",
  },
  productWrap: {
    marginBottom: "2rem",
  },
  productsCategoryWrap: {
    marginTop: "3rem",
    marginBottom: "2rem",
  },
  categoryTitle: {
    background: "#ffdd87",
    textAlign: "center",
    borderRadius: "1rem",
    "& span": {
      display: "block",
      fontWeight: "bold",
      fontSize: "25px",
    },
    marginBottom: "2rem",
  },
  productCategoryItem: {
    "& h2": {
      fontSize: 35,
      marginTop: 0,
    },
    "& h3": {
      fontSize: 30,
    },
    "& p": {
      fontSize: 16,
      // textAlign: 'justify',
      color: "#383838",
    },
    minHeight: "600px",
  },
  propertiesWrap: {
    width: "100%",
    padding: "5px 0",
    borderRadius: " 1rem",
    textAlign: "left",

    "& > h4": {
      fontWeight: "500",
      fontSize: "22px",
    },
  },
  propertiesBox: {
    alignItems: "start",
    justifyContent: "start",
    padding: "0 1rem",
    "& img": {
      width: "125px",
      height: "80px",
    },
  },
  productHead: {
    display: "flex",
    maxHeight: "100px",
    minHeight: "100px",
    alignItems: "center",
    "& > img": {
      width: "80px",
      height: "80px",
    },
    "& > h3": {
      marginLeft: "20px",
      fontSize: "28px",
    },
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
  bottomGradient: {
    position: "fixed",
    width: "33.625rem",
    height: "33.625rem",
    bottom: "0",
    left: "9rem",
    background: "rgba(255, 187, 88, 0.12)",
    filter: "blur(209px)",
    borderRadius: "50%",
    marginBottom: "-20rem",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },
  topGradientMobile: {
    position: "fixed",
    width: "43.625rem",
    height: "43.625rem",
    top: "-22rem",
    right: "-22rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },

  bottomGradientMobile: {
    position: "fixed",
    width: "33.625rem",
    height: "33.625rem",
    bottom: "0",
    left: "-5rem",
    background: "rgba(255, 187, 88, 0.12)",
    filter: "blur(209px)",
    borderRadius: "50%",
    marginBottom: "-20rem",
    zIndex: -1,
  },
  topGradient: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "-2rem",
    marginLeft: "33rem",
    background: "rgba(225, 187, 88, 0.12)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  Ellipse: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "17rem",
    marginLeft: "43rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  mahimoSvg: {
    position: "absolute",
    top: "34rem",
    marginLeft: "39rem",
    zIndex: 2,
  },
}));

function UserQuestions() {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { token } = useContext(AuthContext);
  const [answers, setAnswers] = useState();

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const res = await axios.get(
          `https://app.mahimo.co/user/profile/questions/answers/`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setAnswers(res.data.results);
      } catch (err) {
        console.log(err);
      }
    };

    sendRequest();
  }, [token, currentPage]);

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <CameraLayout>
      {/* check the screen size */}

      <Desktop>
        <div className={classes.topGradient}></div>
        <div className={classes.Ellipse}></div>
        <div className={classes.mahimoSvg}>
          <img src={mahimoSvg} alt="defualt" zIndex="2" style={{ zIndex: 1 }} />
        </div>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{
              justifyContent: "flex-end",
              padding: "10px",
              marginTop: "-20px",
              marginRight: "40px",
              display: "flex",
            }}
          >
            <img src={star} alt="" style={{ marginBottom: "-32px" }} />
          </GridItem>
        </GridContainer>
        <GridContainer></GridContainer>
        <GridContainer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "start",
            justify: "start",

            justifyContent: "center",
          }}
        >
          {/* first section  percent*/}

          <Grid xs={12} sm={12} md={12} lg={7} style={{ marginTop: "1rem" }}>
            <GridContainer
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                flexDirection: "center",
                alignItems: "center",
                justify: "center",
                width: "70%",
                zIndex: 2,
              }}
            >
              {answers && answers.length ? (
                <QuestionsWithAnswers
                  data={answers}
                  token={token}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : (
                <QuestionsWithoutAnswers token={token} />
              )}
            </GridContainer>
          </Grid>

          {/* second section */}
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={5}
            style={{
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                position: "relative",
                zIndex: 1,
                marginLeft: "2rem",
              }}
            >
              <Box
                style={{
                  width: "17.125rem",
                  border: "3px solid #732F88",
                  height: "26.625rem",
                  borderRadius: "18.063rem",
                  zIndex: -1,
                }}
              />
              <div
                style={{
                  background: "#E7DFEE",
                  alignItems: "center",
                  width: "17.125rem",
                  position: "absolute",
                  marginTop: "20px",
                  top: "0",
                  left: "-18px",
                  height: "26.625rem",
                  borderRadius: "18.063rem",
                  justifyContent: "center",
                  display: "flex",
                  zIndex: 0,
                }}
              >
                {/* //180 270 _ 170 174 */}
                <img
                  src={mainProduct}
                  alt="defualt"
                  width="190"
                  height="280"
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    margin: "auto",
                    zIndex: 1,
                    objectFit: "fit",
                    marginTop: "40px",
                  }}
                />
              </div>

              <div
                style={{
                  left: "80px",
                  marginTop: "5px",
                  position: "relative",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Playfair Display",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "40px",
                    lineHeight: "53px",

                    background:
                      "-webkit-linear-gradient( 103.21deg, #1F1F1F 13.3%, #521465 52.42%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    alignSelf: "left",
                  }}
                >
                  What are your main skin concerns?
                </Typography>
              </div>
            </div>
          </Grid>

          <div
            style={{
              position: "relative",
              width: "800px",
              height: "800px",
              right: "-400px",
              marginTop: "-420px",
              padding: "5px",
            }}
          >
            <img
              src={Ellipse7}
              alt="Ellips"
              style={{
                zIndex: 0,
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </GridContainer>

        <Box className={classes.bottomGradient}></Box>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
      </Desktop>
      {/* Tablet or Mobile */}
      <Tablet>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
        <div className={classes.topGradientMobile}></div>

        <GridContainer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justify: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
          }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            style={{
              justifyContent: "center",

              display: "flex",
            }}
            direction="column"
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              direction="column"
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <GridItem
                xs={12}
                sm={12}
                md={12}
                direction="column"
                container
                style={{
                  justifyContent: "left",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                }}
              >
                {answers && answers.length ? (
                  <QuestionsWithAnswers
                    data={answers}
                    token={token}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ) : (
                  <QuestionsWithoutAnswers token={token} />
                )}
              </GridItem>
            </Grid>
          </Grid>
        </GridContainer>

        <Box className={classes.bottomGradient}></Box>
      </Tablet>
    </CameraLayout>
  );
}

export default UserQuestions;
