import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, Paper, Box } from "@material-ui/core";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
export default function Index({ mainResult }) {
  const [activeItem, setActiveItem] = useState(
    mainResult ? mainResult[0].name : null
  );
  const [activeDescription, setActiveDescription] = useState(
    mainResult ? mainResult[0].description : null
  );
  //set description
  useEffect(() => {
    if (activeItem !== null) {
      var data = mainResult.filter((item) => item.name === activeItem);
      setActiveDescription(data[0].description);
    }
  }, [activeItem, mainResult]);

  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{
        marginTop: "4rem",
        display: "flex",
        flexDirection: "center",
        alignItems: "center",
        justify: "center",
      }}
    >
      <GridContainer
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "center",
          alignItems: "center",
          justify: "center",
          width: "100%",
        }}
      >
        {mainResult &&
          mainResult.map((item) => (
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                alignItems: "center",
                justify: "center",
                paddingLeft: "10px",
                paddingRight: "10px",
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                display: "flex",
              }}
            >
              <Button
                variant="text"
                onClick={() => {
                  setActiveItem(item.name);
                }}
              >
                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  margin=" 20px 0px"
                  display={"flex"}
                  width="100%"
                >
                  <div style={{ width: 60, height: 70 }}>
                    <CircularProgressbar
                      value={Math.round(parseInt(item.percentage))}
                      strokeWidth={12}
                      styles={buildStyles({
                        textSize: "10px",

                        pathColor:
                          activeItem === item.name
                            ? "#A146BD"
                            : "rgba(130, 130, 130, 1)",
                      })}
                    />
                  </div>

                  <Box
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    display={"flex"}
                    style={{
                      marginLeft: "10px",
                      width: "8.5rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "calc(4px + 10px)",
                        display: "flex",
                        alignSelf: "left",

                        color:
                          activeItem === item.name
                            ? "#76288a"
                            : "rgba(130, 130, 130, 1)",
                      }}
                    >
                      {item.name}
                      <ExpandMoreIcon />
                    </Typography>

                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        lineHeight: "27px",
                        marginTop: "-10px",
                        alignSelf: "left",
                        color:
                          activeItem === item.name
                            ? "#76288a"
                            : "rgba(130, 130, 130, 1)",
                      }}
                    >
                      {Math.round(parseInt(item.percentage))}%
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </GridItem>
          ))}
        {/* </Box> */}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          spacing={0}
          style={{ marginTop: "3rem" }}
        >
          <Box className="box-active-item-mobile"></Box>
          <Typography className="text-result-activeItem">
            {activeItem}
          </Typography>

          <Typography className="text-result-activeItem-desc">
            {activeDescription}
          </Typography>
        </GridItem>
      </GridContainer>
    </Grid>
  );
}
