import React from "react";
import { Grid, Button, Typography, Paper } from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem.js";

export default function suggest({ products }) {
  return (
    <>
      <Grid
        container
        spacing={5}
        style={{
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          alignItems: "center",
          justify: "center",
          justifyContent: "center",
          paddingBottom: "10px",
          marginTop: "5rem",
          marginBottom: "5px",
        }}
      >
        <GridItem xs={11} sm={11} md={11}>
          <Typography className="suggested-ai">
            Suggested Products by AI
          </Typography>
        </GridItem>
        <GridItem xs={1} sm={1} md={1}>
          <Button
            onClick={() => {
              //sendDoctorNote();
            }}
            style={{
              textAlign: "center",
              fontSize: "24px",

              color: "#76288a",
              float: "center",
              padding: "10px",
            }}
            disabled={true}
          >
            +
          </Button>
        </GridItem>
      </Grid>

      <div className="div-center-product">
        {products ? (
          products.map((product, idx) => (
            <div
              style={{
                margin: "16px",
              }}
            >
              <Paper style={{ height: "100%", width: "288px" }}>
                <>
                  {product.images[0] && (
                    <div class="w3-card-4">
                      <img
                        src={`https://app.mahimo.co${product.images[0].image}`}
                        style={{ width: "288px", height: "208px" }}
                        alt="profile"
                      />
                    </div>
                  )}

                  <Typography
                    style={{
                      fontSize: "calc(0.75em + 1vmin)",
                      width: "100%",
                      fontWeight: "bold",
                      textAlign: "left",
                      float: "left",
                      height: "4rem",
                    }}
                  >
                    {product.name}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "calc(0.75em + 0.5vmin)",
                      width: "100%",
                      alignItems: "end",
                      textAlign: "left",
                      float: "left",
                    }}
                  >
                    {`${product.description.substring(0, 300)}... `}
                    <a href={product.related_link}>Read more</a>
                  </Typography>
                </>
              </Paper>
            </div>
          ))
        ) : (
          <div style={{ margin: "16px" }}>
            <Paper style={{ height: "100%", width: "288px" }}>
              <>
                <Typography
                  style={{
                    fontSize: "calc(0.75em + 1vmin)",
                    width: "100%",
                    fontWeight: "bold",
                    textAlign: "left",
                    float: "left",
                    height: "466px",
                  }}
                >
                  No product Set
                </Typography>
                <Typography
                  style={{
                    fontSize: "calc(0.75em + 0.5vmin)",
                    width: "100%",
                    alignItems: "end",
                    textAlign: "left",
                    float: "left",
                  }}
                ></Typography>
              </>
            </Paper>
          </div>
        )}
      </div>
    </>
  );
}
