import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import { Container, CssBaseline } from "@material-ui/core";
import HomePage from "./pages/NHome";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgetPassword from "./pages/forgetPassword";
//import QuizSingle from './pages/QuizSingle';
import QuizSingle from "./pages/Quiz/QuizSingle";
import Camera from "./pages/NCamera"; //camera

//import ResultPage from './pages/newResult';
import ResultPage from "./pages/NResult";
import Cart from "./pages/Cart";
import Address from "./pages/Address";
import Checkout from "./pages/Checkout";
import Confirm from "./pages/Confirm";
import Profile from "./pages/profile/NProfile";
import AdminLogin from "./pages/Admin-Login";
import ScrollToTop from "./components/tools/ScrollToTop";
import { AuthContext } from "./components/context/auth";
import { useAuth } from "./hooks/useAuth";
import QuizList from "./pages/Admin/QuizList";
import QuizResult from "./pages/Admin/QuizResult";
import UserQuestions from "./pages/NUserQuestions";
import LQuestions from "./pages/LQuestions";
const theme = createMuiTheme({
  palette: {
    primary: { main: "#76288A" },
  },
  breakpoints: {
    values: {
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 360,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          fontFamily: "Inter",
          backgroundColor: "#fff",
        },
        ".MuiButton-root": {
          fontFamily: "Inter",
          textTransform: "none",
        },
        ".heading": {
          position: "relative",
          marginBottom: "1rem",
          height: "60px",
        },
        ".heading-name": {
          position: "absolute",
          height: "29px",
          left: "0px",
          right: "0px",
          top: "16px",
          fontFamily: "Red Hat Display",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "21.875px",
          lineHeight: "29px",
          color: "#000000",
          zIndex: "2",
        },
        ".heading-bg": {
          position: "absolute",
          height: "45px",
          left: "0px",
          right: "0px",
          top: "0px",
          background: "rgba(255, 255, 255, 0.001)",
        },
        ".MuiButton-contained:hover": {
          backgroundColor: "#76288a !important",
        },
        ".MuiInputBase-root": {
          color: "#666B78",
          fontFamily: "Red Hat Display",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: 16,
          lineHeight: 21,
        },
        ".MuiFormLabel-root": {
          fontFamily: "Red Hat Display !important",
          color: "#666B78 !important",
        },
        "@media (max-width: 768px)": {
          ".heading": {
            height: "80px",
          },
        },
      },
    },
  },
});

function App() {
  const {
    token,
    login,
    logout,
    accessPermission,
    access,
    wplogin,
    jwtlogin,
    jwtToken,
    wpToken,
  } = useAuth();

  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/cart" exact component={Cart} />
        <Route path="/address" exact component={Address} />
        <Route path="/checkout" exact component={Checkout} />
        <Route path="/confirm" exact component={Confirm} />
        <Route path="/camera/:id" exact component={Camera} />
        <Route path="/quiz/result/:form" exact component={ResultPage} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/profile/quiz" exact component={Profile} />
        <Route path="/quiz/:id" exact component={QuizSingle} />
        <Route path="/profile/questions" exact component={UserQuestions} />
        <Route path="/profile/location" exact component={LQuestions} />
        <Route path="/profile/personal" exact component={Profile} />
        <Route path="/profile/orders" exact component={Profile} />
        <Route path="/profile/settings" exact component={Profile} />
        <Route path="/profile/view/order" exact component={Profile} />
        <Route path="/admin/profile" component={QuizList} />
        <Route path="/admin/result/:form" exact component={QuizResult} />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={Signup} />
        <Route
          path="/account-recovery/:email/:token"
          exact
          component={ForgetPassword}
        />
        <Route path="/quiz/:id" exact component={QuizSingle} />
        <Route path="/camera/:id" exact component={Camera} />
        <Route path="/admin/login" exact component={AdminLogin} />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        token: token,
        wpToken: wpToken,
        jwtToken: jwtToken,
        login: login,
        logout: logout,
        access: access,
        wplogin: wplogin,
        jwtlogin: jwtlogin,
        accessPermission: accessPermission,
      }}
    >
      <Router>
        {/* <Switch> */}
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <CssBaseline />
            <ScrollToTop />

            <Container maxWidth="xl">{routes}</Container>
          </StylesProvider>
        </ThemeProvider>
        {/* </Switch> */}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
