import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  TextField,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Layout from "../components/shared/Layout";
import MainButton from "../components/UIElements/Button";
import Auth from "../components/shared/AuthForget";
import { useForm } from "react-hook-form";
import { AuthContext } from "../components/context/auth";
import mahimoSvg from "../assets/img/mahimoSvg.svg";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  rootInput: {
    width: "100%",
    margin: "10px 0",
  },
  forget_password: {
    color: "#000618",
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 500,
  },
  button_box: {
    marginTop: "2rem",
  },
  auth_btn: {
    width: 213,
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));

const INITIAL_FORM = {
  grant_type: "password",
  username: "",
  password: "",
};

function Login() {
  const [inputData, setInputData] = useState(INITIAL_FORM);
  const [userInfo, setUserInfo] = useState();
  const { register, handleSubmit } = useForm();
  const [passwordFailed, setPasswordFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authFailed, setAuthFailed] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { login } = useContext(AuthContext);
  const { state } = useLocation();
  const { email, token } = useParams();
  const [faildMessage, setFaildMessage] = useState("Please try again!");

  const handleAuthClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAuthFailed(false);
  };

  const handleAuthSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAuthSuccess(false);
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserInfo((preventData) => ({ ...preventData, [name]: value }));
  };

  const formSubmitHandler = async (e) => {
    if (userInfo.password !== userInfo.password_2) {
      setAuthFailed(true);
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(
        "https://app.mahimo.co/user/change-password/",
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
            user: email,
            password: userInfo.password,
          }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        // throw new Error('Error');
        setFaildMessage(responseData.errors[0].message);
        setPasswordFailed(true);
        setLoading(false);
      } else {
        setAuthSuccess(true);
        setLoading(false);
        setTimeout(() => {
          history.push("/login");
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(email);
    console.log(token);
  }, [email, token]);
  const handlePasswordClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setPasswordFailed(false);
  };

  return (
    <Layout>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <div id="idSvgLoading">
          <img src={mahimoSvg} alt="" />
        </div>
        {/* <CircularProgress color='inherit' /> */}
      </Backdrop>
      <Snackbar
        open={passwordFailed}
        autoHideDuration={6000}
        onClose={handlePasswordClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handlePasswordClose} severity="error">
          {faildMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={authFailed}
        autoHideDuration={6000}
        onClose={handleAuthClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAuthClose} severity="error">
          Password dosen't Match!
        </Alert>
      </Snackbar>
      <Snackbar
        open={authSuccess}
        autoHideDuration={6000}
        onClose={handleAuthSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAuthSuccessClose} severity="success">
          Change successful! Redirecting...
        </Alert>
      </Snackbar>
      <Auth
        headText={"Forget Password for " + email}
        formId={state && state.formId && state.formId}
      >
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <TextField
            variant="outlined"
            label="New Password"
            type="password"
            name="password"
            inputRef={register({ required: true })}
            className={classes.rootInput}
            onChange={inputChangeHandler}
          />
          <TextField
            variant="outlined"
            label="Repeat New Password"
            type="password"
            name="password_2"
            inputRef={register({ required: true })}
            className={classes.rootInput}
            onChange={inputChangeHandler}
          />

          <Grid container spacing={2} className={classes.button_box}>
            <Grid item md={6}>
              <MainButton
                background
                className={classes.auth_btn}
                variant="contained"
                type="submit"
              >
                Change Password
              </MainButton>
            </Grid>
          </Grid>
        </form>
      </Auth>
    </Layout>
  );
}

export default Login;
