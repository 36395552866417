import React, { useState, useContext } from "react";
import { makeStyles, Backdrop, Paper, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import { AuthContext } from "../../components/context/auth";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./profile.css";
const useStyles = makeStyles((theme) => ({
  active: {
    color: "#742B88",
    borderRadius: 0,
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    margin: "auto",
    maxWidth: "100%",
    marginBottom: "12px",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));

function ViewOrder() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1440 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1439 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "4rem" }}>
          <span className="heading-name">
            <p className={classes.active}>Orders</p>
          </span>
        </div>
        <Box className="profile-main-box">
          {location.state.data && (
            <>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={16}>
                  <Grid item xs>
                    <Typography gutterBottom variant="subtitle1">
                      Order: {location.state.data.id}
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                      Date:{" "}
                      {moment(location.state.data.date_created).format(
                        "MMM Do YY"
                      )}
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Status:{location.state.data.status}
                  </Typography>
                </Grid>
              </Grid>
              {location.state.data.line_items.map((row) => (
                <div className={classes.root}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={16}>
                      <Grid item>
                        <ButtonBase className={classes.image}>
                          <img
                            className={classes.img}
                            alt="product"
                            src={
                              row.meta_data[1]
                                ? row.meta_data[1].value
                                : "https://app.mahimo.co/media/Copy_of_Mahimo_Products_Front_190_x_280_px.png"
                            }
                          />
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={16}>
                          <Grid item xs>
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                            ></Typography>
                            <Typography gutterBottom>{row.name}</Typography>
                            <Typography color="textSecondary">
                              quantity: {row.quantity}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1">
                            $ {row.total}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
              <br />
              <Paper className={classes.paper}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={16}>
                    <Grid item xs>
                      <Typography color="textSecondary">
                        Shipping: Free shipping
                      </Typography>
                      <Typography color="textSecondary">
                        Tax: ${location.state.data.total_tax}
                      </Typography>
                      <Typography color="textSecondary">
                        Payment method: {location.state.data.payment_method}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      Total: $ {location.state.data.total}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              <Paper className={classes.paper}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={16}>
                    <Grid item xs>
                      <Typography variant="subtitle1">
                        Customer details{" "}
                      </Typography>
                      <Typography color="textSecondary">
                        Email: {location.state.data.billing.email}{" "}
                        {location.state.data.billing.last_name}
                      </Typography>
                      <Typography color="textSecondary">
                        Phone: {location.state.data.billing.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Paper className={classes.paper}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={16}>
                    <Grid item xs>
                      <Typography variant="subtitle1">
                        Billing address{" "}
                      </Typography>
                      <Typography color="textSecondary">
                        {location.state.data.billing.first_name}{" "}
                        {location.state.data.billing.last_name}
                      </Typography>
                      <Typography color="textSecondary">
                        {location.state.data.billing.address_1}
                      </Typography>
                      <Typography color="textSecondary">
                        {location.state.data.billing.address_2}
                      </Typography>
                      <Typography color="textSecondary">
                        {location.state.data.billing.city}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      Shipping address{" "}
                    </Typography>
                    <Typography color="textSecondary">
                      {location.state.data.shipping.first_name}
                      {location.state.data.shipping.last_name}
                    </Typography>
                    <Typography color="textSecondary">
                      {location.state.data.shipping.address_1}
                    </Typography>
                    <Typography color="textSecondary">
                      {location.state.data.shipping.address_2}
                    </Typography>
                    <Typography color="textSecondary">
                      {location.state.data.shipping.city}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </Box>
      </Desktop>
      <Tablet>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "2rem" }}>
          <span className="heading-name">
            <p className={classes.active}>Orders</p>

            {/* {tab.chat && <span>Provider Messages</span>} */}
          </span>
        </div>
        <div></div>
        <Box className="profile-main-box">
          {location.state.data && (
            <>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={16}>
                  <Grid item xs>
                    <Typography gutterBottom variant="subtitle1">
                      Order: {location.state.data.id}
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                      Date:{" "}
                      {moment(location.state.data.date_created).format(
                        "MMM Do YY"
                      )}
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Status:{location.state.data.status}
                  </Typography>
                </Grid>
              </Grid>
              {location.state.data.line_items.map((row) => (
                <div className={classes.root}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={16}>
                      <Grid item>
                        <ButtonBase className={classes.image}>
                          <img
                            className={classes.img}
                            alt="product"
                            src={
                              row.meta_data[1]
                                ? row.meta_data[1].value
                                : "https://app.mahimo.co/media/Copy_of_Mahimo_Products_Front_190_x_280_px.png"
                            }
                          />
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={16}>
                          <Grid item xs>
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                            ></Typography>
                            <Typography gutterBottom>{row.name}</Typography>
                            <Typography color="textSecondary">
                              quantity: {row.quantity}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1">
                            $ {row.total}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
              <br />
              <Paper className={classes.paper}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={16}>
                    <Grid item xs>
                      <Typography color="textSecondary">
                        Shipping: Free shipping
                      </Typography>
                      <Typography color="textSecondary">
                        Tax: ${location.state.data.total_tax}
                      </Typography>
                      <Typography color="textSecondary">
                        Payment method: {location.state.data.payment_method}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      Total: $ {location.state.data.total}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              <Paper className={classes.paper}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={16}>
                    <Grid item xs>
                      <Typography variant="subtitle1">
                        Customer details{" "}
                      </Typography>
                      <Typography color="textSecondary">
                        Email: {location.state.data.billing.email}{" "}
                        {location.state.data.billing.last_name}
                      </Typography>
                      <Typography color="textSecondary">
                        Phone: {location.state.data.billing.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Paper className={classes.paper}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={16}>
                    <Grid item xs>
                      <Typography variant="subtitle1">
                        Billing address{" "}
                      </Typography>
                      <Typography color="textSecondary">
                        {location.state.data.billing.first_name}{" "}
                        {location.state.data.billing.last_name}
                      </Typography>
                      <Typography color="textSecondary">
                        {location.state.data.billing.address_1}
                      </Typography>
                      <Typography color="textSecondary">
                        {location.state.data.billing.address_2}
                      </Typography>
                      <Typography color="textSecondary">
                        {location.state.data.billing.city}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      Shipping address{" "}
                    </Typography>
                    <Typography color="textSecondary">
                      {location.state.data.shipping.first_name}
                      {location.state.data.shipping.last_name}
                    </Typography>
                    <Typography color="textSecondary">
                      {location.state.data.shipping.address_1}
                    </Typography>
                    <Typography color="textSecondary">
                      {location.state.data.shipping.address_2}
                    </Typography>
                    <Typography color="textSecondary">
                      {location.state.data.shipping.city}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </Box>
      </Tablet>
    </>
  );
}

export default ViewOrder;
