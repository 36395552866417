import React from "react";
import { Typography, Box } from "@material-ui/core";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "../../pages/profile/profile.css";

function Boxes({ mainResult }) {
  return (
    <>
      <Box className="dashboard-box-grid ">
        {/* first box */}
        {mainResult && (
          <>
            <Box className="dashboard-box-main-result">
              <Box
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                display={"flex"}
                width="100%"
              >
                <div
                  style={{
                    width: 60,
                    height: 70,
                    padding: "1px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgressbar
                    value={Math.round(
                      parseInt(mainResult[0] ? mainResult[0].percentage : 0)
                    )}
                    strokeWidth={12}
                    text={mainResult[0] ? mainResult[0].percentage : "0%"}
                    styles={buildStyles({
                      textSize: "22px",
                      textColor: "#11263C",
                      pathColor: "#55A55E",
                    })}
                  ></CircularProgressbar>
                </div>

                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  display={"flex"}
                  style={{
                    marginLeft: "9px",
                  }}
                >
                  <Typography className="dashboard-take-quiz-1">
                    {mainResult[0] ? mainResult[0].name : "Take a Quiz!"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* second box */}
            <Box className="dashboard-box-2">
              <Box
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                display={"flex"}
                width="100%"
              >
                <div
                  style={{
                    width: 60,
                    height: 70,
                    padding: "1px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgressbar
                    value={Math.round(
                      parseInt(mainResult[1] ? mainResult[1].percentage : 0)
                    )}
                    strokeWidth={12}
                    text={mainResult[1] ? mainResult[1].percentage : "0%"}
                    styles={buildStyles({
                      textSize: "22px",
                      textColor: "#11263C",
                      pathColor: "#5E5498",
                    })}
                  ></CircularProgressbar>
                </div>

                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  display={"flex"}
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <Typography className="dashboard-take-quiz-2">
                    {mainResult[1] ? mainResult[1].name : "Take a Quiz!"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* third box */}
            <Box className="dashboard-box-3 ">
              <Box
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                display={"flex"}
                width="100%"
              >
                <div
                  style={{
                    width: 60,
                    height: 70,
                    padding: "1px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgressbar
                    value={Math.round(
                      parseInt(mainResult[2] ? mainResult[2].percentage : 0)
                    )}
                    strokeWidth={12}
                    text={mainResult[2] ? mainResult[2].percentage : "0%"}
                    styles={buildStyles({
                      textSize: "22px",

                      textColor: "#11263C",
                      pathColor: "#9B715D",
                    })}
                  ></CircularProgressbar>
                </div>

                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  display={"flex"}
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <Typography className="dashboard-take-quiz-3">
                    {mainResult[2] ? mainResult[2].name : "Take a Quiz!"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default Boxes;
