import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  makeStyles,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Tabs from "../../components/shared/Tabs";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
  root: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(rgba(50, 50, 50, 0.8), rgba(50, 50, 50, 0.8)), ",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justify: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: "80px",
  },
  container: {
    background: "transparent",
  },
  table: {
    backgroundColor: "white",
  },
  head: {
    backgroundColor: " #e0e0e0",
    color: theme.palette.primary.light,
  },
  border: {
    borderColor: "#ccc",
    borderRadius: "5px" /* (height/2) + border-width */,
    borderStyle: "solid",
    borderWidth: " 2px",
  },
  buttonRoot: {
    width: 190,
    fontSize: "16px",
    textAlign: "left",
    float: "left",
    marginTop: "20px",
    position: "relative",
  },
  button: {
    width: 10,
  },
  customTable: {
    "& .MuiTableCell-root": {
      padding: "0px", // <-- arbitrary value
    },
  },
  box: {
    background: "#fff",
    borderRadius: "12px 12px 12px 12px",
    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.11)",
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    justifyItems: "center",
    padding: "40px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "center",
    alignItems: "center",
    justify: "center",
  },
}));

export default function Quiz({ quizQuestions }) {
  const classes = useStyles();
  return (
    <GridContainer
      style={{
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: "center",
        alignItems: "center",
        justify: "center",
        justifyContent: "center",
        marginBottom: "1rem",
        borderColor: "#E0E0E0",
        borderRadius: "5px" /* (height/2) + border-width */,
        borderStyle: "solid",
        borderWidth: "2px",
      }}
    >
      <GridItem xs={12} sm={12} md={12}>
        <Tabs style={{ backgroundColor: "#fff" }}>
          {quizQuestions &&
            quizQuestions.map((row, idx) => (
              <div label={row.section_name}>
                <Table
                  style={{ marginTop: "40px", marginBottom: "40px" }}
                  className={classes.table}
                  aria-label="simple table"
                >
                  <TableHead className={classes.head}>
                    <TableRow className={classes.border}>
                      <TableCell className={classes.border} align="center">
                        #
                      </TableCell>
                      <TableCell className={classes.border} align="center">
                        Question
                      </TableCell>
                      <TableCell className={classes.border} align="center">
                        Answer
                      </TableCell>
                      <TableCell className={classes.border} align="center">
                        Suggestion Properties
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.questions &&
                      row.questions.map((p, index) => (
                        <TableRow className={classes.border}>
                          <TableCell className={classes.border} align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.border} align="center">
                            {p.question}
                          </TableCell>
                          {p.answer ? (
                            <TableCell align="center">{p.answer}</TableCell>
                          ) : (
                            <TableCell align="center">
                              {" "}
                              {p.choices[0]}
                            </TableCell>
                          )}
                          <TableCell className={classes.border} align="center">
                            {p.ingredients[0]}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            ))}
        </Tabs>
      </GridItem>
    </GridContainer>
  );
}
