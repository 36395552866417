import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import MainButton from '../components/UIElements/Button';
import checkIcon from '../assets/img/check-white.svg';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '10px 0',
    color: '#666B78',
  },
  shipingAddress: {
    margin: '10px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    paddingBottom: 15,
  },
  boxHead: {
    color: '#000000',
    fontSize: 16,
    lineHeight: '24px',
    paddingBottom: 10,
  },
  checkBox: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    color: 'rgba(0,0,0,0.5)',
    lineHeight: '24px',
  },
  buttonRoot: {
    position: 'relative',
    justifyContent: 'flex-start',
  },
  buttonArrow: {
    position: 'absolute',
    right: 10,
  },
  infoTitle: {
    color: '#040415',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: '20px',
    padding: '10px 0',
  },
}));

function Checkout() {
  const classes = useStyles();

  return (
    <Layout>
      <div className='heading'>
        <span className='heading-name'>Confirmation</span>
        <div className='heading-bg'></div>
        <h2 className='heading-title'>Checkout</h2>
      </div>
      <Grid container justify='center' alignItems='center'>
        <Grid item md={6}>
          <form>
            <div className={classes.shipingAddress}>
              <div className={classes.boxHead}> Shipping Address</div>
              <FormControl
                variant='outlined'
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id='demo-simple-select-outlined-label'>
                  Select Shipping Address
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  label='Select Shipping Address'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.boxHead}>Credit Card</div>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                id='outlined-basic'
                label='Cardholder Name'
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                id='outlined-basic'
                label='Card Number'
                variant='outlined'
              />
            </FormControl>
            <Grid item container spacing={5}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id='outlined-basic'
                    label='CVV'
                    variant='outlined'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    id='outlined-basic'
                    label='Exp Date'
                    variant='outlined'
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              item
              container
              justify='space-between'
              alignItems='center'
              style={{ margin: '2rem 0' }}
            >
              <Grid item>Billing address is same as shipping address</Grid>
              <Grid item>
                <Checkbox color='primary' />
              </Grid>
            </Grid>

            <Grid item container className={classes.checkBox}>
              <div className={classes.infoTitle}>Summary</div>
              <Grid item container justify='space-between'>
                <Grid item>3 ITEMS</Grid>
                <Grid item>$119.00</Grid>
              </Grid>
              <Grid item container justify='space-between'>
                <Grid item>DELIVERY</Grid>
                <Grid item>FREE</Grid>
              </Grid>
              <Grid item container justify='space-between'>
                <Grid item>TAX</Grid>
                <Grid item>0</Grid>
              </Grid>
              <Grid
                item
                container
                justify='space-between'
                className={classes.infoTitle}
              >
                <Grid item>TOTAL</Grid>
                <Grid item>$119.00</Grid>
              </Grid>
              <FormControl className={classes.formControl} fullWidth>
                <Link to='confirm'>
                  <MainButton
                    background
                    variant='contained'
                    className={classes.buttonRoot}
                    type='submit'
                    fullWidth
                  >
                    Confirm Order
                    <img
                      src={checkIcon}
                      alt=''
                      className={classes.buttonArrow}
                    />
                  </MainButton>
                </Link>
              </FormControl>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Checkout;
