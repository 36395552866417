import React from 'react';
import Layout from './Layout';
import { Hidden } from '@material-ui/core';
import { Grid } from '@material-ui/core';
//import SkinImg from '../../assets/img/skin.svg';
import AuthImage from '../../assets/img/auth-image.svg';
import { makeStyles } from '@material-ui/core/styles';

const QuizLayout = (props) => {
  const useStyles = makeStyles((theme) => ({
    seperator: {
      textAlign: 'center',
      padding: '1.5rem 0',
      color: '#C2C6CC',
      fontSize: '18px',
      lineHeight: '28px',
      width: '90%',
    },
    authImage: {
      width: '90%',
      height: '100%',
    },
  }));
  const classes = useStyles();
  return (
    <Layout>
      <Grid container>
        <Hidden mdDown>
          <Grid item lg={6} md={12} xs={12}>
            <img src={AuthImage} alt='Skin' className={classes.authImage} />
          </Grid>
        </Hidden>
        <Grid item lg={6} md={12} xs={12}>
          <div className={classes.seperator}></div>
          {props.children}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default QuizLayout;
