import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import QuizLayout from "../shared/QuizLayout";
import {
  TextField,
  makeStyles,
  Radio,
  RadioGroup,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import MainButton from "../UIElements/Button";
import arrowLeft from "../../assets/img/arrow-left.svg";
import arrowRight from "../../assets/img/arrow-right.svg";
import { useHistory, useLocation } from "react-router-dom";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "../../pages/Quiz/RadialSeparators";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

let PageSize = 1;

const useStyles = makeStyles((theme) => ({
  buttonBox: {
    marginTop: "2rem",
    display: "flex",
  },
}));

const QuestionsWithoutAnswers = ({ token }) => {
  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({ question: null, answer: null });
  const classes = useStyles();
  const inputRef = useRef("");
  const history = useHistory();
  const { state } = useLocation();
  //caculate total questions
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [pageCounter, setPageCounter] = useState(1);
  //check if location availble
  const [location, setLocation] = useState(false);
  useEffect(() => {
    const sendRequest = async () => {
      try {
        const res = await axios.get(
          "https://app.mahimo.co/user/profile/questions/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setQuestions(res.data.results);
      } catch (err) {
        console.log(err);
      }
    };

    sendRequest();
  }, [token]);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const paginatedQuestions = questions.slice(firstPageIndex, lastPageIndex);

  const handlePrev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };
  const handleNext = async () => {
    if (inputRef.current && !inputRef.current.value) {
      inputRef.current.focus();
      return;
    }
    try {
      await axios.post(
        `https://app.mahimo.co/user/profile/questions/answers/`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (currentPage === questions.length) {
        if (state.form) {
          if (location) {
            history.push("/profile/location/", {
              state,
            });
          } else {
            history.push(`/quiz/result/${state.form}`);
          }
        } else {
          history.push("/");
        }
      }
      setCurrentPage(currentPage + 1);
    } catch (err) {
      console.log(err);
    }
  };

  //check the location
  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          "https://app.mahimo.co/user/get_user_location/",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        if (!response.ok) {
          setLocation(true);
          throw new Error("ERORRR");
        } else {
          setLocation(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    sendRequest();
  }, [token]);

  // const handlePrev = () => {
  //   if (currentPage === 1) return
  //   setCurrentPage(currentPage - 1)
  // }

  const inputChangeHandler = (e, id) => {
    const value = e.target.value;
    setFormData({
      question: id,
      answer: value,
      selected_choice: [],
    });
  };

  const selectChangeHandler = (e, id) => {
    const value = e.target.value;
    setFormData({
      question: id,
      selected_choice: [+value],
      answer: "",
    });
  };

  return (
    <>
      {questions.length > 0 ? (
        <Typography className="question-number">
          QUESTION {currentPage} OF {questions.length} | USER QUESTION
        </Typography>
      ) : (
        <Typography className="question-number">
          FIRST QUESTION | USER QUESTION
        </Typography>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "start",

          flexDirection: "column",
        }}
      >
        {paginatedQuestions &&
          paginatedQuestions.map((q, idx) => (
            <div key={q.id}>
              <Typography className="question-objects">{q.question}</Typography>

              {q.question_type === 0 && (
                <TextField
                  type="text"
                  label="Your answer"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => inputChangeHandler(e, q.id)}
                  inputRef={inputRef}
                />
              )}

              {q.question_type === 1 && (
                <>
                  <RadioGroup
                    name={`radio_${idx}`}
                    onChange={(e) => selectChangeHandler(e, q.id)}
                  >
                    {q.choices.map((c) => (
                      <FormControl
                        component="fieldset"
                        className="form-control"
                        key={c.id}
                      >
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                style={{
                                  marginRight: "15px",
                                }}
                              />
                            }
                            value={c.id.toString()}
                            label={c.choice}
                            labelPlacement="start"
                            name={`radio_${idx}`}
                            className="form-control-label"
                          />
                        </FormGroup>
                      </FormControl>
                    ))}
                  </RadioGroup>
                </>
              )}
            </div>
          ))}
        <div className={classes.buttonBox}>
          <div
            style={{
              width: 55,
              height: 55,
              display: "flex",
              justifyContent: "center",
              marginRight: 20,
            }}
          >
            <CircularProgressbarWithChildren
              value={
                questions.length === 0
                  ? currentPage
                  : (currentPage / questions.length) * 100
              }
              strokeWidth={6}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: "#732F88",
              })}
            >
              <RadialSeparators
                count={questions.length}
                style={{
                  background: "#fff",
                  width: "2px",
                  // This needs to be equal to props.strokeWidth
                  height: `${10}%`,
                }}
              />
              <Box
                style={{
                  height: 40,
                  width: 40,

                  borderRadius: 360,
                  background: "#732F88",
                  justifyContent: "center",
                  alignSelf: "center",
                  display: "flex",
                }}
              >
                <IconButton variant="text" onClick={handleNext}>
                  <img
                    src={arrowRight}
                    style={{
                      position: "relative",
                      alignSelf: "center",
                    }}
                    alt=""
                  />
                </IconButton>
              </Box>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionsWithoutAnswers;
