import React, { useState, useContext } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import dashboarditem from "../../assets/img/dashboardsvg.svg";
import productitem from "../../assets/img/water.svg";
import calender from "../../assets/img/calendar.svg";
import mahimoSvg from "../../assets/img/Logo.svg";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { AuthContext } from "../context/auth";
import "./dashboard.css";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#838383",
  },
  itemIcon: {
    minWidth: 35,
  },
  divider: {
    background: "#000",
  },
  hover: {
    "&:hover": {
      background: "#838383",
    },
  },
}));
const NavList = ({ history, data }) => {
  const [sideBar, setsideBar] = useState(false);
  const [currentDate, setcurrentDate] = useState(
    moment().format("MMMM Do YYYY")
  );
  const { jwtToken, logout } = useContext(AuthContext);
  const handleSidebar = () => {
    setsideBar(!sideBar);
  };
  const getPages = () => {
    var list = [];

    list.push({
      label: "Dashboard",
      path: "/profile",
      icon: DashboardIcon,
    });
    list.push(
      // {
      //   comp: 'divider',
      // },
      {
        label: "List of quizzes",
        path: "/profile/quiz",
        icon: ListAltIcon,
      },
      {
        label: "List of orders",
        path: "/profile/orders",
        icon: ShoppingCartIcon,
      },
      {
        label: "Personal questions",
        path: "/profile/personal",
        icon: AssignmentIndIcon,
      },
      {
        label: "Settings",
        path: "/profile/settings",
        icon: SettingsIcon,
      },
      {
        label: "Log out",
        onClick: () => {
          logout();
          history.push("/login");
        },
        icon: ExitToAppIcon,
      }
    );
    return list;
  };
  const classes = useStyles();
  const pages = getPages();

  return (
    <>
      {/* left bar */}
      <div className="left-bar">
        <nav>
          <ul className="mainNav">
            <li className="li-dashboard">
              <RouterLink to="/">
                <img src={mahimoSvg} alt="defualt" style={{ zIndex: 1 }} />
              </RouterLink>
            </li>
            <li className="li-dashboard-menu mt5">
              <Typography className="text-menu">Menu</Typography>
            </li>
            {pages.map(({ label, path, comp, icon: Icon, onClick }, index) => {
              if (path) {
                return (
                  <ListItem
                    component={RouterLink}
                    to={path}
                    button
                    key={index}
                    className="list-group"
                    style={{
                      background:
                        history.location.pathname === path
                          ? "#732F88"
                          : "#838383",
                    }}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      <Icon
                        className={classes.icon}
                        style={{
                          color:
                            history.location.pathname === path
                              ? "#732F88"
                              : "#838383",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        align: "left",
                        variant: "subtitle2",
                      }}
                      style={{
                        background:
                          history.location.pathname === path
                            ? "#732F88"
                            : "#838383",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                      className={`list-group, 
                        ${
                          history.location.pathname === path
                            ? classes.selected
                            : ""
                        }`}
                    />
                  </ListItem>
                );
              } else if (comp) {
                return <Divider key={index} className={classes.divider} />;
              } else {
                return (
                  <ListItem
                    onClick={onClick}
                    button
                    key={index}
                    className="list-group"
                    style={{
                      background:
                        history.location.pathname === path
                          ? "#732F88"
                          : "#838383",
                    }}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      <Icon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        align: "left",
                        variant: "subtitle2",
                      }}
                      className="list-group"
                      style={{
                        background: "#838383",
                      }}
                    />
                  </ListItem>
                );
              }
            })}
            <li className="li-mahimo">
              <Typography
                className="list-group"
                style={{
                  fontSize: "14px",
                  position: "fixed",
                  zIndex: "10",
                  background: "#838383",
                }}
              >
                © 2022 Mahimo, Inc.
              </Typography>
            </li>
          </ul>
        </nav>
      </div>

      {/* right side */}
      <div className="right-bar">
        <nav className="right-bar-nav">
          <ul
            className="mainNav2"
            style={{
              justifyContent: "center",
              justifyItems: "flex-start",
              flexDirection: " row",
            }}
          >
            <li className="li-dashboard">
              <img src={calender} alt="calender" style={{ zIndex: 1 }} />
              <Typography className="text-18-linear">{currentDate}</Typography>
            </li>
            <li
              className="li-dashboard"
              style={{
                marginTop: "3rem",
              }}
            >
              <div
                alt="defualt"
                className="product-item"
                style={{
                  background: `url(${productitem})`,
                }}
              >
                <a
                  href={
                    jwtToken
                      ? "https://mahimo.co/product/skincare-set/?mo_jwt_token=" +
                        jwtToken
                      : "https://mahimo.co/product/skincare-set/"
                  }
                >
                  <img
                    src={
                      "https://app.mahimo.co/media/Copy_of_Mahimo_Products_Front_190_x_280_px.png"
                    }
                    alt="defualt"
                    width="200"
                    height="250"
                    className="product-image"
                  />
                </a>
              </div>
            </li>
            <li className="li-dashboard mt1">
              <div
                alt="defualt"
                style={{
                  background: `url(${dashboarditem})`,
                }}
                className="dashboard-item"
              >
                <Typography className="dashboard-item-text">
                  {data.forms[0]
                    ? data.forms[0].disorders[0]
                      ? data.forms[0].disorders[0].name
                      : "Acne"
                    : "Acne"}{" "}
                  goal
                </Typography>
                <Typography
                  className="dashboard-item-text"
                  style={{
                    fontWeight: "400",
                    fontSize: "20px",
                  }}
                >
                  {data.forms[0]
                    ? data.forms[0].disorders[0]
                      ? data.forms[0].disorders[0].percentage
                      : "0"
                    : "0"}
                  % | Clear
                </Typography>
              </div>
            </li>
            <li className="li-dashboard mt1">
              <div className="last-quiz-div ">
                <Typography className="last-quiz-text ">
                  Last quizzes
                </Typography>

                <ul className="last-quiz-div-ul">
                  {data &&
                    data.forms.slice(0, 3).map((item) => (
                      <RouterLink to={`/quiz/result/${item.id}`}>
                        <li
                          className="last-quiz-div-li"
                          onClick={() => {
                            //   openDialog(row);
                            history.push(`/quiz/result/${item.id}`);
                          }}
                        >
                          <Typography className="text-18-linear">
                            {moment(item.created_at).format("MMM Do YY")}
                          </Typography>
                        </li>
                      </RouterLink>
                    ))}
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
export default withRouter(NavList);
