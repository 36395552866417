import React from "react";

import { Typography, Grid } from "@material-ui/core";
import GridItem from "../Grid/GridItem.js";
import Frame from "../../assets/img/frame.svg";
function Index() {
  return (
    <GridItem
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{
        justifyContent: "center",
        display: "flex",
      }}
      direction="column"
    >
      <Typography className="camera-mobile-text-main">
        Take picture or go to the next Section
      </Typography>
      <div style={{ justifyContent: "center" }}>
        <Grid
          container
          spacing={1}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          direction="column"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography className="desktop-text ">
            Before taking a photo, please note these four items:
          </Typography>
          <img src={Frame} alt="" />
        </Grid>
      </div>
    </GridItem>
  );
}

export default Index;
