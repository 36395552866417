import React from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import confirmImg from '../assets/img/confirm.svg';

const useStyles = makeStyles((theme) => ({
  textHead: {
    margin: '2rem 0',
    fontSize: 15,
    color: '#040415',
    lineHeight: '20px',
    fontWeight: 'bold',
  },
  textBold: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
  textSub: {
    fontSize: 13,
    color: '#666B78',
    fontWeight: 500,
    lineHeight: '24px',
  },
  buttonRoot: {
    width: 340,
    height: 48,
    marginTop: 50,
  },
}));

function Confirm() {
  const classes = useStyles();

  return (
    <Layout>
      <div className='heading'>
        <span className='heading-name'>Order Confirmed</span>
        <div className='heading-bg'></div>
        <h2 className='heading-title'>Checkout</h2>
      </div>
      <Grid container justify='center' alignItems='center'>
        <Grid
          item
          container
          md={6}
          justify='center'
          alignItems='center'
          direction='column'
        >
          <div>
            <img src={confirmImg} alt='' />
          </div>

          <div className={classes.textHead}>What's next? </div>
          <div>
            <div className={classes.textBold}>
              Your doctor will review your medical history.
            </div>
            <div className={classes.textSub}>
              Your doctor will review your online visit answers and ask for any
              other information if necessary to determine the appropriate
              treatment for you.
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <div className={classes.textBold}>
              Your treatment will arrive in 3-5 days!
            </div>
            <div className={classes.textSub}>
              Our pharmacy will process your order shortly after your doctor
              prescribes your medicati
            </div>
          </div>
          <Link to='/profile'>
            <Button
              color='primary'
              variant='contained'
              size='large'
              className={classes.buttonRoot}
            >
              Back to Profile Page
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Confirm;
