import React, { useState, useEffect, useContext } from "react";
import { Button, makeStyles, Backdrop, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import { AuthContext } from "../../components/context/auth";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import { useMediaQuery } from "react-responsive";
import "./profile.css";
const useStyles = makeStyles((theme) => ({
  active: {
    color: "#742B88",
    borderRadius: 0,
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  profileBox: {
    padding: "5px 0",
  },
  profileHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  boxTitle: {
    color: "#000618",
    fontSize: 20,
    fontWeight: 600,
  },

  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
  box: {
    background: "#fff",

    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.11)",
    width: "100%",
    height: "100%",
  },
  infoBox: {
    lineHeight: "30px",
    padding: "1rem 1rem",

    display: "grid",
    gridTemplateColumns: " 1fr",

    rowGap: "12px",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#000618",
    border: "1px solid #ccc",
  },
  answer: {
    fontWeight: "bold",
    fontFamily: "Playfair Display",

    fontSize: "18px",
  },
}));

function Profile() {
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [userData, setUserData] = useState();

  // load profile questions
  const loadQuestions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://app.mahimo.co/user/profile/questions/answers",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error("ERORRR");
      }

      if (responseData.count === 0) {
        setLoading(false);
        history.push("/profile/questions/", {});
        return;
      } else {
        setUserData(responseData.results);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!userData) loadQuestions();
  }, [userData]);

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1440 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1439 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "4rem" }}>
          <span className="heading-name">
            <p className={classes.active}> Personal Questions</p>

            {/* {tab.chat && <span>Provider Messages</span>} */}
          </span>
          <div className="heading-bg"></div>
          {/* <h2 className='heading-title'>Profile</h2> */}
        </div>
        <div></div>
        <Box className="profile-main-box">
          <div className={classes.profileBox}>
            <div className={classes.profileHead}>
              <span className={classes.boxTitle}>Details</span>
              <Button
                color="primary"
                style={{ fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  history.push("/profile/questions/", {});
                }}
              >
                Edit
              </Button>
            </div>
            <div className={`${classes.box}`}>
              {userData &&
                userData.map((d) => (
                  <div key={d.id} className={classes.infoBox}>
                    <div>{d.question_obj.question}</div>
                    {d.answer ? (
                      <div className={classes.answer}>{d.answer}</div>
                    ) : (
                      <>
                        {d.choices[0] ? (
                          <div className={classes.answer}>
                            {d.choices[0].choice}
                          </div>
                        ) : (
                          <div className={classes.answer}></div>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </Box>
      </Desktop>
      <Tablet>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "2rem" }}>
          <span className="heading-name">
            <p className={classes.active}>Personal Questions</p>

            {/* {tab.chat && <span>Provider Messages</span>} */}
          </span>
          <div className="heading-bg"></div>
          {/* <h2 className='heading-title'>Profile</h2> */}
        </div>
        <div></div>
        <Box className="profile-main-box">
          <div className={classes.profileBox}>
            <div className={classes.profileHead}>
              <span className={classes.boxTitle}>Details</span>
              <Button
                color="primary"
                style={{ fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  history.push("/profile/questions/", {});
                }}
              >
                Edit
              </Button>
            </div>
            <div>
              {userData &&
                userData.map((d) => (
                  <div key={d.id} className={classes.infoBox}>
                    <div>{d.question_obj.question}</div>
                    {d.answer ? (
                      <div className={classes.answer}>{d.answer}</div>
                    ) : (
                      <>
                        {d.choices[0] ? (
                          <div className={classes.answer}>
                            {d.choices[0].choice}
                          </div>
                        ) : (
                          <div className={classes.answer}></div>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </Box>
      </Tablet>
    </>
  );
}

export default Profile;
