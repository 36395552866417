import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  makeStyles,
  Grid,
  Button,
  Backdrop,
  Box,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TextField,
} from "@material-ui/core";
import MainButton from "../../components/UIElements/Button";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import SideBar from "../../components/NavBar/SideBar";
import Tabs from "../../components/shared/Tabs";
import { useParams, useHistory } from "react-router-dom";
import useWindowSize from "@rehooks/window-size";
import { AuthContext } from "../../components/context/auth";
import axios from "axios";
// image mapper
import URL from "../../assets/img/skin.svg";
import ImageMapper from "react-image-mapper";
//product list
import ProductList from "./ProductList";
import { set } from "nprogress";
import QuizQuestion from "./quizQuestions";
import DoctorNotes from "./doctorNotes";
import SuggestionProductsAI from "./suggestionProductsAI";
import SuggestionProductsDoctor from "./suggestionProductsDoctor";
// function Alert(props) {
//   return <MuiAlert elevation={6} variant='filled' {...props} />;
// }
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import "./adminStyle.css";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
  root: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(rgba(50, 50, 50, 0.8), rgba(50, 50, 50, 0.8)), ",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justify: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: "80px",
  },
  container: {
    background: "transparent",
  },
  table: {
    backgroundColor: "white",
  },
  head: {
    backgroundColor: " #e0e0e0",
    color: theme.palette.primary.light,
  },
  border: {
    borderColor: "#ccc",
    borderRadius: "5px" /* (height/2) + border-width */,
    borderStyle: "solid",
    borderWidth: " 2px",
  },
  buttonRoot: {
    width: 190,
    fontSize: "16px",
    textAlign: "left",
    float: "left",
    marginTop: "20px",
    position: "relative",
  },
  button: {
    width: 10,
  },
  customTable: {
    "& .MuiTableCell-root": {
      padding: "0px", // <-- arbitrary value
    },
  },
  box: {
    background: "#fff",
    borderRadius: "12px 12px 12px 12px",
    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.11)",
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    justifyItems: "center",
    padding: "40px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "center",
    alignItems: "center",
    justify: "center",
  },
}));

function Result() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState();
  const [doctorSuggestedProducts, setDcotorSuggestedProducts] = useState();
  const [quizQuestions, setQuizQuestions] = useState();

  const [mainResult, setMainResult] = useState();
  const [ImageSelfi, setImageSelfi] = useState(URL);
  const [DoctorNote, setDoctorNote] = useState("No comment");

  const [userProfileQuestionsAnswers, setUserProfileQuestionsAnswers] =
    useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const classes = useStyles();
  const { form } = useParams();
  const { token } = useContext(AuthContext);
  const isMountedRef = useRef(null);
  const history = useHistory();

  //image mapper
  const [selectedAreaResult, setSelectedAreaResult] = useState(null);
  const [info, setInfo] = useState(false);

  //List product
  const [productList, setProductList] = useState(null);
  const [open, setOpen] = useState(false);
  //AI
  const [AIPanel, setAIPanel] = useState(false);
  const [ImageMap, setImageMap] = useState(null);
  //const [AIMassage, setAIMassage] = useState(null);
  const [AIResultName, setAiResultName] = useState();
  const [AIResultLevel, setAiResultLevel] = useState();
  const [AIunderEye, setAiUnderEye] = useState();
  const [Map, setMap] = useState();

  function clickArea(area) {
    setAiResultName(area.name);
    setAIPanel(true);
    setSelectedAreaResult(area.rank);
    setInfo(true);
  }

  function handleInfoExitClick() {
    setInfo(false);
  }

  useEffect(() => {
    isMountedRef.current = true; // set true when mounted
    return () => (isMountedRef.current = false); // clear when unmounted
  }, []);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setLoading(true);

        const respons = await axios.get(
          `https://app.mahimo.co/quiz/admin/user_result/${form}/`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        //personal questions
        if (respons.data["User Profile Questions Answers"].length > 0) {
          setUserProfileQuestionsAnswers(
            respons.data["User Profile Questions Answers"]
          );
        }
        //quiz questions
        if (respons.data["Quiz Questions Answers"].length > 0) {
          setQuizQuestions(respons.data["Quiz Questions Answers"]);
        }
        //Suggested By Doctor Products
        if (respons.data["Suggested By Doctor Products"].length > 0) {
          setDcotorSuggestedProducts(
            respons.data["Suggested By Doctor Products"][0].product
          );
          // console.log(respons.data['Suggested By Doctor Products'][0].product);
        }
        //set Products
        if (respons.data.Products.length > 0) {
          setProducts(respons.data.Products[0].product);
        }
        //set mainResult
        if (respons.data.Disorders.length > 0) {
          setMainResult(respons.data.Disorders);
          setActiveItem(respons.data.Disorders[0].name);
        }

        //set Properties

        if (respons.data["User Image"] !== null) {
          setImageSelfi(`https://app.mahimo.co${respons.data["User Image"]}`);
        }

        //Docetor Note
        if (respons.data["Doctor Note"].note !== null) {
          setDoctorNote(respons.data["Doctor Note"].note);
        }
        if (ImageMap === null) {
          AiRequest();
          setLoading(false);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    if (isMountedRef.current) {
      // <-- check if still mounted
      sendRequest();
    }
  }, [form, token, ImageMap]);

  //set description
  useEffect(() => {
    if (activeItem !== null) {
      var data = mainResult.filter((item) => item.name === activeItem);
    }
  }, [activeItem, mainResult]);

  //set description
  useEffect(() => {
    if (ImageMap !== null) {
      setMap(ImageMap);
    }
  }, [ImageMap, Map]);

  //list Product
  const ProductListApi = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://app.mahimo.co/shop/admin/product_list/`,
        {
          method: "Get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        setLoading(false);
        throw new Error("ERORRR");
      } else {
        setProductList(responseData.results);

        setLoading(false);
        setOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  //Ai request
  //load quiz list
  const AiRequest = async () => {
    try {
      const response = await fetch(
        `https://app.mahimo.co/quiz/ai/process/v2/`,
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ form_id: form }),
        }
      );

      if (!response.ok) {
        const responseData = await response.json();

        //  setAIMassage(responseData.message);
        throw new Error("ERORRR");
      } else {
        if (response.message !== undefined) {
          throw new Error("ERORRR");
        } else {
          if (response) {
            const responseData = await response.json();

            var result = [];
            for (var i in responseData) {
              result.push({
                id: i,
                class: i,
                name: responseData[i].disorder_name,
                rank: responseData[i].disorder_description,
                percentage: responseData[i].rank
                  ? responseData[i].rank
                  : isNaN(responseData[i].disorder_description[0])
                  ? 0
                  : responseData[i].disorder_description[0],
                shape: "circle",
                coords: [
                  responseData[i].points[1],
                  responseData[i].points[0],
                  7,
                ],

                preFillColor: "#E7DFEE",
              });
            }

            setAiResultName(result[0].name);
            const map = {
              id: "map",
              name: "my-map",

              areas: result,
            };
            setImageMap(map);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log("render");
  return (
    <>
      <SideBar />
      <div className={classes.root}>
        {/* Result of user */}
        {userProfileQuestionsAnswers ? (
          <Typography className="result-title">
            Skin quiz Result {userProfileQuestionsAnswers[0].user_obj.email}
          </Typography>
        ) : (
          <Typography className="result-title">Skin quiz Result</Typography>
        )}
        {/* profile section */}
        <GridContainer
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            alignItems: "start",
            justify: "center",
            justifyContent: "center",
            marginBottom: "5rem",
          }}
          spacing={5}
          container
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography className="text-30-linear">AI Image</Typography>
            <div className="div-center-result">
              <main className="App">
                <ImageMapper
                  src={ImageSelfi}
                  map={Map}
                  height={512}
                  width={512}
                  onClick={(area) => clickArea(area)}
                  // onMouseEnter={(area) => enterArea(area)}
                  // onMouseLeave={() => leaveArea()}
                  fillColor="rgba(0,0,0,0)"
                  strokeColor="rgba(255, 255, 255, 1)"
                  lineWidth={4}
                  style={{
                    PointerEvent: "none",
                  }}
                />
              </main>
            </div>
          </Grid>
          {userProfileQuestionsAnswers ? (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ExpansionPanel elevation={1}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="text-30-linear">
                    Profile info
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ marginTop: "-5px" }}>
                  <TableContainer className={classes.container}>
                    <Table className={classes.table}>
                      <TableBody>
                        {userProfileQuestionsAnswers &&
                          userProfileQuestionsAnswers.map((d, index) => (
                            <TableRow key={d.id}>
                              <TableCell align="center">#{index + 1}</TableCell>
                              <TableCell align="center">
                                {d.question_obj.question}
                              </TableCell>
                              {d.answer ? (
                                <TableCell align="center">{d.answer}</TableCell>
                              ) : (
                                <TableCell align="center">
                                  {" "}
                                  {d.choices[0].choice}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                expanded={AIPanel}
                onChange={() => {
                  setAIPanel(!AIPanel);
                }}
                elevation={1}
                style={{ marginTop: "1rem" }}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="text-30-linear">AI Result</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ marginTop: "-5px" }}>
                  <Box
                    alignItems="start"
                    display="flex"
                    width="100%"
                    minHeight="390px"
                    justifyContent="start"
                    flexDirection="column"
                    paddingTop="15px"
                    style={{
                      border: "1px solid #BDBDBD",
                      boxSizing: "border-box",
                      background: "#e7dfee",
                    }}
                  >
                    {info && (
                      <>
                        <div
                          onClick={() => handleInfoExitClick()}
                          className="close-ai"
                        >
                          X
                        </div>

                        <Typography
                          className="ai-text"
                          style={{
                            marginTop: "0.6rem",
                          }}
                        >
                          {AIResultName && AIResultName} :{" "}
                          {AIResultLevel && AIResultLevel}
                        </Typography>

                        <Typography className="ai-text">Result:</Typography>
                        <Typography
                          className="ai-text"
                          style={{
                            marginTop: "-18px",
                          }}
                        >
                          {selectedAreaResult && selectedAreaResult}
                        </Typography>

                        <Typography className="text-30-linear-normal">
                          Note:
                        </Typography>

                        <Typography className="ai-text">
                          {AIunderEye && AIunderEye}
                        </Typography>
                      </>
                    )}
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          ) : (
            <GridItem item xs={12} sm={12} md={12} lg={6}>
              <Typography className="rofile-info">Profile info</Typography>
              <div className={classes.box}>
                The user dosen't answer the questions
              </div>
            </GridItem>
          )}
        </GridContainer>
        {/* Qusetion result */}
        <Typography className="quiz-rename ">
          Quiz Results and Suggested Properties
        </Typography>
        <QuizQuestion quizQuestions={quizQuestions} />

        {/* Doctor note */}

        <div style={{ marginTop: "5rem" }}></div>
        <DoctorNotes DoctorNote={DoctorNote} />

        {/* Suggestion Products AI */}
        <SuggestionProductsAI products={products} />
        {/* Suggestion Products Doctor */}

        <Grid
          container
          spacing={5}
          style={{
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            justify: "center",
            justifyContent: "center",
            paddingBottom: "10px",
            marginTop: "5rem",
            marginBottom: "5px",
          }}
        >
          <GridItem xs={11} sm={11} md={11}>
            <Typography className="suggested-ai">
              Suggested Products by Doctor
            </Typography>
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <Button
              onClick={() => {
                ProductListApi();
              }}
              style={{
                textAlign: "center",
                fontSize: "24px",

                color: "#76288a",
                float: "center",
                padding: "10px",
              }}
            >
              +
            </Button>
          </GridItem>
        </Grid>
        <SuggestionProductsDoctor
          doctorSuggestedProducts={doctorSuggestedProducts}
        />

        <dive style={{ marginTop: "50px" }}></dive>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
        <ProductList
          openProduct={open}
          list={productList}
          formId={form}
          handleClose={() => {
            setOpen(false);
          }}
        />
      </div>
    </>
  );
}

export default Result;
