import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import axios from "axios";
import {
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  Checkbox,
  FormControl,
  FormGroup,
  makeStyles,
  Backdrop,
  Snackbar,
  useMediaQuery,
  Typography,
  Box,
  IconButton,
  Grid,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import arrowLeft from "../../assets/img/arrow-left.svg";
import arrowRight from "../../assets/img/arrow-right.svg";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../components/context/auth";
import star from "../../assets/img/star_home.svg";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
// Radial separators
import RadialSeparators from "./RadialSeparators";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import SideLayout from "../../components/quiz/SideLayout.js";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  buttonBox: {
    marginTop: "2rem",
    display: "flex",
  },

  input: {
    display: "none",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
  topGradient: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "-2rem",
    marginLeft: "33rem",
    background: "rgba(225, 187, 88, 0.12)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  Ellipse: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "17rem",
    marginLeft: "43rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  mahimoSvg: {
    position: "absolute",
    top: "34rem",
    marginLeft: "39rem",
    zIndex: 2,
  },
  openResume: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",

    marginTop: "3rem",
    width: "80%",
    marginLeft: "20px",
  },
  welcome: {
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "43px",

    background:
      "-webkit-linear-gradient( 103.21deg, #1F1F1F 13.3%, #521465 52.42%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    alignSelf: "left",
    marginBottom: "0.5rem",
  },
  specificName: {
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "53px",
    marginBottom: "10px",
    width: "70%",
    alignSelf: "left",
    // background: 'rgba(225, 187, 88, 0.12)',
  },
  continueDiv: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",
    marginTop: "3rem",
    width: "70%",
    marginLeft: "20px",
  },
  continueText: {
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "43px",

    background:
      "-webkit-linear-gradient( 103.21deg, #1F1F1F 13.3%, #521465 52.42%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    alignSelf: "left",
    marginBottom: "0.5rem",
  },
  question: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#929292",
  },
  questionsTitle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    alignSelf: "start",
    color: "#742B88",
    marginLeft: "-15px",
  },
  eachQuestion: {
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "43px",

    background:
      "-webkit-linear-gradient( 103.21deg, #1F1F1F 13.3%, #521465 52.42%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    alignSelf: "left",
    marginBottom: "1rem",
  },
}));

const INITIAL_FORM = {
  answers: [],
};

let PageSize = 1;

const QuizSingle = () => {
  const { token } = useContext(AuthContext);
  const [section, setSection] = useState([]);
  const [email, setEmail] = useState(null);
  const [openResume, setOpenResume] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const classes = useStyles();
  const [formData, setFormData] = useState({
    answers: [],
  });
  const [loading, setLoading] = useState(false);
  const [successFormSubmit, setSuccessFormSubmit] = useState(false);
  const [successFinalStep, setSuccessFinalStep] = useState(false);
  const [sectionFailed, setSectionFailed] = useState(false);
  const { watch, register, errors, handleSubmit } = useForm();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  //caculate total questions
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [pageCounter, setPageCounter] = useState(1);
  const isMd = useMediaQuery("(max-width:720px)");
  const [showSubSections, setShowSubSection] = useState(false);
  const [subSectionStartIndex, setSubSectionStartIndex] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [currentPageُSection, setCurrentPageSection] = useState(1);
  const [data, setData] = useState();
  const [maxlength, setMaxlength] = useState();
  const [faildMassage, setFaildMassage] = useState(
    "Something went wrong! Please try again..."
  );
  const [dataSection, setDataSection] = useState({
    answers: [],
  });

  const [canContinue, setCanContinue] = useState(false);
  const [continueData, setcontinueData] = useState(false);

  const [error, setError] = useState(null);
  useEffect(() => {
    const sendRequset = async () => {
      try {
        const response = await axios.get(
          `https://app.mahimo.co/quiz/${id}/questions`
        );
        setSection(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (location.state === undefined) sendRequset();
    else setSection(location.state.section);
  }, [id, location]);

  let maxPage;

  if (data) {
    maxPage = Math.ceil(
      data.questions.length / (isMd ? data.mobile_count : data.desktop_count)
    );
  }

  const compare = (a, b) => {
    let compration = 0;
    if (a.order > b.order) {
      compration = 1;
    }
    if (a.order < b.order) {
      compration = -1;
    }

    return compration;
  };

  useEffect(() => {
    const firstPageIndex = (currentPageُSection - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const sortedData = section && section.slice(firstPageIndex, lastPageIndex);
    setData(sortedData[0]);
    setMaxlength(section.length);
  }, [currentPageُSection, section]);

  useEffect(() => {
    if (data) {
      const begin =
        (currentPage - 1) * (isMd ? data.mobile_count : data.desktop_count);
      const end = begin + (isMd ? data.mobile_count : data.desktop_count);
      const sortedData =
        data &&
        data.questions &&
        data.questions.sort(compare).slice(begin, end);

      setCurrentData(sortedData);
    }
  }, [data, isMd, currentPage]);

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
    setPageCounter((pageCounter) => Math.min(pageCounter + 1, totalQuestions));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
    setPageCounter((pageCounter) => Math.max(pageCounter - 1, 1));
  }

  const nextSectionHandler = async () => {
    if (currentPage < maxPage) {
      next();
      return;
    }

    if (currentPageُSection < maxlength) {
      setCurrentPageSection(currentPageُSection + 1);
      setCurrentPage(1);
      setPageCounter((pageCounter) =>
        Math.min(pageCounter + 1, totalQuestions)
      );
      try {
        setLoading(true);
        const response = await fetch(
          `https://app.mahimo.co/quiz/${id}/questions/?form=${data.form}`,
          {
            method: data.is_passed ? "PATCH" : "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              section_id: section[currentPageُSection - 1].id,
              form: data.form,
              ...dataSection,
            }),
          }
        );

        if (!response.ok) {
          console.log(response.json());
          setLoading(false);
          setSectionFailed(true);
          // setFaildMassage(response.json().detail);
          throw new Error("ERROR ON POST QUESTION");
        } else {
          setLoading(false);
          setSuccessFormSubmit(true);
          setDataSection({ answers: [] });
        }
      } catch (err) {
        console.log(err);
      }

      return;
    } //this is the End!
    else if (currentPageُSection === maxlength && currentPage !== 1) {
      try {
        setLoading(true);
        const response = await fetch(
          `https://app.mahimo.co/quiz/${id}/questions/?form=${data.form}`,
          {
            method: data.is_passed ? "PATCH" : "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              section_id: section[currentPageُSection - 1].id,
              form: data.form,
              ...dataSection,
            }),
          }
        );

        if (!response.ok) {
          setLoading(false);
          setSectionFailed(true);
          throw new Error("ERROR ON POST QUESTION");
        }

        if (data.final) {
          setLoading(false);
          setSuccessFinalStep(true);
          setTimeout(() => {
            if (token) {
              history.push(`/quiz/result/${data.form}`);
            } else {
              history.push(`/signup`, {
                formId: data.form,
              });
            }
          }, 2000);

          return;
        }
        setSuccessFormSubmit(true);
        setShowSubSection(false);
      } catch (err) {
        console.log(err);
      }
      return;
    } else {
      try {
        setLoading(true);

        if (token) {
          const response = await fetch(
            `https://app.mahimo.co/quiz/${id}/questions/?form=${data.form}`,
            {
              method: data.is_passed ? "PATCH" : "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },

              body: JSON.stringify({
                section_id: data.id,
                form: data.form,
                ...formData,
              }),
            }
          );

          if (!response.ok) {
            setLoading(false);
            setSectionFailed(true);
            throw new Error("ERROR ON POST QUESTION");
          }

          if (data.final) {
            setLoading(false);
            setSuccessFinalStep(true);
            setTimeout(() => {
              if (token) {
                history.push(`/quiz/result/${data.form}`);
              } else {
                history.push(`/signup`, {
                  formId: data.form,
                });
              }
            }, 2000);

            return;
          }
        } else if (email) {
          const response = await fetch(
            `https://app.mahimo.co/quiz/${id}/questions/?form=${data.form}&email=${email}`,
            {
              method: data.is_passed ? "PATCH" : "POST",
              headers: {
                "Content-Type": "application/json",
              },

              body: JSON.stringify({
                section_id: data.id,
                form: data.form,
                ...formData,
              }),
            }
          );

          if (!response.ok) {
            setLoading(false);
            setSectionFailed(true);
            throw new Error("ERROR ON POST QUESTION");
          }

          if (data.final) {
            setLoading(false);
            setSuccessFinalStep(true);
            setTimeout(() => {
              if (token) {
                history.push(`/quiz/result/${data.form}`);
              } else {
                history.push(`/signup`, {
                  formId: data.form,
                });
              }
            }, 2000);

            return;
          }
        } else {
          const response = await fetch(
            `https://app.mahimo.co/quiz/${id}/questions/?form=${data.form}`,
            {
              method: data.is_passed ? "PATCH" : "POST",
              headers: {
                "Content-Type": "application/json",
              },

              body: JSON.stringify({
                section_id: data.id,
                form: data.form,
                ...formData,
              }),
            }
          );

          if (!response.ok) {
            setLoading(false);
            setSectionFailed(true);
            throw new Error("ERROR ON POST QUESTION");
          }

          if (data.final) {
            setLoading(false);
            setSuccessFinalStep(true);
            setTimeout(() => {
              if (token) {
                history.push(`/quiz/result/${data.form}`);
              } else {
                history.push(`/signup`, {
                  formId: data.form,
                });
              }
            }, 2000);

            return;
          }
        }

        setSuccessFormSubmit(true);
        setShowSubSection(false);

        try {
          const response = await fetch(
            `https://app.mahimo.co/quiz/${id}/questions/?form=${data.form}`
          );

          const responseData = await response.json();
          console.log(responseData);
          // caculate total questions
          if (responseData !== null) {
            let total_questions = 0;
            for (var i = 0; i < responseData.length; i++) {
              total_questions += responseData[i].questions.length;
            }
            setTotalQuestions(total_questions);
          }
          setFormData(INITIAL_FORM);
          setLoading(false);
          setCurrentPage(1);

          setSection(responseData);
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const prevSectionHandler = async () => {
    if (currentPage > 1 && !showSubSections) {
      //write to get back
      dataSection.answers.pop();
      prev();
      return;
    }
    if (currentPageُSection > 1) {
      setCurrentPageSection(currentPageُSection - 1);
      return;
    }
    if (currentPage > 1 && showSubSections) {
      setShowSubSection(false);
      return;
    }
    if (data.order === 0) {
      history.goBack();
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(
        `https://app.mahimo.co/quiz/${id}/questions/?form=${data.form}&section=${data.prev_section}`
      );

      const responseData = await response.json();
      setTotalQuestions(0);
      setLoading(false);
      setSection(responseData);
    } catch (err) {
      // console.log(err);
    }
  };

  const inputChangeHandler = (e, idx, id) => {
    const value = e.target.value;
    let newArr = [...formData.answers];

    newArr[idx] = {
      question: id,
      answer: value,
    };

    setFormData({
      answers: [...newArr],
    });
    setDataSection({
      answers: [...newArr],
    });
  };

  const radioChangeHandler = (e, idx, id) => {
    let add = {
      question: id,
      choices: [+e.target.value],
    };

    const idExists = dataSection.answers.some((i) => i.question === id);
    if (idExists) {
      dataSection.answers.pop();
      formData.answers.pop();
    }
    setFormData((prevState) => ({
      answers: [...prevState.answers, add],
    }));
    setDataSection((prevState) => ({
      answers: [...prevState.answers, add],
    }));
  };

  const checkboxChangeHandler = (e, idx, id) => {
    let newArr = [...formData.answers];
    let a = [];

    watch(`checkbox_${idx}`).map((c) => a.push(+c));

    newArr[idx] = {
      question: id,
      choices: [...a],
    };

    setFormData({
      answers: [...newArr],
    });
  };

  const handleAuthSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessFormSubmit(false);
  };
  const handleSectionFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSectionFailed(false);
  };
  const handleFinalStep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessFinalStep(false);
  };

  const handleClickOpen = () => {
    setOpenResume(true);
  };

  const handleClose = () => {
    setOpenResume(false);
  };
  //continue
  const handleCancelContinue = () => {
    setCanContinue(false);
  };

  const handleContinue = () => {
    setLoading(true);
    setSection(continueData);
    setCurrentPage(1);
    setFormData(INITIAL_FORM);
    handleCancelContinue();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handelSend = async () => {
    try {
      if (email === null || firstName === null) {
        setError(true);
        return;
      } else {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const response = await fetch(
          "https://app.mahimo.co/quiz/cookie/get_remained_sections/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              first_name: firstName,
              // last_name: lastName,
            }),
          }
        );
        const responseData = await response.json();
        if (!response.ok) {
          setLoading(false);
          handleClose();
          setCanContinue(false);
          throw new Error("ERORRR");
        } else {
          if (responseData.remained_sections.length === 0) {
            handleClose();
            setLoading(false);
            setCanContinue(false);
          } else {
            setcontinueData(responseData.remained_sections);
            setLoading(false);
            setCanContinue(true);
            handleClose();
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hastoken = async () => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(
        "https://app.mahimo.co/quiz/cookie/get_remained_sections/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        setLoading(false);
        setCanContinue(false);
        throw new Error("ERORRR");
      } else {
        if (responseData.remained_sections.length === 0) {
          setLoading(false);
          setCanContinue(false);
        } else {
          setcontinueData(responseData.remained_sections);
          setLoading(false);
          setCanContinue(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!token) {
      handleClickOpen();
    } else {
      setTimeout(() => {
        hastoken();
      }, 2000);
    }
  }, []);

  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   return () => (document.body.style.overflow = 'unset');
  // }, []);

  return (
    <>
      <div className={classes.topGradient}></div>
      <div className={classes.Ellipse}></div>
      <div className={classes.mahimoSvg}>
        <img src={mahimoSvg} alt="defualt" zIndex="2" style={{ zIndex: 1 }} />
      </div>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <div id="idSvgLoading">
          <img src={mahimoSvg} alt="" />
        </div>
      </Backdrop>

      <Snackbar
        open={successFormSubmit}
        autoHideDuration={3000}
        onClose={handleAuthSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAuthSuccessClose} severity="success">
          Success ...
        </Alert>
      </Snackbar>
      <Snackbar
        open={sectionFailed}
        autoHideDuration={3000}
        onClose={handleSectionFailed}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSectionFailed} severity="error">
          {faildMassage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successFinalStep}
        autoHideDuration={3000}
        onClose={handleFinalStep}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleFinalStep} severity="success">
          {token
            ? "Success form submit! Redirect to result page..."
            : "Success form submit! Redirect to signup page..."}
        </Alert>
      </Snackbar>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{
            justifyContent: "flex-end",
            padding: "10px",
            marginTop: "-20px",
            marginRight: "40px",
            display: "flex",
          }}
        >
          <img src={star} alt="" style={{ marginBottom: "-32px" }} />
        </GridItem>
      </GridContainer>

      <GridContainer
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "start",
          justify: "start",

          justifyContent: "center",
        }}
      >
        {/* first section  percent*/}

        <Grid xs={12} sm={12} md={12} lg={7} style={{ marginTop: "-1rem" }}>
          <GridContainer
            style={{
              marginLeft: "auto",

              justifyContent: "start",
              flexDirection: "center",
              alignItems: "center",
              justify: "center",
              width: "100%",
              zIndex: 2,
            }}
          >
            {openResume ? (
              <div className={classes.openResume}>
                <Typography className={classes.welcome}>Hi there!</Typography>
                <TextField
                  variant="standard"
                  autoFocus
                  id="first_name"
                  label="Name"
                  type="text"
                  size="normal"
                  fullWidth
                  className={classes.specificName}
                  required
                  error={error}
                  onChange={(e) => setFirstName(e.target.value)}
                />

                <TextField
                  variant="standard"
                  id="email"
                  label="Email Address"
                  type="email"
                  size="normal"
                  className={classes.specificName}
                  error={error}
                  fullWidth
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div className={classes.buttonBox}>
                  <IconButton
                    aria-label="cancel"
                    style={{
                      height: 48,
                      marginRight: 20,
                      background: "#FBF7FF",
                    }}
                    onClick={handleClose}
                  >
                    <ClearIcon />
                  </IconButton>
                  <Box
                    style={{
                      height: 45,
                      width: 45,
                      borderRadius: 360,
                      background: "#732F88",
                      justifyContent: "center",
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <IconButton aria-label="cancel" onClick={handelSend}>
                      <img
                        src={arrowRight}
                        style={{
                          position: "relative",
                          alignSelf: "center",
                        }}
                        alt=""
                      />
                    </IconButton>
                  </Box>
                </div>
              </div>
            ) : canContinue ? (
              <div classes={classes.continueDiv}>
                <Typography classes={classes.continueText}>
                  Do you want continue your Last Quiz?
                </Typography>

                <div className={classes.buttonBox}>
                  <IconButton
                    aria-label="cancel"
                    style={{
                      height: 48,
                      marginRight: 20,
                      background: "#FBF7FF",
                    }}
                    onClick={handleCancelContinue}
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    aria-label="cancel"
                    style={{
                      height: 48,
                      marginRight: 20,
                      background: "#76288a",
                    }}
                    onClick={handleContinue}
                  >
                    <CheckIcon style={{ color: "white" }} />
                  </IconButton>
                </div>
              </div>
            ) : (
              data && (
                <>
                  <div style={{ display: "flex", justifyContent: "start" }}>
                    {totalQuestions > 0 ? (
                      <Typography className={classes.question}>
                        QUESTION {pageCounter} OF {totalQuestions} |
                      </Typography>
                    ) : (
                      <Typography className={classes.question}>
                        First question |
                      </Typography>
                    )}

                    <Typography className={classes.questionsTitle}>
                      {data.title}
                    </Typography>
                  </div>
                  {/* <h2>{data.title}</h2> */}
                  <form
                    onSubmit={handleSubmit(nextSectionHandler)}
                    style={{
                      width: "100%",
                    }}
                  >
                    {!showSubSections &&
                      currentData &&
                      currentData.map((q, idx) => {
                        return (
                          <>
                            <div
                              key={q.id}
                              style={{
                                marginBottom: "1rem",
                                width: "85%",
                              }}
                            >
                              <Typography className={classes.eachQuestion}>
                                {" "}
                                {q.question}
                              </Typography>

                              <div
                                key={q.id}
                                style={{
                                  marginBottom: "1rem",
                                  width: "80%",
                                }}
                              >
                                {q.question_type === 1 && (
                                  <TextField
                                    type="text"
                                    label="Input test"
                                    variant="outlined"
                                    fullWidth
                                    name={`inputText_${q.index}`}
                                    margin="normal"
                                    onChange={(e) =>
                                      inputChangeHandler(e, idx, q.id)
                                    }
                                    inputRef={register({ required: true })}
                                    value={
                                      formData.answers[q.index] &&
                                      formData.answers[q.index].answer
                                        ? formData.answers[q.index].answer
                                        : ""
                                    }
                                  />
                                )}
                                {errors[`inputText_${q.index}`] && (
                                  <div style={{ color: "red" }}>
                                    Please fill this input.
                                  </div>
                                )}

                                {q.question_type === 0 && (
                                  <>
                                    <RadioGroup
                                      name={`radio_${idx}`}
                                      onChange={(e) =>
                                        radioChangeHandler(e, idx, q.id)
                                      }
                                    >
                                      {q.choices.map((c) => (
                                        <FormControl
                                          component="fieldset"
                                          style={{
                                            width: "90%",

                                            background: "#FAFAFA",
                                            margin: "2px",
                                          }}
                                          key={c.id}
                                        >
                                          <FormGroup aria-label="position">
                                            <FormControlLabel
                                              control={
                                                <Radio
                                                  color="primary"
                                                  style={{
                                                    marginRight: "15px",
                                                  }}
                                                />
                                              }
                                              value={c.id.toString()}
                                              label={c.answer}
                                              labelPlacement="start"
                                              name={`radio_${idx}`}
                                              style={{
                                                justifyContent: "space-between",

                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                fontWeight: "300",
                                                fontSize: "14px",
                                                lineHeight: "21px",

                                                color: "rgba(69, 28, 82, 1)",
                                              }}
                                              inputRef={register({
                                                required: true,
                                              })}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                      ))}
                                    </RadioGroup>
                                  </>
                                )}
                                {q.choices.map((c, idx) => (
                                  <div key={c.id}>
                                    {q.question_type === 2 && (
                                      <FormControl
                                        component="fieldset"
                                        style={{
                                          width: "90%",
                                          background: "#FAFAFA",
                                          margin: "2px",
                                        }}
                                      >
                                        <FormGroup aria-label="position">
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                color="primary"
                                                style={{ marginRight: "15px" }}
                                              />
                                            }
                                            label={c.answer}
                                            value={c.id}
                                            onChange={(e) =>
                                              checkboxChangeHandler(
                                                e,
                                                q.index,
                                                q.id
                                              )
                                            }
                                            name={`checkbox_${q.index}`}
                                            inputRef={register({
                                              required: true,
                                            })}
                                            labelPlacement="start"
                                            style={{
                                              justifyContent: "space-between",
                                              fontFamily: "Poppins",
                                              fontStyle: "normal",
                                              fontWeight: "300",
                                              fontSize: "14px",
                                              lineHeight: "21px",

                                              color: "rgba(69, 28, 82, 1)",
                                            }}
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    )}
                                  </div>
                                ))}
                                {errors[`radio_${idx}`] && (
                                  <div style={{ color: "red" }}>
                                    Please select an item.
                                  </div>
                                )}
                                {errors[`checkbox_${q.index}`] && (
                                  <div style={{ color: "red" }}>
                                    Please select at least one item.
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}

                    {showSubSections &&
                      section.subsections.map((sub, idx) => (
                        <div key={idx}>
                          <h2>{sub.title}</h2>
                          {sub.questions &&
                            sub.questions.map((q, idx) => {
                              return (
                                <>
                                  <div
                                    key={q.id}
                                    style={{
                                      marginBottom: "1rem",
                                      width: "70%",
                                    }}
                                  >
                                    <div className="heading">
                                      <span className="heading-name">
                                        {q.question}
                                      </span>
                                      <div className="heading-bg"></div>
                                      <h2 className="heading-title">
                                        {q.question}
                                      </h2>
                                    </div>
                                    <div style={{ marginBottom: "1rem" }}>
                                      {q.description}{" "}
                                    </div>
                                    <div
                                      key={q.id}
                                      style={{
                                        marginBottom: "1rem",
                                        width: "90%",
                                      }}
                                    >
                                      {q.question_type === 1 && (
                                        <TextField
                                          type="text"
                                          label="Input test"
                                          variant="outlined"
                                          fullWidth
                                          name="inputText"
                                          margin="normal"
                                          onChange={(e) =>
                                            inputChangeHandler(
                                              e,
                                              idx + subSectionStartIndex,
                                              q.id
                                            )
                                          }
                                          inputRef={register({
                                            required: true,
                                          })}
                                        />
                                      )}
                                      {q.question_type === 0 && (
                                        <RadioGroup
                                          name={`radio_${
                                            idx + subSectionStartIndex
                                          }`}
                                          onChange={(e) =>
                                            radioChangeHandler(
                                              e,
                                              idx + subSectionStartIndex,
                                              q.id
                                            )
                                          }
                                        >
                                          {q.choices.map((c) => (
                                            <div key={c.id}>
                                              <FormControl
                                                component="fieldset"
                                                style={{
                                                  width: "100%",
                                                  borderBottom:
                                                    "1px solid rgba(0, 0, 0, 0.2)",
                                                }}
                                              >
                                                <FormGroup
                                                  aria-label="position"
                                                  name="form-group"
                                                >
                                                  <FormControlLabel
                                                    control={
                                                      <Radio color="primary" />
                                                    }
                                                    value={c.id.toString()}
                                                    label={c.answer}
                                                    labelPlacement="start"
                                                    name={`radio_${
                                                      idx + subSectionStartIndex
                                                    }`}
                                                    style={{
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                    inputRef={register({
                                                      required: true,
                                                    })}
                                                  />
                                                </FormGroup>
                                              </FormControl>
                                            </div>
                                          ))}
                                        </RadioGroup>
                                      )}
                                      {q.choices.map((c) => (
                                        <div key={c.id}>
                                          {q.question_type === 2 && (
                                            <FormControl
                                              component="fieldset"
                                              style={{
                                                width: "100%",
                                                borderBottom:
                                                  "1px solid rgba(0, 0, 0, 0.2)",
                                              }}
                                            >
                                              <FormGroup aria-label="position">
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox color="primary" />
                                                  }
                                                  label={c.answer}
                                                  value={c.id}
                                                  onChange={(e) =>
                                                    checkboxChangeHandler(
                                                      e,
                                                      idx +
                                                        subSectionStartIndex,
                                                      q.id
                                                    )
                                                  }
                                                  name={`checkbox_${
                                                    idx + subSectionStartIndex
                                                  }`}
                                                  inputRef={register({
                                                    required: true,
                                                  })}
                                                  labelPlacement="start"
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                />
                                              </FormGroup>
                                            </FormControl>
                                          )}
                                        </div>
                                      ))}
                                      {errors[
                                        `radio_${idx + subSectionStartIndex}`
                                      ] && (
                                        <div style={{ color: "red" }}>
                                          Please select one item.
                                        </div>
                                      )}
                                      {errors[
                                        `checkbox_${idx + subSectionStartIndex}`
                                      ] && (
                                        <div style={{ color: "red" }}>
                                          Please select at least one item.
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      ))}

                    <div className={classes.buttonBox}>
                      <Box
                        onClick={prevSectionHandler}
                        style={{
                          height: 40,
                          width: 40,
                          marginRight: 10,
                          borderRadius: 360,
                          justifyContent: "center",
                          marginTop: "10px",
                          display: "flex",
                        }}
                      >
                        <img
                          src={arrowLeft}
                          style={{ position: "relative", alignSelf: "center" }}
                          alt=""
                        />
                      </Box>
                      <div
                        style={{
                          width: 55,
                          height: 55,
                          display: "flex",
                          justifyContent: "center",
                          marginRight: 20,
                        }}
                      >
                        <CircularProgressbarWithChildren
                          value={
                            totalQuestions < 1
                              ? pageCounter
                              : (pageCounter / totalQuestions) * 100
                          }
                          strokeWidth={6}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#732F88",
                          })}
                        >
                          <RadialSeparators
                            count={totalQuestions}
                            style={{
                              background: "#fff",
                              width: "2px",
                              // This needs to be equal to props.strokeWidth
                              height: `${10}%`,
                            }}
                          />
                          <Box
                            style={{
                              height: 40,
                              width: 40,

                              borderRadius: 360,
                              background: "#732F88",
                              justifyContent: "center",
                              alignSelf: "center",
                              display: "flex",
                            }}
                          >
                            <IconButton variant="text" type="submit">
                              <img
                                src={arrowRight}
                                style={{
                                  position: "relative",
                                  alignSelf: "center",
                                }}
                                alt=""
                              />
                            </IconButton>
                          </Box>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </form>
                </>
              )
            )}
          </GridContainer>
        </Grid>

        {/* second section */}
        <SideLayout />
      </GridContainer>
    </>
  );
};

export default QuizSingle;
