import React, { useState, useEffect, useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import blank from "../../assets/img/blank_chart.svg";
import { colors } from "@material-ui/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,

  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Percent of quiz",
    },
  },
};

export default function App({ data }) {
  const [labels2, setLabels] = useState(null);
  const [datas, setDatas] = useState(null);
  const [flags, setFlags] = useState(false);

  useEffect(() => {
    if (data !== null) {
      if (datas === null) {
        SetDataChart();
      } else {
        console.log(datas);
        if (datas.labels.length > 0 && datas.datasets.length > 0) {
          setFlags(true);
        }
      }
    }
  }, [datas]);

  const COLORS = [
    "#55A55E",
    "#5E5498",
    "#FFF2EC",
    "#B08A01",
    "#4dc9f6",
    "#f67019",
    "#f53794",
    "#537bc4",
    "#acc236",
    "#166a8f",
  ];

  function SetDataChart() {
    if (data !== null) {
      var result = [];
      let pp = data.filter(
        (ele, ind) => ind === data.findIndex((elem) => elem.date === ele.date)
      );
      for (var i in pp) {
        result.push(pp[i].date);
      }

      setLabels(result);

      let dataset = [];
      //merge duplicate objects in array of objects
      const newData = [...new Set(data.map((d) => d.name))].map((name) => {
        return {
          name,
          percentage: data
            .filter((d) => d.name === name)
            .map((d) => d.percentage),
        };
      });

      for (var j in newData) {
        if (dataset.length < 10) {
          dataset.push({
            label: newData[j].name,
            data: newData[j].percentage,
            borderColor: COLORS[j],
            backgroundColor: COLORS[j],
            pointStyle: "circle",
            pointRadius: 5,
            pointHoverRadius: 15,
          });
        }
      }
      setDatas({ labels: result, datasets: dataset });
    }
  }

  return (
    <>
      {flags ? (
        <Line options={options} data={datas} />
      ) : (
        <img src={blank} alt="blank" zIndex="2" style={{ zIndex: 1 }} />
      )}
    </>
  );
}
