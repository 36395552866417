import React from "react";
import { Grid, Button, Typography, Paper } from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem.js";

export default function suggest({ doctorSuggestedProducts }) {
  return (
    <>
      <div className="div-center-product">
        {doctorSuggestedProducts ? (
          doctorSuggestedProducts.map((product, idx) => (
            <div style={{ margin: "16px" }}>
              <Paper style={{ height: "100%", width: "288px" }}>
                <>
                  {product.images[0] && (
                    <div class="w3-card-4">
                      <img
                        src={`https://app.mahimo.co${product.images[0].image}`}
                        style={{ width: "288px", height: "208px" }}
                        alt="profile"
                      />
                    </div>
                  )}

                  <Typography
                    style={{
                      fontSize: "calc(0.75em + 1vmin)",
                      width: "100%",
                      fontWeight: "bold",
                      textAlign: "left",
                      float: "left",
                      height: "4rem",
                    }}
                  >
                    {product.name}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "calc(0.75em + 0.5vmin)",
                      width: "100%",
                      alignItems: "end",
                      textAlign: "left",
                      float: "left",
                    }}
                  >
                    {`${product.description.substring(0, 300)}... `}
                    <a href={product.related_link}>Read more</a>
                  </Typography>
                </>
              </Paper>
            </div>
          ))
        ) : (
          <div style={{ margin: "16px" }}>
            <Paper style={{ height: "100%", width: "288px" }}>
              <>
                <Typography
                  style={{
                    fontSize: "calc(0.75em + 1vmin)",
                    width: "100%",
                    fontWeight: "bold",
                    textAlign: "left",
                    float: "left",
                    height: "466px",
                  }}
                >
                  No product Set
                </Typography>
                <Typography
                  style={{
                    fontSize: "calc(0.75em + 0.5vmin)",
                    width: "100%",
                    alignItems: "end",
                    textAlign: "left",
                    float: "left",
                  }}
                ></Typography>
              </>
            </Paper>
          </div>
        )}
      </div>
    </>
  );
}
