import React, { useContext } from "react";
import { Grid, Button, Typography, Paper, Box } from "@material-ui/core";
import { AuthContext } from "../context/auth";

import Ellipse7 from "../../assets/img/NEllipse.svg";
export default function Index({ defaultProduct }) {
  const { jwtToken } = useContext(AuthContext);
  return (
    <>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={5}
        style={{
          justifyContent: "left",
          marginTop: "2rem",
        }}
      >
        {defaultProduct && (
          <div className="defaultProduct-div">
            <Box className="defaultProduct-box" />
            <div className=" defaultProduct-div-2">
              {/* //180 270 _ 170 174 */}
              <img
                src={`https://app.mahimo.co/${defaultProduct[0].images[0].image}`}
                alt="defualt"
                width="190"
                height="280"
                className="defaultProduct-image"
              />
            </div>
            <div className="defaultProduct-div-3">
              <Typography className="defaultProduct-text-price">
                {defaultProduct[0].name}
              </Typography>
              <Typography className="defaultProduct-text-price">
                $ {defaultProduct[0].price}
              </Typography>
              <a
                href={
                  jwtToken
                    ? defaultProduct[0].related_link +
                      "?mo_jwt_token=" +
                      jwtToken
                    : defaultProduct[0].related_link
                }
                className="defaultProduct-text-price-a"
              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    textTransform: "none",
                  }}
                >
                  <span className=" defaultProduct-text-price-span">
                    Add to Cart
                  </span>
                </Button>
              </a>
            </div>
          </div>
        )}
      </Grid>
      <div className="ellipse-div">
        <img src={Ellipse7} alt="Ellips" />
      </div>
    </>
  );
}
