import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState, useContext, useCallback } from "react";
// import EditIcon from '@material-ui/icons/Edit';
import SideBar from "../../components/NavBar/SideBar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "../../components/context/auth";
import {
  TableContainer,
  Paper,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  Backdrop,
  CircularProgress,
  Button,
  Hidden,
  Snackbar,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import userEvent from "@testing-library/user-event";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//import UserTable from './admin_user_table';
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    paddingBottom: "2em",
    width: "auto",
    background: "transparent",
    flexGrow: 1,
  },
  table: {
    backgroundColor: "white",
  },
  head: {
    color: theme.palette.primary.light,
  },
  icon: {
    color: theme.palette.primary.light,
  },
  root: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(rgba(50, 50, 50, 0.8), rgba(50, 50, 50, 0.8)), ",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginTop: "80px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));
export const QuizList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState();
  const history = useHistory();
  const [tableCount, SetTablecCount] = useState(0);
  const { token, logout, access } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [successfullyDeleted, setSuccessfullyDeleted] = useState(false);
  const [deleteFailed, setDeleteFailed] = useState(false);
  const [doctorNoteOrder, setDoctorNoteOrder] = useState(0); // 0 is all, 1 is has note, 2 is empty

  const handleDeleteSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessfullyDeleted(false);
  };

  const handleDeleteFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDeleteFailed(false);
  };

  //change the doctor note order
  const changeOrderDoctor = useCallback(() => {
    console.log(doctorNoteOrder);
    switch (doctorNoteOrder) {
      case 0:
        setDoctorNoteOrder(1);
        setPage(0);
        setdata(null);
        //  loadData();
        break;
      case 1:
        setDoctorNoteOrder(2);
        setPage(0);
        setdata(null);
        //   loadData();
        break;
      case 2:
        setDoctorNoteOrder(0);
        setPage(0);
        setdata(null);
        //  loadData();
        break;
      default:
        setDoctorNoteOrder(0);
        setPage(0);
        setdata(null);
        // loadData();
        break;
    }
    console.log(doctorNoteOrder);
  }, [doctorNoteOrder, page]);

  //load quiz list
  const SearchUser = useCallback(
    (event) => {
      event.preventDefault();
      setdata(null);
      setPage(0);
      loadData();
    },
    [search, doctorNoteOrder]
  );

  //delete account
  const deleteAccount = useCallback(
    async (row, index) => {
      setLoading(true);
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
        const response = await fetch(
          `https://app.mahimo.co/quiz/admin/delete-form/${row}/`,
          {
            method: "DELETE",
            headers: myHeaders,
          }
        );

        if (!response.ok) {
          setDeleteFailed(true);
          setLoading(false);
          throw new Error("ERORRR");
        } else {
          data.splice(index, 1);
          setSuccessfullyDeleted(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [data, token]
  );

  //load quiz list
  const loadData = async () => {
    try {
      setLoading(true);
      var Url;
      //check the search parameter
      if (search) {
        if (doctorNoteOrder === 1) {
          Url = `https://app.mahimo.co/user/forms/?search=${search}&page=${
            page + 1
          }&is_doctor_note=True`;
        } else if (doctorNoteOrder === 2) {
          Url = `https://app.mahimo.co/user/forms/?search=${search}&page=${
            page + 1
          }&is_doctor_note=False`;
        } else {
          Url = `https://app.mahimo.co/user/forms/?search=${search}&page=${
            page + 1
          }`;
        }
      } else {
        //check the doctor note  filter
        if (doctorNoteOrder === 1) {
          Url = `https://app.mahimo.co/user/forms/?page=${
            page + 1
          }&is_doctor_note=True`;
        } else if (doctorNoteOrder === 2) {
          Url = `https://app.mahimo.co/user/forms/?page=${
            page + 1
          }&is_doctor_note=False`;
        } else {
          Url = `https://app.mahimo.co/user/forms/?page=${page + 1}`;
        }
      }
      const response = await fetch(Url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        //throw new Error('ERORRR');
        history.push("/admin/login");
        logout();
      }

      const responseData = await response.json();
      SetTablecCount(responseData.count);
      setdata(responseData.results);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadData();
    console.log(access);
  }, [page, doctorNoteOrder]);

  //pagenation
  const handleChangePage = (event, newPage) => {
    setdata([]);
    setPage(newPage);
    console.log(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const classes = useStyles();
  return (
    <>
      <SideBar />

      <div className={classes.root}>
        <TableContainer className={classes.container} component={Paper}>
          <h1 class="h1">List of Quiz</h1>

          <div class="flexsearch">
            <div class="flexsearch--wrapper">
              <form class="flexsearch--form" onSubmit={SearchUser}>
                <div class="flexsearch--input-wrapper">
                  <input
                    class="flexsearch--input"
                    type="search"
                    placeholder="search"
                    label="search"
                    name="search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <input
                  class="flexsearch--submit"
                  type="submit"
                  value="&#10140;"
                />
              </form>
            </div>
          </div>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell align="center">Username</TableCell>
                <TableCell align="center">date</TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      changeOrderDoctor();
                    }}
                    style={{ color: doctorNoteOrder > 0 ? "#76288a" : "black" }}
                  >
                    Doctor Note
                  </Button>
                </TableCell>
                <TableCell align="center">Show</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && (
                <>
                  {data
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          {row.email ? row.email : row.user.email}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {moment(row.created_at).format("MMM Do YY")}
                        </TableCell>
                        <TableCell align="center">
                          {row.is_doctor_note ? (
                            <CheckIcon className={classes.icon} />
                          ) : (
                            <CloseIcon className={classes.icon} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              //   openDialog(row);
                              history.push(`/admin/result/${row.id}`);
                            }}
                          >
                            <Hidden xsDown>Show</Hidden>
                            <span
                              style={{
                                width: 5,
                                display: "inline-block",
                              }}
                            />
                            <VisibilityIcon className={classes.icon} />
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            disabled={!access}
                            onClick={() => {
                              deleteAccount(row.id, index);
                            }}
                          >
                            <Hidden xsDown>Delete</Hidden>
                            <span
                              style={{
                                width: 5,
                                display: "inline-block",
                              }}
                            />
                            <DeleteIcon className={classes.icon} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "start",
            position: "relative",
          }}
        >
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[{ label: "all", value: -1 }]}
              colSpan={3}
              count={tableCount}
              rowsPerPage={rowsPerPage}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              variant="outlined"
              shape="rounded"
              style={{ color: "secondary" }}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </div>
      <Snackbar
        open={successfullyDeleted}
        autoHideDuration={3000}
        onClose={handleDeleteSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleDeleteSuccessClose} severity="success">
          Quiz successfully deleted!
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteFailed}
        autoHideDuration={3000}
        onClose={handleDeleteFailed}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleDeleteFailed} severity="error">
          Error occurs! Please try again...
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <div id="idSvgLoading">
          <img src={mahimoSvg} alt="" />
        </div>
      </Backdrop>
    </>
  );
};

export default QuizList;
