import React, { useContext } from "react";
import { Grid, Button, Typography, Paper, Box } from "@material-ui/core";
import { AuthContext } from "../context/auth";

export default function Index({ defaultProduct }) {
  const { jwtToken } = useContext(AuthContext);
  return (
    <Grid
      xs={4}
      sm={4}
      md={4}
      lg={4}
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",

        zIndex: 1,
      }}
    >
      {defaultProduct && (
        <div className="defaultProduct-mobile-div">
          <Box className="  defaultProduct-mobile-box" />

          <div className="defaultProduct-div-2">
            {/* //180 270 _ 170 174 */}
            <img
              src={`https://app.mahimo.co/${defaultProduct[0].images[0].image}`}
              alt="defualt"
              width="190"
              height="280"
              className="defaultProduct-image"
            />
          </div>
          <div className="defaultProduct-mobile-div-2">
            <Typography className="defaultProduct-text-price">
              {defaultProduct[0].name}
            </Typography>
            <Typography className="defaultProduct-mobile-text-price">
              $ {defaultProduct[0].price}
            </Typography>
            <a
              href={
                jwtToken
                  ? defaultProduct[0].related_link + "?mo_jwt_token=" + jwtToken
                  : defaultProduct[0].related_link
              }
              className="defaultProduct-mobile-text-price-a"
            >
              <Button
                color="primary"
                variant="contained"
                style={{
                  textTransform: "none",
                }}
              >
                <span classNames="defaultProduct-text-price-span">
                  Add to Cart
                </span>
              </Button>
            </a>
          </div>
        </div>
      )}
    </Grid>
  );
}
