import React, { useState, useContext } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Divider,
  Typography,
  Box,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LOGO from "../../assets/img/Logo.svg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { AuthContext } from "../context/auth";
import "./dashboard.css";
const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    color: "#838383",
    // color: theme.palette.primary.contrastText,
  },
  itemIcon: {
    minWidth: 35,
  },
  divider: {
    background: "#000",
    //backgroundColor: theme.palette.primary.light,
  },
  hover: {
    "&:hover": {
      background: "#838383",
    },
  },
}));
const NavList = ({ history }) => {
  const [sideBar, setsideBar] = useState(false);
  const { logout } = useContext(AuthContext);
  const handleSidebar = () => {
    setsideBar(!sideBar);
  };

  const getPages = () => {
    var list = [];

    list.push({
      label: "Dashboard",
      path: "/profile",
      icon: DashboardIcon,
    });
    list.push(
      // {
      //   comp: 'divider',
      // },
      {
        label: "List of quizzes",
        path: "/profile/quiz",
        icon: ListAltIcon,
      },
      {
        label: "List of orders",
        path: "/profile/orders",
        icon: ShoppingCartIcon,
      },
      {
        label: "Personal questions",
        path: "/profile/personal",
        icon: AssignmentIndIcon,
      },
      {
        label: "Settings",
        path: "/profile/settings",
        icon: SettingsIcon,
      },
      {
        label: "Log out",
        onClick: () => {
          logout();
          history.push("/login");
        },
        icon: ExitToAppIcon,
      }
    );
    return list;
  };
  const classes = useStyles();
  const pages = getPages();
  return (
    <div>
      <header className="header">
        <div className="navContainer">
          <span
            className="logo"
            style={{ color: "#fff", fontStyle: "italic", fontWeight: "400" }}
          >
            <RouterLink to="/">
              <img src={LOGO} alt="" />
            </RouterLink>
          </span>
          <div
            onClick={handleSidebar}
            className={`overlay ${sideBar ? "open" : ""}`}
          />
          <nav>
            <ul
              className="mainNav3"
              style={sideBar ? { transform: "translateX(0)" } : null}
            >
              <li
                className="li-dashboard-menu"
                style={{
                  marginTop: "2rem",
                }}
              >
                <Typography className="text-menu">Menu</Typography>
              </li>
              {pages.map(
                ({ label, path, comp, icon: Icon, onClick }, index) => {
                  if (path) {
                    return (
                      <ListItem
                        component={RouterLink}
                        to={path}
                        button
                        key={index}
                        style={{
                          background:
                            history.location.pathname === path
                              ? "#732F88"
                              : "#838383",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                        className={`list-group, 
                        ${
                          history.location.pathname === path
                            ? classes.selected
                            : ""
                        }`}
                      >
                        <ListItemIcon className={classes.itemIcon}>
                          <Icon
                            className={classes.icon}
                            style={{
                              color:
                                history.location.pathname === path
                                  ? "#732F88"
                                  : "#838383",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={label}
                          primaryTypographyProps={{
                            align: "left",
                            variant: "subtitle2",
                          }}
                          style={{
                            background:
                              history.location.pathname === path
                                ? "#732F88"
                                : "#838383",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                          className={`list-group, 
                          ${
                            history.location.pathname === path
                              ? classes.selected
                              : ""
                          }`}
                        />
                      </ListItem>
                    );
                  } else if (comp) {
                    return <Divider key={index} className={classes.divider} />;
                  } else {
                    return (
                      <ListItem
                        onClick={onClick}
                        button
                        key={index}
                        className="list-item-text"
                        style={{
                          background:
                            history.location.pathname === path
                              ? "#732F88"
                              : "#838383",
                        }}
                      >
                        <ListItemIcon className={classes.itemIcon}>
                          <Icon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={label}
                          primaryTypographyProps={{
                            align: "left",
                            variant: "subtitle2",
                          }}
                          className="list-item-text"
                        />
                      </ListItem>
                    );
                  }
                }
              )}
              <li className="side-bar-li-mahimo">
                <Typography className="side-bar-mahimo">
                  © 2022 Mahimo, Inc.
                </Typography>
              </li>
            </ul>
          </nav>
          <button
            onClick={handleSidebar}
            className={`navToggle ${sideBar ? "open" : null}`}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </header>
      <div className="wrapper"></div>
    </div>
  );
};
export default withRouter(NavList);
