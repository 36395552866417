import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  TextField,
  makeStyles,
  Button,
  InputAdornment,
  Hidden,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AuthContext } from '../components/context/auth';
import Layout from '../components/shared/Layout';
import MainButton from '../components/UIElements/Button';
import ProductCart from '../components/shared/ProductCart';
import productImg from '../assets/img/Vitamin A&K.svg';
import checkIcon from '../assets/img/check.svg';
import paypalImg from '../assets/img/PayPal.svg';
import arrowRight from '../assets/img/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  productBox: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
    padding: 10,
    marginTop: 20,
  },
  productHead: {
    marginTop: 30,
  },
  payWrap: {
    '@media (min-width: 1024px)': {
      paddingLeft: '12rem',
    },
  },
  payInfo: {
    margin: '2.5rem 0 1.5rem 0',
  },

  payText: {
    color: '#000000',
    fontSize: 13,
    opacity: 0.5,
    lineHeight: '24px',
  },
  payTitle: {
    color: '#040415',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 600,
    margin: '8px 0',
    opacity: 1,
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    marginTop: 10,
    fontSize: 15,
  },
  buttonRoot: {
    position: 'relative',
    justifyContent: 'flex-start',
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 25,
  },
  buttonArrow: {
    position: 'absolute',
    right: 10,
  },
  mainPrice: {
    color: '#79308D',
    marginLeft: 6,
    fontSize: 15,
  },
  mobileFixed: {
    '@media (max-width: 768px)': {
      position: 'sticky',
      bottom: 0,
      background: '#fff',
      zIndex: 999,
    },
  },
}));

function Cart() {
  const [products, setProducts] = useState(null);
  const [productsCard, setProductsCard] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [productCounts, setProductCounts] = useState(0);
  const { token } = useContext(AuthContext);
  const classes = useStyles();

  useEffect(() => {
    const sendRequest = async () => {
      const response = await fetch('https://app.mahimo.co/question/answers/', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      const responseData = await response.json();
      console.log(responseData);

      setProducts(responseData);
    };
    sendRequest();
  }, [token]);

  const plusProduct = (price) => {
    setTotalPrice(totalPrice + price);
  };

  const minusProduct = (price) => {
    setTotalPrice(totalPrice - price);
  };

  const countProduct = (count) => {
    setProductCounts(countProduct + count);
  };

  return (
    <Layout>
      <div className='heading'>
        <span className='heading-name'>Shopping Cart</span>
        <div className='heading-bg'></div>
        <h2 className='heading-title'>cart</h2>
      </div>
      <Grid container>
        <Grid item lg={6} md={12} xs={12} className={classes.productWrap}>
          {productsCard && (
            <ProductCart
              product={productsCard}
              setProduct={setProductsCard}
              plusProduct={plusProduct}
              minusProduct={minusProduct}
              countProduct={countProduct}
            />
          )}

          {/* <ProductCart /> */}
          <div className={classes.productHead}>
            <div className={classes.payTitle}>The Best Opportunity To Buy</div>
            <div>*Recommended On Your Test Result</div>
          </div>

          <Grid
            item
            container
            justify='space-between'
            className={classes.productBox}
          >
            {products &&
              products.map(({ product }) => {
                return (
                  <Grid item key={product.id}>
                    <div className={classes.payTitle}>{product.name}</div>
                    <div style={{ marginBottom: 10, color: '#7F7F7F' }}>
                      ${product.price}
                      <span className={classes.mainPrice}>$80</span>
                    </div>
                    <Button
                      color='primary'
                      variant='contained'
                      size='small'
                      onClick={() => setProductsCard(product)}
                    >
                      ADD TO CARD
                    </Button>
                  </Grid>
                );
              })}

            <Grid>
              <div>
                <img src={productImg} alt='' />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} md={12} xs={12}>
          <div className={classes.payWrap}>
            <TextField
              fullWidth
              variant='outlined'
              color='primary'
              label='Promo Code'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <img src={checkIcon} alt='check' />
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.mobileFixed}>
              <div className={classes.payInfo}>
                <Hidden mdDown>
                  <div className={classes.payTitle}>Summary</div>
                </Hidden>
                <Grid
                  item
                  container
                  justify='space-between'
                  className={classes.payText}
                >
                  <Grid>
                    <div>{productCounts} ITEMS</div>
                    <div>DELIVERY</div>
                  </Grid>
                  <Grid>
                    <div>${parseFloat(totalPrice).toFixed(2)}</div>
                    <div>FREE</div>
                  </Grid>
                </Grid>
                <div className={classes.total}>
                  <div className={classes.payTitle}>TOTAL</div>
                  <div className={classes.payTitle}>
                    ${parseFloat(totalPrice).toFixed(2)}
                  </div>
                </div>
              </div>
              <Link to='/address'>
                <MainButton
                  background
                  variant='contained'
                  className={classes.buttonRoot}
                  fullWidth
                >
                  Checkout
                  <img
                    src={arrowRight}
                    alt=''
                    className={classes.buttonArrow}
                  />
                </MainButton>
              </Link>
              <Button variant='outlined' fullWidth style={{ height: 48 }}>
                <img src={paypalImg} alt='' />
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Cart;
