import React from 'react'
import { TextField } from '@material-ui/core'

const UserAddressReadOnly = ({ userAddress }) => {
  
  return (
    <div style={{ width: '100%' }}>
      <TextField
        id='standard-read-only-input'
        label='Street'
        defaultValue={userAddress.address_1}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        margin='normal'
        style={{ display: 'block' }}
      />
      <TextField
        id='standard-read-only-input'
        label='Street'
        defaultValue={userAddress.address_2}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        margin='normal'
        style={{ display: 'block' }}
      />
      <TextField
        id='standard-read-only-input'
        label='City / Province'
        defaultValue={userAddress.city}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        margin='normal'
        style={{ display: 'block' }}
      />
       <TextField
        id='standard-read-only-input'
        label='state'
        defaultValue={userAddress.state}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        margin='normal'
        style={{ display: 'block' }}
      />
      <TextField
        id='standard-read-only-input'
        label='Zip Code'
        defaultValue={userAddress.postcode}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        margin='normal'
        style={{ display: 'block' }}
      />
      <TextField
        id='standard-read-only-input'
        label='country'
        defaultValue={userAddress.country}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        margin='normal'
        style={{ display: 'block' }}
      />
    </div>
  )
}

export default UserAddressReadOnly
