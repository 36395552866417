import React, { useContext, useState } from 'react';
import { Button, TextField, Snackbar } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../context/auth';
import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function UserInfo({ data }) {
  const { register, handleSubmit } = useForm();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [passwordFailed, setPasswordFailed] = useState(false);
  const { token, logout } = useContext(AuthContext);
  const history = useHistory();

  const handleUpdateClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUpdateFailed(false);
  };

  const handlePasswordClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setPasswordFailed(false);
  };

  const handleUpdateSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUpdateSuccess(false);
  };

  const formSubmitHandler = async (data) => {
    if (data.password !== data.password_2) {
      setPasswordFailed(true);
      return;
    }

    try {
      const response = await fetch('https://app.mahimo.co/user/profile/', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error('Error');
      }

      setUpdateSuccess(true);

      setTimeout(() => {
        logout();
        history.push('/login');
      }, 3000);

      console.log(responseData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        open={updateFailed}
        autoHideDuration={6000}
        onClose={handleUpdateClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleUpdateClose} severity='error'>
          Update failed! Please try again.
        </Alert>
      </Snackbar>
      <Snackbar
        open={passwordFailed}
        autoHideDuration={6000}
        onClose={handleUpdateClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handlePasswordClose} severity='error'>
          the Password dosen't match
        </Alert>
      </Snackbar>
      <Snackbar
        open={updateSuccess}
        autoHideDuration={6000}
        onClose={handleUpdateSuccessClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleUpdateSuccessClose} severity='success'>
          Update successful! Please login again.
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <TextField
          id='standard-read-only-input'
          label='Name'
          name='first_name'
          defaultValue={data && data.first_name}
          variant='outlined'
          fullWidth
          margin='normal'
          inputRef={register({ required: true })}
          style={{ display: 'block' }}
        />
        <TextField
          id='standard-read-only-input'
          label='Email'
          name='email'
          variant='outlined'
          fullWidth
          defaultValue={data && data.email}
          margin='normal'
          inputRef={register({ required: true })}
          style={{ display: 'block' }}
        />
        <TextField
          id='standard-read-only-input'
          label='New Password'
          type='password'
          name='password'
          variant='outlined'
          fullWidth
          margin='normal'
          inputRef={register({ required: true })}
          style={{ display: 'block' }}
        />
        <TextField
          id='standard-read-only-input'
          label='Re enter new Password'
          type='password'
          name='password_2'
          variant='outlined'
          fullWidth
          margin='normal'
          inputRef={register({ required: true })}
          style={{ display: 'block' }}
        />
        <Button
          variant='contained'
          color='primary'
          fullWidth
          type='submit'
          style={{
            margin: '15px 0',
            borderRadius: 10,
            height: 50,
          }}
        >
          Save Changes
        </Button>
      </form>
    </React.Fragment>
  );
}

export default UserInfo;
