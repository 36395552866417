import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  makeStyles,
  Hidden,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Backdrop,
  TablePagination,
  TableFooter,
  Paper,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { AuthContext } from "../../components/context/auth";

import VisibilityIcon from "@material-ui/icons/Visibility";
import mahimoSvg from "../../assets/img/mahimoSvg.svg";
import moment from "moment";
import "./profile.css";
import { useMediaQuery } from "react-responsive";
const useStyles = makeStyles((theme) => ({
  active: {
    color: "#742B88",

    borderRadius: 0,
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },

  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));

function Profile() {
  const { token, wpToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState(null);
  const history = useHistory();
  const [tableCount, SetTablecCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();

  //load order list
  const loadData = async () => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ wc_token: wpToken });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://app.mahimo.co/user/get_user_orders_history/",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) {
            setLoading(false);
          } else {
            SetTablecCount(result.length);
            setdata(result);
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!data && wpToken) loadData();
  }, [data, wpToken]);

  //pagenation
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1440 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1439 });
    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "4rem" }}>
          <span className="heading-name">
            <p className={classes.active}>Orders</p>
          </span>
        </div>
        <Box className="profile-main-box">
          <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.head}>
                <TableRow style={{ borderBottom: "2px solid #451C52" }}>
                  <TableCell align="center" className="profile-table-head-cell">
                    Order
                  </TableCell>
                  <TableCell align="center" className="profile-table-head-cell">
                    Date
                  </TableCell>
                  <TableCell align="center" className="profile-table-head-cell">
                    Status
                  </TableCell>
                  <TableCell align="center" className="profile-table-head-cell">
                    Total
                  </TableCell>
                  <TableCell align="center" className="profile-table-head-cell">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && (
                  <>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            align="center"
                            className="profile-table-head-cell"
                          >
                            #{row.id}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.date_created).format("MMM Do YY")}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="profile-table-cell"
                          >
                            {row.status}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="profile-table-cell"
                          >
                            {row.total}/{row.currency}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                //   openDialog(row);
                                history.push({
                                  pathname: "/profile/view/order",
                                  state: { data: row },
                                });
                              }}
                              className="profile-table-cell"
                            >
                              <Hidden xsDown>View</Hidden>
                              <span
                                style={{
                                  width: 5,
                                  display: "inline-block",
                                }}
                              />
                              <VisibilityIcon className={classes.icon} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableFooter
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "start",
              position: "relative",
            }}
          >
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "all", value: -1 }]}
                colSpan={3}
                count={tableCount}
                rowsPerPage={rowsPerPage}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                variant="outlined"
                shape="rounded"
                style={{ color: "secondary" }}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Box>
      </Desktop>
      <Tablet>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>

        <div className="heading" style={{ marginBottom: "2rem" }}>
          <span className="heading-name">
            <p className={classes.active}>Treatments</p>

            {/* {tab.chat && <span>Provider Messages</span>} */}
          </span>
        </div>
        <div></div>
        <Box className="profile-main-box">
          <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.head}>
                <TableRow style={{ borderBottom: "2px solid #451C52" }}>
                  <TableCell align="center" className="profile-table-head-cell">
                    Order
                  </TableCell>
                  <TableCell align="center" className="profile-table-head-cell">
                    Date
                  </TableCell>
                  <TableCell align="center" className="profile-table-head-cell">
                    Status
                  </TableCell>
                  <TableCell align="center" className="profile-table-head-cell">
                    Total
                  </TableCell>
                  <TableCell align="center" className="profile-table-head-cell">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && (
                  <>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            align="center"
                            className="profile-table-head-cell"
                          >
                            {row.id}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="profile-table-cell"
                          >
                            {moment(row.date_created).format("MMM Do YY")}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="profile-table-cell"
                          >
                            {row.status}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="profile-table-cell"
                          >
                            {row.total}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                //   openDialog(row);
                                history.push(`/quiz/result/${row.id}`);
                              }}
                              className="profile-table-cell"
                            >
                              <Hidden xsDown>View</Hidden>
                              <span
                                style={{
                                  width: 5,
                                  display: "inline-block",
                                }}
                              />
                              <VisibilityIcon className={classes.icon} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableFooter
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "center",
              width: "100%",
            }}
          >
            <TableRow
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "center",
                width: "100%",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "all", value: -1 }]}
                colSpan={10}
                count={tableCount}
                rowsPerPage={rowsPerPage}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                variant="outlined"
                shape="rounded"
                style={{ color: "secondary" }}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Box>
      </Tablet>
    </>
  );
}

export default Profile;
