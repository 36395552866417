import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Backdrop,
  Box,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import "react-circular-progressbar/dist/styles.css";
import { useMediaQuery } from "react-responsive";
// import { MAP } from './map';
import mainProduct from "../assets/img/main_product.svg";
import Ellipse7 from "../assets/img/NEllipse.svg";
import star from "../assets/img/star_home.svg";
import mahimoSvg from "../assets/img/mahimoSvg.svg";
import Axios from "axios";
import { Link } from "react-router-dom";
import "../styles.css";
import CameraLayout from "../components/shared/CameraLayout";
import SideLayout from "../components/quiz/SideLayout.js";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
  bottomGradient: {
    position: "fixed",
    width: "33.625rem",
    height: "33.625rem",
    bottom: "0",
    left: "9rem",
    background: "rgba(255, 187, 88, 0.12)",
    filter: "blur(209px)",
    borderRadius: "50%",
    marginBottom: "-20rem",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },
  topGradientMobile: {
    position: "fixed",
    width: "43.625rem",
    height: "43.625rem",
    top: "-22rem",
    right: "-22rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },

  topGradient: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "-2rem",
    marginLeft: "33rem",
    background: "rgba(225, 187, 88, 0.12)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  Ellipse: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "17rem",
    marginLeft: "43rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  mahimoSvg: {
    position: "absolute",
    top: "34rem",
    marginLeft: "39rem",
    zIndex: 2,
  },
}));

function Result() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    const sendRequest = async () => {
      const response = await Axios.get("https://app.mahimo.co/quiz/");

      setData(response.data.results);
    };
    sendRequest();
  }, []);
  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);
  return (
    <CameraLayout>
      {/* check the screen size */}

      <Desktop>
        <div className={classes.topGradient}></div>
        <div className={classes.Ellipse}></div>
        <div className={classes.mahimoSvg}>
          <img src={mahimoSvg} alt="defualt" zIndex="2" style={{ zIndex: 1 }} />
        </div>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{
              justifyContent: "flex-end",
              padding: "10px",
              marginTop: "-20px",
              marginRight: "40px",
              display: "flex",
            }}
          >
            <img src={star} alt="" style={{ marginBottom: "-32px" }} />
          </GridItem>
        </GridContainer>
        <GridContainer></GridContainer>
        <GridContainer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "start",
            justify: "start",

            justifyContent: "center",
          }}
        >
          {/* first section  percent*/}

          <Grid xs={12} sm={12} md={12} lg={7} style={{ marginTop: "1rem" }}>
            <GridContainer
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                flexDirection: "center",
                alignItems: "center",
                justify: "center",
                width: "70%",
                zIndex: 2,
              }}
            >
              <Typography className="text-home-desktop-take-quiz">
                Take the Quiz!
              </Typography>
              {data &&
                data.map((q, idx) => (
                  <div key={q.slug}>
                    {idx % 2 ? (
                      <>
                        {" "}
                        <Link to={`/quiz/${q.slug}`}>
                          <Button
                            variant="text"
                            className="text-home-desktop-take-quiz-button"
                          >
                            <Typography className="text-home-desktop-take-quiz-button-text">
                              {q.title}
                            </Typography>
                          </Button>
                        </Link>
                        <Link to={`/camera/${q.slug}`}>
                          <Button
                            variant="text"
                            style={{
                              background: "#FAFAFA",
                              marginBottom: "15px",
                            }}
                          >
                            <Typography className="text-home-desktop-take-quiz-button-text">
                              Image Scan and {q.title}
                            </Typography>
                          </Button>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to={`/quiz/${q.slug}`}>
                          <Button
                            variant="text"
                            className="text-home-desktop-take-quiz-button-2"
                          >
                            <Typography className="text-home-desktop-take-quiz-button-text">
                              {q.title}
                            </Typography>
                          </Button>
                        </Link>
                        <Link to={`/camera/${q.slug}`}>
                          <Button
                            variant="text"
                            style={{
                              background: "#FBF7FF",
                              marginBottom: "15px",
                            }}
                          >
                            <Typography className="text-home-desktop-take-quiz-button-text">
                              Image Scan and {q.title}
                            </Typography>
                          </Button>
                        </Link>
                      </>
                    )}
                  </div>
                ))}
            </GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                position: "absolute",
                bottom: "20px",
              }}
            >
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  justifyContent: "left",
                  paddingTop: "0.5rem",
                  alignSelf: "left",
                  alignItems: "left",
                  justify: "left",
                  display: "flex",
                }}
              >
                <a
                  className="a-home-desktop-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mahimo.co/privacy-policy/"
                >
                  Privacy and Policies{" "}
                </a>
                <Typography className="a-home-desktop-policy">|</Typography>
                <a
                  className="a-home-desktop-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mahimo.co/terms-of-use/"
                >
                  Terms of Use{" "}
                </a>
              </Grid>
            </GridItem>
          </Grid>

          {/* second section */}
          <SideLayout />
        </GridContainer>

        <Box className={classes.bottomGradient}></Box>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
      </Desktop>
      {/* Tablet or Mobile */}
      <Tablet>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
        <div className={classes.topGradientMobile}></div>

        <GridContainer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justify: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
          }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            style={{
              justifyContent: "center",

              display: "flex",
            }}
            direction="column"
          >
            <Typography className="text-home-mobile-take-quiz">
              Take the Quiz!
            </Typography>
            <Grid
              xs={12}
              sm={12}
              md={12}
              direction="column"
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <GridItem
                xs={8}
                sm={8}
                md={8}
                direction="column"
                container
                style={{
                  justifyContent: "left",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                }}
              >
                {data &&
                  data.map((q, idx) => (
                    <div key={q.slug}>
                      {idx % 2 ? (
                        <>
                          {" "}
                          <Link
                            to={`/quiz/${q.slug}`}
                            style={{
                              alignSelf: "left",
                            }}
                          >
                            <Button
                              variant="text"
                              fullWidth
                              size="medium"
                              style={{
                                background: "#FBF7FF",
                                marginRight: "20px",
                                marginBottom: "15px",
                              }}
                            >
                              <Typography className="text-home-desktop-take-quiz-button-text">
                                {q.title}
                              </Typography>
                            </Button>
                          </Link>
                          <Link
                            to={`/camera/${q.slug}`}
                            style={{
                              alignSelf: "left",
                            }}
                          >
                            <Button
                              size="medium"
                              fullWidth
                              variant="text"
                              style={{
                                background: "#FAFAFA",
                                marginBottom: "15px",
                              }}
                            >
                              <Typography className="text-home-desktop-take-quiz-button-text">
                                Image Scan and {q.title}
                              </Typography>
                            </Button>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            to={`/quiz/${q.slug}`}
                            style={{
                              alignSelf: "left",
                            }}
                          >
                            <Button
                              size="medium"
                              fullWidth
                              variant="text"
                              style={{
                                background: "#FAFAFA",
                                marginRight: "20px",
                                marginBottom: "15px",
                              }}
                            >
                              <Typography className="text-home-desktop-take-quiz-button-text">
                                {q.title}
                              </Typography>
                            </Button>
                          </Link>
                          <Link
                            to={`/camera/${q.slug}`}
                            style={{
                              alignSelf: "left",
                            }}
                          >
                            <Button
                              size="medium"
                              fullWidth
                              variant="text"
                              style={{
                                background: "#FBF7FF",
                                marginBottom: "15px",
                              }}
                            >
                              <Typography className="text-home-desktop-take-quiz-button-text">
                                Image Scan and {q.title}
                              </Typography>
                            </Button>
                          </Link>
                        </>
                      )}
                    </div>
                  ))}
              </GridItem>
            </Grid>
          </Grid>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              position: "absolute",
              bottom: "20px",
            }}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                justifyContent: "left",
                paddingTop: "0.5rem",
                alignSelf: "left",
                alignItems: "left",
                justify: "left",
                display: "flex",
              }}
            >
              <a
                className="a-home-desktop-policy"
                target="_blank"
                rel="noopener noreferrer"
                href="https://mahimo.co/privacy-policy/"
              >
                Privacy and Policies{" "}
              </a>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "20px",
                  margin: "10px 0px",
                  color: "#828282",
                  alignSelf: "center",
                }}
              >
                |
              </Typography>
              <a
                className="a-home-desktop-policy"
                target="_blank"
                rel="noopener noreferrer"
                href="https://mahimo.co/terms-of-use/"
              >
                Terms of Use{" "}
              </a>
            </Grid>
          </GridItem>
        </GridContainer>
      </Tablet>
    </CameraLayout>
  );
}

export default Result;
