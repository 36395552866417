import React from "react";

import { makeStyles, Typography, Box, Grid } from "@material-ui/core";
import mainProduct from "../../assets/img/main_product.svg";
import Ellipse7 from "../../assets/img/NEllipse.svg";

const useStyles = makeStyles((theme) => ({
  Ellipse7: {
    zIndex: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  Box: {
    width: "17.125rem",
    border: "3px solid #732F88",
    height: "26.625rem",
    borderRadius: "18.063rem",
    zIndex: -1,
  },
  div: {
    position: "relative",
    zIndex: 1,
    marginLeft: "2rem",
  },
  div2: {
    background: "#E7DFEE",
    alignItems: "center",
    width: "17.125rem",
    position: "absolute",
    marginTop: "20px",
    top: "0",
    left: "-18px",
    height: "26.625rem",
    borderRadius: "18.063rem",
    justifyContent: "center",
    display: "flex",
    zIndex: 0,
  },
  divEclipse: {
    position: "relative",
    width: "800px",
    height: "800px",
    right: "-400px",
    marginTop: "-420px",
    padding: "5px",
  },
  text: {
    fontFamily: "Playfair Display",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "53px",

    background:
      "-webkit-linear-gradient( 103.21deg, #1F1F1F 13.3%, #521465 52.42%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    alignSelf: "right",
  },
  divQuestion: {
    left: "80px",
    marginTop: "5px",
    position: "relative",
  },
  img: {
    alignItems: "center",
    alignSelf: "center",
    margin: "auto",
    zIndex: 1,
    objectFit: "fit",
    marginTop: "40px",
  },
}));

const SideLayout = () => {
  const classes = useStyles();
  return (
    <>
      {/* second section */}
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={5}
        style={{
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <div className={classes.div}>
          <Box className={classes.Box} />
          <div className={classes.div2}>
            {/* //180 270 _ 170 174 */}
            <img
              src={mainProduct}
              alt="defualt"
              width="190"
              height="280"
              classes={classes.img}
            />
          </div>

          <div
            style={{
              left: "80px",
              marginTop: "5px",
              position: "relative",
            }}
          >
            <Typography className={classes.text}>
              What are your main skin concerns?
            </Typography>
          </div>
        </div>
      </Grid>

      <div className={classes.divEclipse}>
        <img src={Ellipse7} alt="Ellips" className={classes.Ellipse7} />
      </div>
    </>
  );
};

export default SideLayout;
