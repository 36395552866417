import React, { useState, useContext } from 'react';
import {
  Grid,
  TextField,
  Backdrop,
  CircularProgress,
  SwipeableDrawer,
  Hidden,
  Modal,
  Button,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import AdminLoginLayout from '../components/shared/AdminLogin';
import MainButton from '../components/UIElements/Button';
import Auth from '../components/shared/Auth';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../components/context/auth';
import ForgetPass from '../components/panel/ForgetPass';
import closeIcon from '../assets/img/x.svg';
import closeDrawerIcon from '../assets/img/drawer-close.svg';
import mahimoSvg from '../assets/img/mahimoSvg.svg';
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  rootInput: {
    width: '100%',
    margin: '10px 0',
  },
  forget_password: {
    color: '#000618',
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
  },
  button_box: {
    marginTop: '2rem',
  },
  auth_btn: {
    width: 213,
  },
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  modalBox: {
    background: '#fff',
    width: 480,
    padding: 25,
  },
  modalHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 20,
    color: '#000618',
    marginBottom: 30,
  },
  drawerClose: {
    textAlign: 'center',
  },
  backdrop: {
    zIndex: 99999,
    color: '#fff',
    background:
      'radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )',
  },
}));

const INITIAL_FORM = {
  grant_type: 'password',
  username: '',
  password: '',
};

function Login() {
  const [inputData, setInputData] = useState(INITIAL_FORM);
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [authFailed, setAuthFailed] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { login, accessPermission } = useContext(AuthContext);
  const { state } = useLocation();
  const [infoDrawer, setInfoDrawer] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [userInfo, setUserInfo] = useState();
  if (state) {
    console.log(state);
  }

  const handleAuthClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAuthFailed(false);
  };

  const handleAuthSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAuthSuccess(false);
  };

  const formSubmitHandler = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      const response = await fetch('https://app.mahimo.co/user/admin-login/', {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          grant_type: 'password',
          username: inputData.username,
          password: inputData.password,
          client_id: 'react',
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        setAuthFailed(true);
        throw new Error('Err');
      }

      setAuthSuccess(true);
      login(responseData.access_token);

      if (responseData.groups[0] === 'admins') {
        accessPermission(true);
      } else {
        accessPermission(false);
      }

      setLoading(false);
      if (state && state.formId) {
        history.push(`/quiz/result/${state.formId}`);
      } else {
        history.push('/admin/profile');
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserInfo((preventData) => ({ ...preventData, [name]: value }));
    setInputData((preventData) => ({ ...preventData, [name]: value }));
  };

  return (
    <AdminLoginLayout>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <div id='idSvgLoading'>
          <img src={mahimoSvg} alt='' />
        </div>
        {/* <CircularProgress color='inherit' /> */}
      </Backdrop>
      <Snackbar
        open={authFailed}
        autoHideDuration={6000}
        onClose={handleAuthClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAuthClose} severity='error'>
          Wrong Username or Password!
        </Alert>
      </Snackbar>
      <Snackbar
        open={authSuccess}
        autoHideDuration={6000}
        onClose={handleAuthSuccessClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAuthSuccessClose} severity='success'>
          Login successful! Redirecting...
        </Alert>
      </Snackbar>
      <h1>Welcome to Admin panel</h1>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <TextField
          variant='outlined'
          label='Email'
          type='email'
          name='username'
          inputRef={register({ required: true })}
          className={classes.rootInput}
          onChange={inputChangeHandler}
        />
        <TextField
          variant='outlined'
          label='Password'
          type='password'
          name='password'
          inputRef={register({ required: true })}
          className={classes.rootInput}
          onChange={inputChangeHandler}
        />
        <div style={{ textAlign: 'right' }}>
          <Link
            onClick={() => {
              setInfoDrawer(true);
              setInfoModal(true);
            }}
            className={classes.forget_password}
          >
            <span>Forgot your password?</span>
          </Link>
        </div>
        <Grid container spacing={2} className={classes.button_box}>
          <Grid item md={6}>
            <MainButton
              background
              className={classes.auth_btn}
              variant='contained'
              type='submit'
            >
              Login
            </MainButton>
          </Grid>
        </Grid>
      </form>

      <>
        <Hidden xsDown>
          <Modal open={infoModal} onClose={() => setInfoModal(false)}>
            <div className={classes.modalWrapper}>
              <div className={classes.modalBox}>
                <div className={classes.modalHead}>
                  <span>Recovery account</span>
                  <Button onClick={() => setInfoModal(false)}>
                    <img src={closeIcon} alt='' />
                  </Button>
                </div>
                {
                  <ForgetPass
                    data={userInfo}
                    parentCallback={() => setInfoModal(false)}
                  />
                }
              </div>
            </div>
          </Modal>
        </Hidden>
        <Hidden smUp>
          <SwipeableDrawer
            anchor='bottom'
            open={infoDrawer}
            onClose={() => setInfoDrawer(false)}
            onOpen={() => setInfoDrawer(true)}
          >
            <div className={classes.drawer}>
              <div className={classes.drawerClose}>
                <Button onClick={() => setInfoDrawer(false)}>
                  <img src={closeDrawerIcon} alt='' />
                </Button>
              </div>
              <div className={classes.drawerHead}>
                <span>Recovery account</span>
              </div>
              {
                <ForgetPass
                  data={userInfo}
                  parentCallback={() => setInfoDrawer(false)}
                />
              }
            </div>
          </SwipeableDrawer>
        </Hidden>
      </>
    </AdminLoginLayout>
  );
}

export default Login;
