import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Box,
  Fade,
  Typography,
  Paper,
} from "@material-ui/core";

import { useParams, useHistory } from "react-router-dom";

import { AuthContext } from "../context/auth";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// image mapper
import Desktopselfi from "../../assets/img/desktopselfi.svg";
import ImageMapper from "react-image-mapper";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
    alignSelf: "center",
  },
  bottomGradient: {
    position: "fixed",
    width: "33.625rem",
    height: "33.625rem",
    bottom: "0",
    left: "9rem",
    background: "rgba(255, 187, 88, 0.12)",
    filter: "blur(209px)",
    borderRadius: "50%",
    marginBottom: "-20rem",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },
  topGradientMobile: {
    position: "fixed",
    width: "43.625rem",
    height: "43.625rem",
    top: "-22rem",
    right: "-22rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },

  topGradient: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "-1rem",
    marginLeft: "33rem",
    background: "rgba(225, 187, 88, 0.12)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  Ellipse: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "30rem",
    marginLeft: "43rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
}));

function Result({ ImageSelfi, ImageMap, AIMassage }) {
  const [aiLoading, setAiLoading] = useState(ImageMap ? false : true);

  const [AIResultName, setAiResultName] = useState();
  const [Map, setMap] = useState(ImageMap);

  //image mapper
  const [selectedAreaResult, setSelectedAreaResult] = useState(null);

  const [info, setInfo] = useState(false);

  function clickArea(area) {
    setAiResultName(area.name);
    setSelectedAreaResult(area.rank);
    setInfo(true);
  }

  console.log("result render ai");
  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      {" "}
      <Fade
        in={aiLoading}
        style={{
          transitionDelay: aiLoading ? "800ms" : "0ms",
          position: "absolute",
          marginTop: "256px",
          zIndex: 99,
        }}
        unmountOnExit
      >
        <CircularProgress />
      </Fade>
      <main className="App">
        {AIMassage && <Typography className="ai-text">{AIMassage}</Typography>}
        <ImageMapper
          src={ImageSelfi}
          map={Map}
          height={400}
          width={400}
          imgWidth={512}
          onClick={(area) => clickArea(area)}
          // onMouseEnter={(area) => enterArea(area)}
          // onMouseLeave={() => leaveArea()}
          fillColor="rgba(0,0,0,0)"
          strokeColor="rgba(255, 255, 255, 1)"
          lineWidth={4}
          active={false}
        />
      </main>
      {info && (
        <>
          {/* list the results */}
          <div
            style={{
              width: "100%",
              overflow: "auto",
              display: "flex",
              marginTop: "1.3rem",
            }}
          >
            {Map &&
              Map &&
              Map.areas.map((idx) => (
                <Paper
                  elevation={0}
                  style={{
                    height: "100%",
                    width: "100%",
                    background: "transparent",
                  }}
                >
                  <>
                    <Box
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      margin=" 20px 0px"
                      display={"flex"}
                      width="100%"
                    >
                      <div
                        style={{
                          width: 80,
                          height: 80,
                          margin: "0px 1rem",
                        }}
                      >
                        <CircularProgressbar
                          value={Math.round(parseInt(idx.percentage))}
                          strokeWidth={12}
                          styles={buildStyles({
                            textSize: "18px",
                            textColor:
                              AIResultName === idx.name
                                ? "#A146BD"
                                : "rgba(130, 130, 130, 1)",
                            pathColor:
                              AIResultName === idx.name
                                ? "#A146BD"
                                : "rgba(130, 130, 130, 1)",
                          })}
                          text={`${Math.round(parseInt(idx.percentage))}%`}
                        />
                      </div>

                      <Box
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        display={"flex"}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight:
                              AIResultName === idx.name ? "bold" : "500",
                            fontSize: "calc(4px + 10px)",
                            display: "flex",
                            alignSelf: "left",

                            color:
                              AIResultName === idx.name
                                ? "#76288a"
                                : "rgba(130, 130, 130, 1)",
                          }}
                        >
                          {idx.name === "Skin oil rank"
                            ? "Skin oil"
                            : idx.name === "Under eye value"
                            ? "Under eye"
                            : idx.name === "Under eye area"
                            ? "around eyes"
                            : idx.name}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                </Paper>
              ))}
          </div>

          <div className="list-result-mobile-div-2">
            {/* to show the data */}
            <Typography className="div-ai-text">
              {selectedAreaResult && selectedAreaResult
                ? selectedAreaResult
                : Map !== null
                ? Map.areas[0].rank
                : "No results found"}
            </Typography>
          </div>
        </>
      )}
    </Grid>
  );
}
export default Result;
