import React, { useState } from "react";
import { Grid, TextField, Backdrop, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Auth from "../components/shared/Auth";
import Layout from "../components/shared/Layout";
import MainButton from "../components/UIElements/Button";
import { useForm } from "react-hook-form";
import mahimoSvg from "../assets/img/mahimoSvg.svg";
const queryString = require("query-string");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  rootInput: {
    width: "100%",
    margin: "10px 0",
  },
  loginBox: {
    padding: "1rem 0",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "-0.25px",
  },
  loginText: {
    marginLeft: "3px",
    color: "#76288A",
  },
  button_box: {
    marginTop: "2rem",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
  },
}));

const INITIAL_FORM = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
};

function Signup(props) {
  const [inputData, setInputData] = useState(INITIAL_FORM);
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [signupFailed, setSignupFailed] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [errorMassage, setErrorMassage] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  const { form } = queryString.parse(props.location.search);

  const handleAuthClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSignupFailed(false);
  };

  const handleAuthSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSignupSuccess(false);
  };

  const formSubmitHandler = async (e) => {
    let baseUrl;

    if (state && state.formId) {
      baseUrl = `https://app.mahimo.co/user/profile/${
        state && state.formId && `?form=${state.formId}`
      }`;
    } else {
      baseUrl = "https://app.mahimo.co/user/profile/";
    }

    try {
      setLoading(true);

      const response = await fetch(
        baseUrl,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputData),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        if (responseData.password !== undefined)
          setErrorMassage(responseData.password[0]);
        else if (responseData.email !== undefined)
          setErrorMassage(responseData.email[0]);
        setSignupFailed(true);
        throw new Error("Err", response);
      }

      setLoading(false);
      setSignupSuccess(true);
      setTimeout(() => {
        history.push({
          pathname: "/login",
          state: {
            formId: state && state.formId,
          },
        });
      }, 2000);
    } catch (err) {
      setLoading(false);
      console.log("ERROR");
      console.log(err);
    }
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;

    setInputData((preventData) => ({ ...preventData, [name]: value }));
  };

  return (
    <Layout>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <div id="idSvgLoading">
          <img src={mahimoSvg} alt="" />
        </div>
        {/* <CircularProgress color='inherit' /> */}
      </Backdrop>
      <Snackbar
        open={signupFailed}
        autoHideDuration={6000}
        onClose={handleAuthClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {errorMassage ? (
          <Alert onClose={handleAuthClose} severity="error">
            {errorMassage}
          </Alert>
        ) : (
          <Alert onClose={handleAuthClose} severity="error">
            Sign up failed! Please try again.
          </Alert>
        )}
      </Snackbar>
      <Snackbar
        open={signupSuccess}
        autoHideDuration={6000}
        onClose={handleAuthSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAuthSuccessClose} severity="success">
          Signup successful! Please login...
        </Alert>
      </Snackbar>
      <Auth
        signup
        headText="Sign up to Mahimo"
        googleText="Sign up with Google"
        facebookText="Sign up with Facebook"
        formId={state && state.formId && state.formId}
      >
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <TextField
            variant="outlined"
            label="Email Address"
            type="email"
            name="email"
            inputRef={register({ required: true })}
            className={classes.rootInput}
            onChange={inputChangeHandler}
          />
          <TextField
            variant="outlined"
            label="First Name"
            type="text"
            name="first_name"
            inputRef={register({ required: true })}
            className={classes.rootInput}
            onChange={inputChangeHandler}
          />
          <TextField
            variant="outlined"
            label="Last Name"
            type="text"
            name="last_name"
            inputRef={register({ required: true })}
            className={classes.rootInput}
            onChange={inputChangeHandler}
          />
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            name="password"
            inputRef={register({ required: true })}
            className={classes.rootInput}
            onChange={inputChangeHandler}
          />
          <div className={classes.loginBox}>
            <span>Already a member?</span>
            <Link
              to={{
                pathname: "/login",
                state: {
                  formId: state && state.formId,
                },
              }}
              className={classes.loginText}
            >
              <span>Log in and continue</span>
            </Link>
          </div>
          <Grid container spacing={2} className={classes.button_box}>
            <Grid item xs={12}>
              <MainButton
                background
                className={classes.auth_btn}
                variant="contained"
                fullWidth
                type="submit"
              >
                Sign Up
              </MainButton>
            </Grid>
          </Grid>
        </form>
      </Auth>
    </Layout>
  );
}

export default Signup;
