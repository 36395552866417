import React from "react";

import { Typography } from "@material-ui/core";
import GridItem from "../Grid/GridItem.js";

function Index() {
  return (
    <GridItem
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2rem",
        justifyContent: "center",
      }}
    >
      <Typography className="a-home-desktop-policy">
        By clicking the button above, you agree to our
      </Typography>
      <a
        className="a-home-desktop-policy"
        target="_blank"
        rel="noopener noreferrer"
        href="https://mahimo.co/terms-of-use/"
      >
        Terms of Use{" "}
      </a>
      <a
        className="a-home-desktop-policy"
        target="_blank"
        rel="noopener noreferrer"
        href="https://mahimo.co/privacy-policy/"
      >
        , Privacy and Policies,
      </a>
    </GridItem>
  );
}

export default Index;
