import React from "react";

import { Typography, Grid } from "@material-ui/core";
import GridItem from "../Grid/GridItem.js";

function Index() {
  return (
    <GridItem
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{
        display: "flex",
      }}
    >
      <Grid
        xs={8}
        sm={8}
        md={8}
        lg={8}
        style={{
          justifyContent: "left",
          paddingTop: "0.5rem",
          alignSelf: "left",
          alignItems: "left",
          justify: "left",
          display: "flex",
        }}
      >
        <a
          className="a-home-desktop-policy"
          target="_blank"
          rel="noopener noreferrer"
          href="https://mahimo.co/privacy-policy/"
        >
          Privacy and Policies{" "}
        </a>
        <Typography className="a-home-desktop-policy">|</Typography>
        <a
          className="a-home-desktop-policy"
          target="_blank"
          rel="noopener noreferrer"
          href="https://mahimo.co/terms-of-use/"
        >
          Terms of Use{" "}
        </a>
      </Grid>
      <Grid
        xs={4}
        sm={4}
        md={4}
        lg={4}
        style={{
          justifyContent: "center",
          paddingTop: "0.5rem",
          alignSelf: "center",
          alignItems: "center",
          justify: "center",
        }}
      >
        <a
          className="a-home-desktop-policy"
          target="_blank"
          rel="noopener noreferrer"
          href="https://mahimo.co/privacy-policy/"
        >
          By clicking the button above, and Privacy and Policies,
        </a>
      </Grid>
    </GridItem>
  );
}

export default Index;
