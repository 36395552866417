import React from "react";
import { makeStyles, Typography, Paper, Box } from "@material-ui/core";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "../../pages/profile/profile.css";
const useStyles = makeStyles((theme) => ({
  grid: {
    height: "100%",
    width: "100%",
    background: "transparent",
    display: "grid",
    gridTemplateColumns: "minmax(min-content, 1fr) minmax(min-content,1fr)",
    ["@media only screen and (max-width: 400px)"]: {
      /* For mobile phones: */
      gridTemplateColumns: "minmax(min-content, 1fr)",
    },
    rowGap: "12px",
    columnGap: "12px",
  },
}));

function BoxesMobile({ mainResult }) {
  const classes = useStyles();

  return (
    <>
      <div className="dashboard-mobile-second-box">
        <Paper elevation={0} className={classes.grid}>
          {/* first box */}
          {mainResult && (
            <>
              <Box className="dashboard-mobile-quiz-box-1">
                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  display={"flex"}
                  width="100%"
                >
                  <Box
                    width="32%"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 50,
                        height: 60,
                        padding: "1px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgressbar
                        value={Math.round(
                          parseInt(mainResult[0] ? mainResult[0].percentage : 0)
                        )}
                        strokeWidth={12}
                        text={mainResult[0] ? mainResult[0].percentage : "0%"}
                        styles={buildStyles({
                          textSize: "22px",
                          textColor: "#11263C",
                          pathColor: "#55A55E",
                        })}
                      ></CircularProgressbar>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginLeft: "0rem",
                    }}
                    width="68%"
                  >
                    <Typography className="dashboard-mobile-quiz-box-1-text">
                      {mainResult[0] ? mainResult[0].name : "Take a Quiz!"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* second box */}
              <Box className="dashboard-mobile-quiz-box-2">
                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  display={"flex"}
                  width="100%"
                >
                  <Box
                    width="32%"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 50,
                        height: 60,
                        padding: "1px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgressbar
                        value={Math.round(
                          parseInt(mainResult[1] ? mainResult[1].percentage : 0)
                        )}
                        strokeWidth={12}
                        text={mainResult[1] ? mainResult[1].percentage : "0%"}
                        styles={buildStyles({
                          textSize: "22px",
                          textColor: "#11263C",
                          pathColor: "#5E5498",
                        })}
                      ></CircularProgressbar>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginLeft: "0rem",
                    }}
                    width="68%"
                  >
                    <Typography className="dashboard-mobile-quiz-box-2-text">
                      {mainResult[1] ? mainResult[1].name : "Take a Quiz!"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* third box */}
              <Box className="dashboard-mobile-quiz-box-3">
                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  display={"flex"}
                  width="100%"
                >
                  <Box
                    width="32%"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 50,
                        height: 60,
                        padding: "1px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgressbar
                        value={Math.round(
                          parseInt(mainResult[2] ? mainResult[2].percentage : 0)
                        )}
                        strokeWidth={12}
                        text={mainResult[2] ? mainResult[2].percentage : "0%"}
                        styles={buildStyles({
                          textSize: "22px",

                          textColor: "#11263C",
                          pathColor: "#9B715D",
                        })}
                      ></CircularProgressbar>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginLeft: "0rem",
                    }}
                    width="68%"
                  >
                    <Typography className="dashboard-mobile-quiz-box-3-text">
                      {mainResult[2] ? mainResult[2].name : "Take a Quiz!"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* fourth box */}
              <Box className="dashboard-mobile-quiz-box-4">
                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  display={"flex"}
                  width="100%"
                >
                  <Box
                    width="32%"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 50,
                        height: 60,
                        padding: "1px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgressbar
                        value={Math.round(
                          parseInt(mainResult[3] ? mainResult[3].percentage : 0)
                        )}
                        strokeWidth={12}
                        text={mainResult[3] ? mainResult[3].percentage : "0%"}
                        styles={buildStyles({
                          textSize: "22px",
                          textColor: "#11263C",
                          pathColor: "#B08A01",
                        })}
                      ></CircularProgressbar>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginLeft: "0rem",
                    }}
                    width="68%"
                  >
                    <Typography className="dashboard-mobile-quiz-box-4-text">
                      {mainResult[3] ? mainResult[3].name : "Take a Quiz!"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Paper>
      </div>
    </>
  );
}

export default BoxesMobile;
