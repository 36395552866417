import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  TextField,
  makeStyles,
  Radio,
  RadioGroup,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import arrowLeft from "../../assets/img/arrow-left.svg";
import arrowRight from "../../assets/img/arrow-right.svg";
import { useHistory } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "../../pages/Quiz/RadialSeparators";
import "./Questions.css";
let PageSize = 1;

const useStyles = makeStyles((theme) => ({
  buttonBox: {
    marginTop: "2rem",
    display: "flex",
  },
}));

const QuestionsWithAnswers = ({ data, token, currentPage, setCurrentPage }) => {
  const [formData, setFormData] = useState({});
  const classes = useStyles();
  //caculate total questions
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const paginatedQuestions = data.slice(firstPageIndex, lastPageIndex);
  const history = useHistory();
  //check if location availble
  const [location, setLocation] = useState(false);

  const inputRef = useRef(
    paginatedQuestions[0] ? paginatedQuestions[0].answer : ""
  );

  const handlePrev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  const handleNext = async () => {
    if (inputRef.current && !inputRef.current.value) {
      inputRef.current.focus();
      return;
    }

    try {
      await axios.patch(
        `https://app.mahimo.co/user/profile/questions/answers/${paginatedQuestions[0].id}/`,
        {
          question: formData.question,
          selected_choice: formData.selected_choice,
          answer: formData.value ? formData.value : "",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (currentPage === data.length) {
        if (location) {
          history.push("/profile/location");
        } else {
          history.push("/profile");
        }
      }
      setCurrentPage(currentPage + 1);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          "https://app.mahimo.co/user/get_user_location/",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        if (!response.ok) {
          setLocation(true);
          throw new Error("ERORRR");
        } else {
          setLocation(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    sendRequest();
  }, [token]);

  const inputChangeHandler = (e, id) => {
    const value = e.target.value;
    setFormData({
      question: id,
      answer: value,
      selected_choice: [],
    });
  };

  const selectChangeHandler = (e, id) => {
    const value = e.target.value;
    setFormData({
      question: id,
      selected_choice: [+value],
      answer: "",
    });
  };

  return (
    <>
      {data.length > 0 ? (
        <Typography className="question-number">
          QUESTION {currentPage} OF {data.length} | USER QUESTION
        </Typography>
      ) : (
        <Typography className="question-number">
          FIRST QUESTION | USER QUESTION
        </Typography>
      )}
      <div className="div-start">
        {paginatedQuestions &&
          paginatedQuestions.map((q, idx) => (
            <div key={q.id}>
              <Typography className="question-objects">
                {q.question_obj.question}
              </Typography>

              {q.question_obj.question_type === 0 && (
                <TextField
                  type="text"
                  label="Your answer"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  defaultValue={q.answer}
                  onChange={inputChangeHandler}
                  inputRef={inputRef}
                />
              )}

              {q.question_obj.question_type === 1 && (
                <>
                  <RadioGroup
                    name={`radio_${idx}`}
                    onChange={(e) => selectChangeHandler(e, q.question_obj.id)}
                  >
                    {q.question_obj.choices.map((c) => (
                      <FormControl
                        component="fieldset"
                        className="form-control"
                        key={c.id}
                      >
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                style={{
                                  marginRight: "1rem",
                                }}
                              />
                            }
                            value={c.id.toString()}
                            label={c.choice}
                            labelPlacement="start"
                            name={`radio_${idx}`}
                            className="form-control-label"
                          />
                        </FormGroup>
                      </FormControl>
                    ))}
                  </RadioGroup>
                </>
              )}
            </div>
          ))}
        <div className={classes.buttonBox}>
          <Box
            onClick={handlePrev}
            className="box-button"
            style={{ height: 40, width: 40 }}
          >
            <img
              src={arrowLeft}
              style={{ position: "relative", alignSelf: "center" }}
              alt=""
            />
          </Box>
          <div
            style={{
              width: 55,
              height: 55,
              display: "flex",
              justifyContent: "center",
              marginRight: 20,
            }}
          >
            <CircularProgressbarWithChildren
              value={
                data.length === 0
                  ? currentPage
                  : (currentPage / data.length) * 100
              }
              strokeWidth={6}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: "#732F88",
              })}
            >
              <RadialSeparators
                count={data.length}
                style={{
                  background: "#fff",
                  width: "2px",
                  // This needs to be equal to props.strokeWidth
                  height: `${10}%`,
                }}
              />
              <Box
                style={{
                  height: 40,
                  width: 40,

                  borderRadius: 360,
                  background: "#732F88",
                  justifyContent: "center",
                  alignSelf: "center",
                  display: "flex",
                }}
              >
                <IconButton variant="text" onClick={handleNext}>
                  <img
                    src={arrowRight}
                    style={{
                      position: "relative",
                      alignSelf: "center",
                    }}
                    alt=""
                  />
                </IconButton>
              </Box>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionsWithAnswers;
