import React, { useContext } from 'react';
import { Grid, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AuthImage from '../../assets/img/auth-image.svg';
import GoogleIcon from '../../assets/img/Google.svg';
import FacebookIcon from '../../assets/img/Facebook.svg';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { AuthContext } from '../context/auth';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seperator: {
    textAlign: 'center',
    padding: '1.5rem 0',
    color: '#C2C6CC',
    fontSize: '18px',
    lineHeight: '28px',
    width: '90%',
  },
  social_auth_span: {
    marginLeft: '7px',
    color: '#000618',
    fontSize: '16px',
    letterSpacing: '-0.75px',
  },

  forget_password: {
    color: '#000618',
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
  },
  button_box: {
    marginTop: '2rem',
  },
  auth_btn: {
    width: 213,
  },
  authImage: {
    width: '90%',
    height: '100%',
  },
}));

function Auth(props) {
  const { login } = useContext(AuthContext);
  const history = useHistory();

  const responseGoogle = async (response) => {
    let baseUrl;

    if (props.formId) {
      baseUrl = `https://app.mahimo.co/user/google/signin/?form=${props.formId}`;
    } else {
      baseUrl = 'https://app.mahimo.co/user/google/signin/';
    }

    try {
      const res = await axios.post(baseUrl, {
        id_token: response.tokenObj.id_token,
      });
      login(res.data.access_token);
      if (props.formId) {
        history.push(`/quiz/result/${props.formId}`);
      } else {
        history.push('/profile');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const responseFacebook = async (response) => {
    let baseUrl;
    if (props.formId) {
      baseUrl = `https://app.mahimo.co/user/facebook/signin/?form=${props.formId}`;
    } else {
      baseUrl = 'https://app.mahimo.co/user/facebook/signin/';
    }
    try {
      const res = await axios.post(baseUrl, {
        access_token: response.accessToken,
      });
      login(res.data.access_token);
      if (props.formId) {
        history.push(`/quiz/result/${props.formId}`);
      } else {
        history.push('/profile');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Hidden mdDown>
        <Grid item lg={6} md={12} xs={12}>
          <div style={{ height: '100%' }}>
            <img
              src={AuthImage}
              alt='authentication'
              className={classes.authImage}
            />
          </div>
        </Grid>
      </Hidden>
      <Grid item lg={6} md={12} xs={12}>
        <div style={{ padding: '0 10px' }}>
          <div className='heading'>
            <span className='heading-name'>{props.headText}</span>
            <div className='heading-bg'></div>
            <h2 className='heading-title'>{props.headTitle}</h2>
          </div>
          <div>{props.children}</div>
        </div>
      </Grid>
    </Grid>
  );
}

export default Auth;
