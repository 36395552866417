import React from 'react';
import Layout from './Layout';

import { Grid } from '@material-ui/core';

const CameraLayout = (props) => {
  return (
    <Layout>
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CameraLayout;
