import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    background: (props) => props.background && '#76288A',
    width: (props) => props.block && '100%',
    color: (props) => props.background && '#fff',
    textTransform: 'none',
    height: '48px',
    fontSize: '18px',
    fontWeight: 400,
    letterSpacing: '-0.75px',
  },
}))

function MainButton(props) {
  const classes = useStyles(props)

  return (
    <Button
      className={`${classes.root} ${props.className}`}
      variant={props.variant}
      fullWidth={props.fullWidth}
      href={props.href}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      ref={props.ref}
    >
      {props.children}
    </Button>
  )
}

export default MainButton
