import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Box,
  Fade,
  Typography,
  Paper,
} from "@material-ui/core";

import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../components/context/auth";
import GridContainer from "../components/Grid/GridContainer.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useMediaQuery } from "react-responsive";
// image mapper
import Desktopselfi from "../assets/img/desktopselfi.svg";
import ImageMapper from "react-image-mapper";
import CameraLayout from "../components/shared/CameraLayout";
import mahimoSvg from "../assets/img/mahimoSvg.svg";
import DesktopProduct from "../components/result/desktopProduct.js";
import DesktopDefualtProduct from "../components/result/desktopDefualtProduct";
import MobileDefualtProduct from "../components/result/mobileDefualtProduct";
import MobileDoctor from "../components/result/mobileDoctor.js";
import MobileProduct from "../components/result/mobileProduct";
import MobileResult from "../components/result/mobileResult";
import DesktopResult from "../components/result/desktopResult.js";
import DesktopUsername from "../components/result/desktopUsername.js";
import MobileUsername from "../components/result/mobileUsername";
import MobileAi from "../components/result/mobileImageMap";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    background:
      "radial-gradient(circle at center, rgba(113, 48, 177, 0.09), #888 )",
    alignSelf: "center",
  },
  bottomGradient: {
    position: "fixed",
    width: "33.625rem",
    height: "33.625rem",
    bottom: "0",
    left: "9rem",
    background: "rgba(255, 187, 88, 0.12)",
    filter: "blur(209px)",
    borderRadius: "50%",
    marginBottom: "-20rem",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },
  topGradientMobile: {
    position: "fixed",
    width: "43.625rem",
    height: "43.625rem",
    top: "-22rem",
    right: "-22rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
    zIndex: -1,
    borderTopLeftRadius: "17rem",
    bordertoprightradius: "17rem",
  },

  topGradient: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "-1rem",
    marginLeft: "33rem",
    background: "rgba(225, 187, 88, 0.12)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
  Ellipse: {
    position: "absolute",
    width: "33.625rem",
    height: "33.625rem",
    top: "30rem",
    marginLeft: "43rem",
    background: "rgba(113, 48, 177, 0.07)",
    filter: "blur(167px)",
    borderRadius: "50%",
  },
}));

function Result() {
  const [loading, setLoading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [products, setProducts] = useState();
  const [mainResult, setMainResult] = useState();
  const [defaultProduct, setDefaultProduct] = useState();
  const [ImageSelfi, setImageSelfi] = useState(Desktopselfi);
  const [doctorNote, setDoctorNote] = useState("No comment");
  const [doctorPanel, setDoctorPanel] = useState(false);
  const [ImageMap, setImageMap] = useState(null);
  const [AIMassage, setAIMassage] = useState(null);
  const [AIResultName, setAiResultName] = useState();
  const [Map, setMap] = useState();
  const classes = useStyles();
  const { form } = useParams();
  const { token } = useContext(AuthContext);

  const history = useHistory();
  const [userProfileQuestionsAnswers, setUserProfileQuestionsAnswers] =
    useState();
  //image mapper
  const [selectedAreaResult, setSelectedAreaResult] = useState(null);

  const [info, setInfo] = useState(false);

  function clickArea(area) {
    setAiResultName(area.name);
    setSelectedAreaResult(area.rank);
    setInfo(true);
  }

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setLoading(true);

        const res = await axios.get(
          "https://app.mahimo.co/user/profile/questions/answers/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.data.count === 0) {
          history.push("/profile/questions/", {
            form,
          });
        } else {
          const respons = await axios.get(
            `https://app.mahimo.co/quiz/user_result/${form}/`,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );

          setProducts(respons.data.Products[0].product);
          //set mainResult
          if (respons.data.Disorders.length > 0) {
            setMainResult(respons.data.Disorders);
          }
          //set Default suggestions
          setDefaultProduct(respons.data["Default Suggestions"]);

          if (respons.data["User Image"] !== null) {
            setImageSelfi(`https://app.mahimo.co${respons.data["User Image"]}`);
          }
          //personal questions
          if (respons.data["User Profile Questions Answers"].length > 0) {
            setUserProfileQuestionsAnswers(
              respons.data["User Profile Questions Answers"]
            );
          }

          if (respons.data["Doctor Note"].note !== null) {
            setDoctorNote(respons.data["Doctor Note"].note);
          }
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    // if (isMountedRef.current) {
    // <-- check if still mounted
    sendRequest();
    if (!ImageMap) {
      AiRequest();
    }
    // }
  }, [form, token]);

  //Ai request
  //load quiz list
  const AiRequest = useCallback(async () => {
    try {
      setAiLoading(true);

      const response = await fetch(
        `https://app.mahimo.co/quiz/ai/process/v2/`,
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ form_id: form }),
        }
      );

      if (!response.ok) {
        const responseData = await response.json();
        setAiLoading(false);

        setAIMassage(responseData.message);
        throw new Error("ERORRR");
      } else {
        if (response.message !== undefined) {
          setAiLoading(false);

          throw new Error("ERORRR");
        } else {
          const responseData = await response.json();

          var result = [];
          for (var i in responseData) {
            result.push({
              id: i,
              class: i,
              name: responseData[i].disorder_name,
              rank: responseData[i].disorder_description,
              percentage: responseData[i].rank
                ? responseData[i].rank
                : isNaN(responseData[i].disorder_description[0])
                ? 0
                : responseData[i].disorder_description[0],
              shape: "circle",
              coords: [responseData[i].points[1], responseData[i].points[0], 7],

              preFillColor: "#E7DFEE",
            });
          }

          setAiResultName(result[0].name);
          const map = {
            id: "map",
            name: "my-map",

            areas: result,
          };

          setImageMap(map);

          setAiLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setAiLoading(false);
    }
  }, [form, token]);
  //random color
  // function get_color(name) {
  //   // var r = function () {
  //   //   return Math.floor(Math.random() * 256);
  //   // };
  //   // return 'rgb(' + r() + ',' + r() + ',' + r() + ')';
  //   switch (name) {
  //     case 'Skin Regeneration':
  //       return 'darkred';
  //     case 'Hydration':
  //       return 'green';
  //     case 'Oxidative Stress':
  //       return 'olive';
  //     case 'Skin Elasticity':
  //       return 'indigo';
  //     case 'Hyaluronic Acid':
  //       return 'Gold';
  //     default:
  //       return;
  //   }
  // }

  //check the screen size
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  const memoized = useMemo(() => {
    if (ImageMap) setMap(ImageMap);
  }, [ImageMap]);

  return (
    <CameraLayout>
      {/* check the screen size */}
      <Desktop>
        <div className={classes.topGradient}></div>
        <div className={classes.Ellipse}></div>
        <DesktopUsername
          userProfileQuestionsAnswers={userProfileQuestionsAnswers}
        />
        <GridContainer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "start",
            justify: "start",

            justifyContent: "center",
          }}
        >
          {/* first section  percent*/}
          <DesktopResult mainResult={mainResult} />
          {/* second section */}
          <DesktopDefualtProduct defaultProduct={defaultProduct} />

          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            spacing={0}
            style={{
              display: "flex",
              justifyContent: "between",
              zIndex: 2,
              border: "1px solid gray",
              borderRadius: "5px",
              background: "#fff",
            }}
          >
            <Grid
              xs={6}
              sm={6}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
              }}
            >
              <Fade
                in={aiLoading}
                style={{
                  transitionDelay: loading ? "800ms" : "0ms",
                  position: "absolute",
                  marginTop: "256px",
                  zIndex: 99,
                }}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
              <main className="App">
                {AIMassage && (
                  <Typography className="ai-text">{AIMassage}</Typography>
                )}

                <ImageMapper
                  src={ImageSelfi}
                  map={Map}
                  height={512}
                  width={512}
                  onClick={(area) => clickArea(area)}
                  // onMouseEnter={(area) => enterArea(area)}
                  // onMouseLeave={() => leaveArea()}
                  fillColor="rgba(0,0,0,0)"
                  strokeColor="rgba(255, 255, 255, 1)"
                  lineWidth={4}
                  style={{
                    PointerEvent: "none",
                  }}
                />
              </main>
            </Grid>
            <Grid
              xs={6}
              sm={6}
              md={6}
              lg={6}
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                zIndex: 2,
                background: "#fff",
                marginLeft: "-1rem",
                paddingRight: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    setDoctorPanel(false);
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Playfair Display",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "32px",
                      background: !doctorPanel
                        ? "-webkit-linear-gradient( 103.21deg, #1F1F1F 13.3%, #521465 52.42%)"
                        : "rgba(130, 130, 130, 1)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    AI Result
                  </Typography>
                </Button>
                <Button
                  variant="text"
                  onClick={() => {
                    setDoctorPanel(true);
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Playfair Display",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "32px",
                      background: doctorPanel
                        ? "-webkit-linear-gradient( 103.21deg, #1F1F1F 13.3%, #521465 52.42%)"
                        : "rgba(130, 130, 130, 1)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Our Dermatologist Note
                  </Typography>
                </Button>
              </div>
              {!doctorPanel ? (
                <>
                  {/* list the results */}
                  <div
                    style={{
                      width: "100%",
                      overflow: "auto",
                      display: "flex",
                      marginTop: "1.3rem",
                    }}
                  >
                    {ImageMap &&
                      ImageMap.areas &&
                      ImageMap.areas.map((idx) => (
                        <Paper
                          elevation={0}
                          style={{
                            height: "100%",
                            width: "100%",
                            background: "transparent",
                          }}
                        >
                          <>
                            <Box
                              alignItems="center"
                              justifyContent="center"
                              flexDirection="column"
                              margin=" 20px 0px"
                              display={"flex"}
                              width="100%"
                            >
                              <div
                                style={{
                                  width: 80,
                                  height: 80,
                                  margin: "0px 1rem",
                                }}
                              >
                                <CircularProgressbar
                                  value={Math.round(parseInt(idx.percentage))}
                                  strokeWidth={12}
                                  styles={buildStyles({
                                    textSize: "18px",
                                    textColor:
                                      AIResultName === idx.name
                                        ? "#A146BD"
                                        : "rgba(130, 130, 130, 1)",
                                    pathColor:
                                      AIResultName === idx.name
                                        ? "#A146BD"
                                        : "rgba(130, 130, 130, 1)",
                                  })}
                                  text={`${Math.round(
                                    parseInt(idx.percentage)
                                  )}%`}
                                />
                              </div>

                              <Box
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                display={"flex"}
                              >
                                <Typography
                                  style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight:
                                      AIResultName === idx.name
                                        ? "bold"
                                        : "500",
                                    fontSize: "calc(4px + 10px)",
                                    display: "flex",
                                    alignSelf: "left",

                                    color:
                                      AIResultName === idx.name
                                        ? "#76288a"
                                        : "rgba(130, 130, 130, 1)",
                                  }}
                                >
                                  {idx.name === "Skin oil rank"
                                    ? "Skin oil"
                                    : idx.name === "Under eye value"
                                    ? "Under eye"
                                    : idx.name === "Under eye area"
                                    ? "around eyes"
                                    : idx.name}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        </Paper>
                      ))}
                  </div>

                  <div className="div-ai">
                    {/* to show the data */}
                    <Typography className="div-ai-text">
                      {selectedAreaResult && selectedAreaResult
                        ? selectedAreaResult
                        : ImageMap !== null
                        ? ImageMap.areas[0].rank
                        : "No results found"}
                    </Typography>
                  </div>
                </>
              ) : (
                <Box
                  alignItems="start"
                  display="flex"
                  width="100%"
                  minHeight="390px"
                  justifyContent="start"
                  flexDirection="column"
                  marginTop="2rem"
                  paddingTop="2rem"
                  style={{
                    boxSizing: "border-box",
                    background: "#e7dfee",
                    padding: "1rem",
                  }}
                >
                  <Typography className="div-ai-text">{doctorNote}</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          {/* desktop Product */}
          <DesktopProduct products={products} defaultProduct={defaultProduct} />
        </GridContainer>
        <Box className={classes.bottomGradient}></Box>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
      </Desktop>
      {/* Tablet or Mobile */}
      <Tablet>
        <div className={classes.topGradientMobile}></div>
        <MobileUsername
          userProfileQuestionsAnswers={userProfileQuestionsAnswers}
        />
        <GridContainer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justify: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            display: "flex",
            flexDirection: "center",
          }}
        >
          {/* second section */}
          <MobileDefualtProduct defaultProduct={defaultProduct} />

          {/* first section  percent*/}
          <MobileResult mainResult={mainResult} />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "5rem",
            }}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              {" "}
              <Fade
                in={aiLoading}
                style={{
                  transitionDelay: loading ? "800ms" : "0ms",
                  position: "absolute",
                  marginTop: "256px",
                  zIndex: 99,
                }}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
              <main className="App">
                {AIMassage && (
                  <Typography className="ai-text">{AIMassage}</Typography>
                )}
                <ImageMapper
                  src={ImageSelfi}
                  map={Map}
                  height={400}
                  width={400}
                  imgWidth={512}
                  onClick={(area) => clickArea(area)}
                  // onMouseEnter={(area) => enterArea(area)}
                  // onMouseLeave={() => leaveArea()}
                  fillColor="rgba(0,0,0,0)"
                  strokeColor="rgba(255, 255, 255, 1)"
                  lineWidth={4}
                  active={false}
                />
              </main>
              {info && (
                <>
                  {/* list the results */}
                  <div
                    style={{
                      width: "100%",
                      overflow: "auto",
                      display: "flex",
                      marginTop: "1.3rem",
                    }}
                  >
                    {ImageMap &&
                      ImageMap.areas &&
                      ImageMap.areas.map((idx) => (
                        <Paper
                          elevation={0}
                          style={{
                            height: "100%",
                            width: "100%",
                            background: "transparent",
                          }}
                        >
                          <>
                            <Box
                              alignItems="center"
                              justifyContent="center"
                              flexDirection="column"
                              margin=" 20px 0px"
                              display={"flex"}
                              width="100%"
                            >
                              <div
                                style={{
                                  width: 80,
                                  height: 80,
                                  margin: "0px 1rem",
                                }}
                              >
                                <CircularProgressbar
                                  value={Math.round(parseInt(idx.percentage))}
                                  strokeWidth={12}
                                  styles={buildStyles({
                                    textSize: "18px",
                                    textColor:
                                      AIResultName === idx.name
                                        ? "#A146BD"
                                        : "rgba(130, 130, 130, 1)",
                                    pathColor:
                                      AIResultName === idx.name
                                        ? "#A146BD"
                                        : "rgba(130, 130, 130, 1)",
                                  })}
                                  text={`${Math.round(
                                    parseInt(idx.percentage)
                                  )}%`}
                                />
                              </div>

                              <Box
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                display={"flex"}
                              >
                                <Typography
                                  style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight:
                                      AIResultName === idx.name
                                        ? "bold"
                                        : "500",
                                    fontSize: "calc(4px + 10px)",
                                    display: "flex",
                                    alignSelf: "left",

                                    color:
                                      AIResultName === idx.name
                                        ? "#76288a"
                                        : "rgba(130, 130, 130, 1)",
                                  }}
                                >
                                  {idx.name === "Skin oil rank"
                                    ? "Skin oil"
                                    : idx.name === "Under eye value"
                                    ? "Under eye"
                                    : idx.name === "Under eye area"
                                    ? "around eyes"
                                    : idx.name}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        </Paper>
                      ))}
                  </div>
                  <div className="list-result-mobile-div-2">
                    <Typography className="div-ai-text">
                      {selectedAreaResult && selectedAreaResult
                        ? selectedAreaResult
                        : ImageMap !== null
                        ? ImageMap.areas[0].rank
                        : "No results found"}
                    </Typography>
                  </div>
                </>
              )}
            </Grid>
            {/* doctorNote */}
            <MobileDoctor doctorNote={doctorNote} />
          </Grid>
          {/* mobile products */}
          <MobileProduct products={products} defaultProduct={defaultProduct} />
        </GridContainer>
        <Box className={classes.bottomGradient}></Box>
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <div id="idSvgLoading">
            <img src={mahimoSvg} alt="" />
          </div>
          {/* <CircularProgress color='inherit' /> */}
        </Backdrop>
      </Tablet>
    </CameraLayout>
  );
}

export default Result;
