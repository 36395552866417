import React from "react";
import { Grid, Typography, Paper, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
export default function Index({ doctorNote }) {
  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{
        display: "flex",
        justifyContent: "start",
        flexDirection: "column",
        marginTop: "5rem",
      }}
    >
      <ExpansionPanel style={{ boxShadow: "none" }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-24-linear-normal">
            Our Dermatologist Note
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box
            alignItems="center"
            display="flex"
            width="100%"
            minHeight="196px"
            justifyContent="center"
            flexDirection="row"
            paddingTop="15px"
            style={{
              border: "1px solid #BDBDBD",
              boxSizing: "border-box",
              background: "#e7dfee",
            }}
          >
            <Typography>{doctorNote}</Typography>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
}
