import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import MainButton from '../components/UIElements/Button';
import arrowRight from '../assets/img/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    color: '#666B78',
  },
  buttonRoot: {
    position: 'relative',
    justifyContent: 'flex-start',
  },
  buttonArrow: {
    position: 'absolute',
    right: 10,
  },
}));

function Address() {
  const classes = useStyles();

  return (
    <Layout>
      <div className='heading'>
        <span className='heading-name'>Add Address</span>
        <div className='heading-bg'></div>
        <h2 className='heading-title'>Shipping address</h2>
      </div>
      <Grid container justify='center' alignItems='center'>
        <Grid item md={6}>
          <form>
            <FormControl
              variant='outlined'
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id='demo-simple-select-outlined-label'>
                Country/Region
              </InputLabel>
              <Select
                labelId='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                label='Country/Region'
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                id='outlined-basic'
                label='Full name'
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                id='outlined-basic'
                label='Address line 1'
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                id='outlined-basic'
                label='Address line 2'
                variant='outlined'
              />
            </FormControl>
            <FormControl
              variant='outlined'
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id='demo-simple-select-outlined-label'>
                City
              </InputLabel>
              <Select
                labelId='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                label='City'
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                id='outlined-basic'
                label='State / Province / Region'
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                id='outlined-basic'
                label='Zip Code'
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                id='outlined-basic'
                label='Phone number'
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <Link to='/checkout'>
                <MainButton
                  background
                  variant='contained'
                  className={classes.buttonRoot}
                  type='submit'
                  fullWidth
                >
                  Add Address
                  <img
                    src={arrowRight}
                    alt=''
                    className={classes.buttonArrow}
                  />
                </MainButton>
              </Link>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Address;
